import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { SubcategoryData } from "../../../Pages/Analytics/orders";

type newData = {
  date: string;
  total: number;
};
type SingleData = {
  label: string;
  data: any[];
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const colors = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
];

function getDates(startDate: any, endDate: any) {
  const dates = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split("T")[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

export function LineChart2({
  orderType,
  dataset,
  startDate,
  endDate,
}: {
  orderType: string;
  dataset: any;
  startDate: string;
  endDate: string;
}) {
  const today = new Date();

  const [data, setData]: any = useState({
    labels: [],
    datasets: [],
  });
  const [show, setShow] = useState(true);

  useEffect(() => {
    const ordersData = Object.values(dataset[orderType]).map(
      (singleLineData, i) => {
        return {
          label: Object.keys(dataset[orderType])[i],
          data: (singleLineData as SubcategoryData[]).map((v: any) => v.total),
          borderColor: colors[i],
          backgroundColor: colors[i],
        };
      }
    );

    const labels = getDates(new Date(startDate), new Date(endDate));

    setData({
      labels,
      datasets: ordersData,
    });
    setShow(false);
    setTimeout(() => setShow(true), 10);
  }, [dataset, orderType]);

  const options = {
    responsive: true,
    redraw: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Orders this month",
      },
    },
  };

  if (!show) return <></>;

  return <Line style={{ maxHeight: "530px" }} options={options} data={data} />;
}

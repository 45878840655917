import React from "react";
import styles from "../../Styles/withTableView.module.css";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Table from "../../Components/Table/Table";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import ContentOuterHead from "../../Components/Content/contentOuterHead";

type props = {
  heading: string;
  head: any[];
  filtervalue?: any;
  body: any;
  body_keys: any;
  show_btn_1: boolean;
  showExportBtn?: boolean;
  btn1Text?: any;
  exportBtnText?: any;
  exportBtnType?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | undefined;
  btn1Type?:
    | "outlined"
    | "filled"
    | "outlined_del"
    | "light_grey"
    | "outlined_success"
    | "filled_red"
    | "filled_green"
    | undefined;
  onClickHandler?: any;
  btn2ClickHandler?: any;
  onActionClick?: any;
  onCloneClick?: any;
  onRowClick?: any;
  isIcon?: boolean;
  iconType?: string;
  onRemarksSave?: any;
  handleDropdownUpdate?: any;
  customElement?: any;
  dropdownValues?: any;
  page?: number;
  showIconAt?: any;
  icon?: any;
  onHeadClick?: any;
  loading?: boolean;
  planError?: boolean;
  moreSettingsList?: any;
  isMoreSettings?: boolean;
  isIcon1?: boolean;
  handleMoreClick?: any;
  iconType1?: any;
};

function WithTableView(props: props) {
  return (
    <Content>
      <ContentHead
        title={props.heading}
        showBtn1={props.show_btn_1 ? props.show_btn_1 : false}
        btn1Name="btn1"
        iconType1={props?.iconType1}
        btn1Text={props.btn1Text ? props.btn1Text : ""}
        btn1type={props.btn1Type ? props.btn1Type : undefined}
        onClickHandler={props?.onClickHandler ? props.onClickHandler : ""}
        showBtn2={props.showExportBtn}
        btn2Name="btn2"
        btn2Text={props.exportBtnText}
        btn2type={props.exportBtnType}
        isIcon={props?.isIcon}
        iconType={props?.iconType}
        customElement={props?.customElement}
        isMoreSettings={props?.isMoreSettings}
        moreSettingsList={props?.moreSettingsList || []}
        isIcon1={props?.isIcon1}
        handleMoreClick={props?.handleMoreClick}
      />
      <ContentBody style={{ padding: 0, position: "relative" }}>
        {props?.planError && (
          <div
            style={{
              height: "fit-content",
              position: "absolute",
              width: "100%",
              bottom: 0,
              color: "var(--brand-color)",
              padding: "20px",
              textAlign: "center",
            }}
          >
            Your shoppable video plan is at its limit. Please contact ShopIQ
            support to increase the limit.
          </div>
        )}
        {props.loading ? (
          <TableLoading head={[...props.head]} />
        ) : (
          <Table
            head={[...props.head]}
            filtervalue={props.filtervalue}
            body={props.body}
            body_keys={[...props.body_keys]}
            number={true}
            onActionClick={props.onActionClick}
            onCloneClick={props?.onCloneClick}
            onRowClick={props.onRowClick}
            onRemarksSave={props.onRemarksSave}
            handleDropdownUpdate={props.handleDropdownUpdate}
            dropdownValues={props.dropdownValues}
            page={props?.page}
            showIconAt={props?.showIconAt}
            icon={props?.icon}
            onHeadClick={props?.onHeadClick}
          />
        )}
      </ContentBody>
    </Content>
  );
}

export default WithTableView;

import React, { useState, useEffect } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import Modal from "../../Components/Modal";
import toast from "react-hot-toast";
import { discount_body_keys, discount_table_head } from "../../Utils/Data";
import callApi from "../../Apis/callApi";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Pagination from "../../Components/Table/Pagination";
import withRouter from "../../Components/withRouter";
import { useAdminContext } from "../../Store/adminContext";

const Discounts: React.FC<any> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { admin }: any = useAdminContext();

  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const [inFocus, setInFocus] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_BASE_URL
      }discounts?identifier=${localStorage.getItem("identifier")}&page=${
        Number(search.get("page")) || 1
      }&limit=${Number(search.get("limit")) || 15}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setData([...res.data.discounts]);
        setTotal(res?.data?.discountsCount);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        callApi({
          url: inFocus,
          method: "delete",
          type: "discounts",
          header: false,
        })
          .then((res: any) => {
            setInFocus("");
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleAction}
      />

      <WithTableView
        loading={loading}
        heading="Discounts"
        head={discountHead(admin)}
        body={data}
        body_keys={discountKeys(admin)}
        show_btn_1={isUserAllowedToEdit(admin)}
        isIcon={true}
        iconType="add"
        btn1Text="Add New"
        btn1Type="filled"
        onClickHandler={() => {
          router.navigate("/discounts/add");
        }}
        onRowClick={(val: any) => {
          router.navigate(`/discounts/update/${val._id}`);
        }}
        onActionClick={(_: any, id: any) => {
          setModal(true);
          setInFocus(id);
        }}
        customElement={
          <Pagination
            pageNo={
              Number(new URLSearchParams(location.search).get("page")) || 1
            }
            changePage={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("page", String(val));

              navigate(`/discounts?${String(search)}`);
            }}
            total={total}
            showDrop={true}
            perPage={
              Number(new URLSearchParams(location.search).get("limit")) || 15
            }
            changeDrop={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("limit", String(val));

              navigate(`/discounts?${String(search)}`);
            }}
          />
        }
      />
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.discount_access.write) return true;

  return false;
};

const discountKeys = (admin: any) => {
  const base = ["discountTitle", "counponCode"];

  if (admin.role !== "subadmin") return [...base, "action:delete"];

  if (admin.role === "subadmin") {
    if (admin.discount_access.delete) {
      return [...base, "action:delete"];
    }
    if (admin.discount_access.write) {
      return base;
    }
  }

  return base;
};

const discountHead = (admin: any) => {
  const base = [
    { title: "S.No." },
    { title: "Discount Title" },
    { title: "Discount Code" },
  ];

  if (admin.role !== "subadmin") return [...base, { title: "" }];

  if (admin.role === "subadmin") {
    if (admin.discount_access.delete) {
      return [...base, { title: "" }];
    }
    if (admin.discount_access.write) {
      return base;
    }
  }

  return base;
};

export default withRouter(Discounts);

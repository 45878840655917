import styles from "./contactPreview.module.css";
import { ReactComponent as DownIcon } from "../../../Assets/icons/down.svg";
import { useState } from "react";

const ContactPreview = ({
  headers,
  assignedFields,
  ready,
  skipped,
  duplicate,
}: any) => {
  return (
    <div className={styles.container}>
      <div className={styles.section_container}>
        <div
          className={`${styles.header}`}
          style={{ borderTop: "1px solid #d2d2d2" }}
        >
          <p>Products That are ready to imported - {ready.length}</p>
        </div>
      </div>
      <div className={styles.section_container}>
        <div className={`${styles.header}`}>
          <p>No. of Records skipped - {skipped.length}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactPreview;

import axios from "axios";

type props = {
  url: string;
  method: string;
  header?: boolean;
  data?: any;
  type:
    | "blogs"
    | "admins"
    | ""
    | "casestudy"
    | "discounts"
    | "productmedia"
    | "leads";
  identifier?: boolean;
};

let url = process.env.REACT_APP_BASE_URL || "https://api.shopiq.app/api/";

// let url = "http://localhost:8080/api/";

const callApi = (props: props) => {
  let data = props.data;

  if (props.identifier) {
    data.identifier = localStorage.getItem("identifier");
  }

  return axios({
    url: url + props.type + "/" + props.url,
    method: props.method,
    data: data,
    headers: props?.header
      ? { Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}` }
      : {},
  });
};

export const uploadBulkImages = async (images: File[]) => {
  try {
    const formData = new FormData();

    // Append each image to formData with field name 'files'
    images.forEach((image) => {
      formData.append("files", image);
    });

    const response = await axios.post(
      `${
        process.env.REACT_APP_BASE_URL
      }uploadbulkimages/uploadbulkimages?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || "Something went wrong",
    };
  }
};

export const getAllFiles = async () => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }uploadbulkimages/images?identifier=${localStorage.getItem("identifier")}`
    );

    return {
      success: true,
      data: response.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: error.response?.data?.message || "Something went wrong",
    };
  }
};

export default callApi;

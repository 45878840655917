import React from "react";
import styles from "./tooltip.module.css";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left";
  className?: string;
  contentClassName?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  side = "top",
  className = "",
  contentClassName = "",
}) => {
  return (
    <div className={`tooltipContainer ${styles.tooltipContainer} ${className}`}>
      {children}
      <div
        className={`${styles.tooltipContent} ${styles[side]} ${contentClassName} ${styles.tooltipContent}`}
        role="tooltip"
      >
        {content}
        <div className={styles.arrow} />
      </div>
    </div>
  );
};

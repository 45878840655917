import React, { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "../../Styles/orderDetails.module.css";
import withRouter from "../../Components/withRouter";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import SingleRow from "../Orders/productOrders/singleRow";
import { useNavigate } from "react-router-dom";

import Pagination from "../../Components/Table/Pagination";

const ProductOrderDetails = ({ props }: any) => {
  const [key, setKey] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [admin, setAdmin] = useState({});
  const [user, setUser] = useState<any>();

  const [searchTerm, setSearchTerm] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const navigate = useNavigate();
  const [secondaryView, setSecondaryView] = useState(false);
  const router = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [disableForAction, setDisableForAction] = useState(false);
  const [inView, setInView]: any = useState({});
  const [editOrderStatus, setEditOrderStatus] = useState(false);
  const [editPaymentStatus, setEditPaymentStatus] = useState(false);

  const [tempPaymentStatus, setTempPaymentStatus] = useState("");

  const location = useLocation();

  useEffect(() => {
    // getOrderDetails();
    // getData();
    getKey();
    getAdminByToken();
    // getCustomerDetails()
  }, []);

  useEffect(() => {
    getData();
  }, [location.search]);

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}orders/getAllOrdersEcom/?identifier=` +
        localStorage.getItem("identifier") +
        "&orderStatus=false" +
        `&page=${Number(search.get("page")) || 1}` +
        "&limit=" +
        15 +
        "&",
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        const temp_data = res.data.orders.map((val: any) => ({
          ...val,
          createdAt: val.createdAt.split("T")[0].split("-").join("-"),
        }));
        setData(temp_data);
        if (Object.keys(inView).length !== 0) {
          for (let i = 0; i < temp_data.length; i++) {
            if (inView._id === temp_data[i]._id) {
              setInView(temp_data[i]);
              setEditOrderStatus(false);
              setEditPaymentStatus(false);
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerDetails(inView?.customerId);
  }, [inView]);

  const getKey = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}users/verifyuser`,
      method: "POST",
      data: {
        identifier: localStorage.getItem("identifier"),
        consent: true,
      },
    })
      .then((res) => {
        setKey(res?.data?.key);
      })
      .catch((error) => {});
  };
  const calculatePriceWithTax = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .
    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  const getAdminByToken = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}admins/getAdminByToken`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setAdmin(res.data.data);
      })
      .catch((err: any) => {});
  };

  const getCustomerDetails = (id: string) => {
    axios({
      url: "https://backend.retaino.in/api/users/getuserbyid/" + id,
      method: "get",
    })
      .then((res: any) => {
        setUser(res.data.user);
      })
      .catch((err: any) => {});
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const calculateAmt = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .
    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}

      <Content>
        <ContentHead
          title={"Orders"}
          showBtn1={false}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={false}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              // position: "relative",
            }}
          >
            {secondaryView && (
              <div className={styles.filter_container}>
                <div className={styles.sdv_buttons}>
                  <button>
                    {" "}
                    <FontAwesomeIcon icon={faEnvelope} />
                    Notify Customer
                  </button>
                </div>
              </div>
            )}
            {secondaryView ? (
              <div className={styles.secondary_view}>
                <div className={styles.secondary_list_view}>
                  {data.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => {
                          setInView(val);
                          setTempPaymentStatus(val?.paymentStatus);
                        }}
                      >
                        <div>
                          <p>{val?.orderRefId || "--"}</p>
                          <span>{val?.shippingAddress?.name}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.secondary_detail_view}>
                  <div>
                    <div className={styles.sdv_head}>
                      <p style={{ textTransform: "capitalize" }}>
                        {inView?.orderRefId}
                      </p>
                    </div>
                  </div>
                  {true && (
                    <>
                      <div className={styles.secondary_content}>
                        <div className={styles.secondary_content_inner}>
                          <div>
                            <span>Order Id</span>
                            <p>{inView?.orderRefId || "--"}</p>
                          </div>

                          <div>
                            <span>Name</span>
                            <p>{inView?.shippingAddress?.name || "--"}</p>
                          </div>
                          <div>
                            <span>Order Status</span>
                            <p>{inView?.fulfilmentStatus}</p>
                          </div>
                          <div>
                            <span>Mode Of Payment</span>
                            <p>{inView?.modeOfPayment || "--"}</p>
                          </div>
                          <div>
                            <span>Payment Status</span>
                            <p>{inView?.paymentStatus}</p>
                          </div>
                          {inView?.refundStatus && (
                            <div>
                              <span>Refund Status</span>
                              <p>{inView?.refundStatus || "--"}</p>
                            </div>
                          )}
                          {inView?.returnStatus && (
                            <div>
                              <span>Return Status</span>
                              <p>{inView?.returnStatus}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.secondary_content}>
                        <div>
                          <h4 style={{ fontWeight: 500 }}>About Product</h4>
                        </div>
                        {inView.products?.map((product: any, ind: any) => {
                          return (
                            <div
                              className={styles.secondary_content_inner}
                              key={ind}
                            >
                              <div>
                                <span>Title</span>
                                <p>{product?.productId?.title || "--"}</p>
                              </div>
                              <div>
                                <span>Category</span>
                                <p>{product?.productId?.category || "--"}</p>
                              </div>
                              <div>
                                <span>Collection</span>
                                <p>
                                  {product?.productId?.collectionName || "--"}
                                </p>
                              </div>
                              <div>
                                <span>Price</span>
                                <p>
                                  {!product.productId?.pricing.inclusiveOfGST
                                    ? product.productId?.pricing.price
                                    : Number(
                                        calculateAmt(
                                          product.productId?.pricing.price,
                                          product.productId?.pricing
                                            .inclusiveOfGST,
                                          product.productId?.pricing.igst
                                        )
                                      ) || "--"}
                                </p>
                              </div>
                              <div>
                                <span>Quantity</span>
                                <p>{product?.quantity || "--"}</p>
                              </div>
                              <div>
                                <span>IGST(%)</span>
                                <p>
                                  {product?.productId?.pricing.igst || "--"}
                                </p>
                              </div>
                              <div>
                                <span>Total Price</span>
                                <p>
                                  {" "}
                                  {product.productId?.pricing.inclusiveOfGST
                                    ? product.quantity *
                                      product.productId?.pricing.price
                                    : product.quantity *
                                      Number(
                                        calculateAmt(
                                          product.productId?.pricing.price,
                                          product.productId?.pricing
                                            .inclusiveOfGST,
                                          product.productId?.pricing.igst
                                        )
                                      )}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles.secondary_content}>
                        <h4>Cutomer Details</h4>
                        <div className={styles.secondary_content_inner}>
                          <div>
                            <span>Name</span>
                            <p>{inView?.shippingAddress?.name || "--"}</p>
                          </div>
                          <div>
                            <span>Email</span>
                            <p>{user?.email || "--"}</p>
                          </div>
                          <div>
                            <span>Mobile</span>
                            <p>{user?.mobile?.number || "--"}</p>
                          </div>

                          <div>
                            <span>Shipping Address</span>

                            <p>
                              {/* @ts-ignore */}
                              {inView?.shippingAddress?.name},{" "}
                              {
                                // @ts-ignore
                                inView?.shippingAddress?.addLineOne
                              }{" "}
                              {
                                // @ts-ignore
                                inView?.shippingAddress?.addLineTwo
                              }{" "}
                              {
                                //@ts-ignore
                                inView?.shippingAddress?.landmark
                              }{" "}
                              {/* @ts-ignore */}
                              {inView?.shippingAddress?.city}{" "}
                              {
                                // @ts-ignore
                                inView?.shippingAddress?.state
                              }{" "}
                              - {/* @ts-ignore */}
                              {inView?.shippingAddress?.pincode}
                            </p>
                          </div>
                          <div>
                            <span>Billing Address</span>

                            <p>
                              {/* @ts-ignore */}
                              {inView?.billingAddress?.name},{" "}
                              {
                                // @ts-ignore
                                inView?.billingAddress?.addLineOne
                              }{" "}
                              {
                                // @ts-ignore
                                inView?.billingAddress?.addLineTwo
                              }{" "}
                              {
                                //@ts-ignore
                                inView?.billingAddress?.landmark
                              }{" "}
                              {/* @ts-ignore */}
                              {inView?.billingAddress?.city}{" "}
                              {
                                // @ts-ignore
                                inView?.billingAddress?.state
                              }{" "}
                              - {/* @ts-ignore */}
                              {inView?.billingAddress?.pincode}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className={styles.table_container}>
                  <table className={styles.responsive_table}>
                    <thead>
                      <tr>
                        <th> Order Id</th>
                        <th>Customer Name</th>
                        <th>Order Value</th>
                        <th>Order Date</th>
                        <th>Mode of Payment</th>
                        <th>Payment Status</th>
                        <th>Order Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((val: any, index: any) => {
                        return (
                          <SingleRow
                            data={val}
                            key={index}
                            changeSelect={() => {}}
                            disableForAction={disableForAction}
                            // updatedContacts={updatedContacts}
                            // setUpdatedContacts={setUpdatedContacts}
                            // subAdmins={subAdmins}
                            onRowClick={() => {
                              setInView(val);
                              setTempPaymentStatus(val?.paymentStatus);
                              setSecondaryView(true);
                            }}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    // border: "1px solid ",
                    paddingBlock: "5px",
                    background: "var(--blue-light)",
                    borderRadius: "0px 0px 10px 10px",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Pagination
                    pageNo={
                      Number(
                        new URLSearchParams(location.search).get("page")
                      ) || 1
                    }
                    changePage={(val: number) => {
                      let search = new URLSearchParams(location.search);

                      search.set("page", String(val));

                      navigate(`/orders?${String(search)}`);
                    }}
                    total={data?.length || 0}
                    showDrop={false}
                    perPage={
                      Number(
                        new URLSearchParams(location.search).get("limit")
                      ) || 15
                    }
                    changeDrop={(val: number) => {
                      let search = new URLSearchParams(location.search);

                      search.set("limit", String(val));

                      navigate(`/orders?${String(search)}`);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.sales_access.write) return true;

  return false;
};

export default withRouter(ProductOrderDetails);

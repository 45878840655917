import React from "react";
import callApi from "../../Apis/callApi";
import WithTableView from "../../GenericPages/WithTableView";
import { compliance_table_head } from "../../Utils/Data";
import axios from "axios";
import withRouter from "../../Components/withRouter";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";

class Compliance extends React.Component<any, any> {
  state = {
    data: [],
    loading: true,
    showbtn: false,
    modal: false,
    inFocus: "",
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}compliances?identifier=` +
        localStorage.getItem("identifier"),
      method: "get",
    })
      .then((res) => {
        this.setState({ data: res.data.compliances, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleAction = (val: string, id: string) => {
    if (val === "delete") {
      this.setState({ modal: true, inFocus: id });
    }

    if (val === "archive") {
      const promise = new Promise((resolve, reject) => {
        axios({
          method: "patch",
          data: { status: "draft" },
          url: `${process.env.REACT_APP_BASE_URL}compliances/${id}`,
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      toast
        .promise(
          promise,
          {
            loading: "Archiving...",
            success: "Archived Successfully.",
            error: "Error when archiving the entry.",
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          this.getData();
        });
    }

    if (val === "unarchive") {
      const promise = new Promise((resolve, reject) => {
        axios({
          method: "patch",
          data: { status: "live" },
          url: `${process.env.REACT_APP_BASE_URL}compliances/${id}`,
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      toast
        .promise(
          promise,
          {
            loading: "UnArchiving...",
            success: "UnArchived Successfully.",
            error: "Error when unarchiving the entry.",
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          this.getData();
        });
    }
  };

  checkForAddBtn = () => {
    if (localStorage.getItem("ecommerce") === "true") {
      if (this.state.data.length === 5) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "returnPolicy" ||
          val.typeName === "cancellationPolicy" ||
          val.typeName === "dataPolicy" ||
          val.typeName === "about" ||
          val.typeName === "buy-back"
        ) {
          return true;
        }
        return false;
      }).length === 5
        ? false
        : true;
    } else if (localStorage.getItem("service") === "true") {
      if (this.state.data.length === 3) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "cancellationPolicy" ||
          val.typeName === "about"
        ) {
          return true;
        }
        return false;
      }).length === 3
        ? false
        : true;
    } else if (localStorage.getItem("blog") === "true") {
      if (this.state.data.length === 3) {
        return false;
      }

      return this.state.data.filter((val: any) => {
        if (
          val.typeName === "terms" ||
          val.typeName === "privacy" ||
          val.typeName === "refund" ||
          val.typeName === "about"
        ) {
          return true;
        }

        return false;
      }).length === 3
        ? false
        : true;
    }
    return false;
  };

  delete = () => {
    this.setState({ modal: false });
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_BASE_URL}compliances/${this.state.inFocus}`,
      })
        .then((res) => {
          this.setState({ inFocus: "" });
          resolve(true);
        })
        .catch((err) => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        this.getData();
      });
  };

  render(): React.ReactNode {
    return (
      <>
        <Modal
          title={"Delete"}
          description={
            "Do you really want to delete this record? This process cannot be undone."
          }
          state={this.state.modal}
          onBtn1Click={() => this.setState({ modal: false, inFocus: "" })}
          onBtn2Click={this.delete}
        />
        <WithTableView
          loading={this.state.loading}
          heading="Compliance"
          head={complianceHead(this.props.admin)}
          body={[...this.state.data]}
          body_keys={complianceKeys(this.props.admin)}
          show_btn_1={
            isUserAllowedToEdit(this.props.admin)
              ? this.checkForAddBtn()
              : false
          }
          btn1Text="Add New"
          btn1Type="filled"
          onRowClick={(val: any) => {
            this.props.router.navigate(`/compliance/update/${val._id}`);
          }}
          onClickHandler={() => {
            this.props.router.navigate("/compliance/add");
          }}
          onActionClick={this.handleAction}
        />
      </>
    );
  }
}

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.compliance_access.write) return true;

  return false;
};

const complianceKeys = (admin: any) => {
  const base = ["title", "typeName", "", "", "status"];

  if (admin.role !== "subadmin") return [...base, "action:delete,archive"];

  if (admin.role === "subadmin") {
    if (admin.compliance_access.delete) {
      return [...base, "action:delete,archive"];
    }
    if (admin.compliance_access.write) {
      return [...base, "action:archive"];
    }
  }

  return base;
};

const complianceHead = (admin: any) => {
  const base = [
    { title: "S.No." },
    { title: "Title" },
    { title: "Type" },
    { title: "Created At" },
    { title: "Last Updated" },
    { title: "Status" },
  ];

  if (admin.role !== "subadmin") return [...base, { title: "" }];

  if (admin.role === "subadmin") {
    if (admin.compliance_access.delete) {
      return [...base, { title: "" }];
    }
    if (admin.compliance_access.write) {
      return [...base];
    }
  }

  return base;
};

export default withRouter(Compliance);

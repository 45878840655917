import React from "react";
import callApi from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import UploadImage, { folder_name } from "../../Utils/UploadImage";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import withRouter from "../../Components/withRouter";
import { getFileName } from "../../Utils/getFileName";
import langObj from "../../Utils/lang";
import heic2any from "heic2any";

class AddUpdateCaseStudy extends React.Component<any, any> {
  state = {
    imageUrl: "",
    imageObject: "",
    content: "", // body
    title: "",
    shortDescription: "", // description
    category: "",
    tags: "",
    adOneUrl: "",
    adTwoUrl: "",
    buttonOneUrl: "",
    buttonTwoUrl: "",
    loading: false,
    isImageChanged: false,
    author: "",
  };

  componentDidMount(): void {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    }
  }

  getData = () => {
    callApi({
      method: "get",
      url: this.props.router.params.id,
      data: {},
      type: "casestudy",
    })
      .then((res) => {
        let data = res.data.caseStudy;

        this.setState({
          adOneUrl: data.adOneUrl,
          adTwoUrl: data.adTwoUrl,
          content: data.body,
          buttonOneUrl: data.buttonOneUrl,
          buttonTwoUrl: data.buttonTwoUrl,
          category: data.category,
          shortDescription: data.description,
          // id: data.,
          identifier: data.identifier,
          tags: data.tags,
          title: data.title,
          imageUrl: data.image,
          author: data?.others?.author ? data.others?.author : "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleImage = async (event: any) => {
    const file = event.target.files[0];
    if (file && file.type === "image/heic") {
      const convertedBlob: any = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      const convertedFile = new File([convertedBlob], "converted.jpg", {
        type: "image/jpeg",
      });
      this.setState({
        imageUrl: URL.createObjectURL(convertedFile),
        imageObject: convertedFile,
        isImageChanged: true,
      });
    } else
      this.setState({
        imageUrl: URL.createObjectURL(event.target.files[0]),
        imageObject: event.target.files[0],
        isImageChanged: true,
      });
  };

  removeExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  validate = () => {
    let text = "";

    // check image
    if (!this.state.imageUrl && !text) {
      text = "Banner image";
    }

    // check title
    if (!this.state.title && !text) {
      text = "Blog title";
    }

    if (text) {
      toast.error(`${text} is Required.`);
      return false;
    }

    return true;
  };

  handleSubmit = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/case-study");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        const filename = getFileName(this.state.imageObject);

        UploadImage({ file: this.state.imageObject, name: filename })
          .then((res) => {
            const image_url = res;
            const data = {
              title: this.state.title,
              description: this.state.shortDescription,
              image: image_url,
              body: this.state.content,
              adOneUrl: this.state.adOneUrl,
              adTwoUrl: this.state.adTwoUrl,
              buttonOneUrl: this.state.buttonOneUrl,
              buttonTwoUrl: this.state.buttonTwoUrl,
              category: this.state.category,
              tags: this.state.tags,
              //   status: event.target.name === "btn1" ? "live" : "draft",
              //   others: { author: this.state.author },
            };

            callApi({
              url: "",
              data,
              method: "post",
              type: "casestudy",
              header: true,
              identifier: true,
            })
              .then((res: any) => {
                toast.success("Case Study Created Successfully");
                this.props.router.navigate("/case-study");
                this.setState({ loading: false });
              })
              .catch((err: any) => {
                toast.error(err.response.data.message);
                this.setState({ loading: false });
              });
          })
          .catch((res) => {});
      }
    }
    // let regex = ' srcs*=s*"?(.+?)["|s]';
  };

  updateBlog = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/case-study");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        if (!!this.state.imageUrl && !this.state.isImageChanged) {
          // no need to upload to aws s3

          const data = {
            title: this.state.title,
            description: this.state.shortDescription,
            image: this.state.imageUrl,
            body: this.state.content,
            adOneUrl: this.state.adOneUrl,
            adTwoUrl: this.state.adTwoUrl,
            buttonOneUrl: this.state.buttonOneUrl,
            buttonTwoUrl: this.state.buttonTwoUrl,
            category: this.state.category,
            tags: this.state.tags,
            // status: event.target.name === "btn1" ? "live" : "draft",
            // others: { author: this.state.author },
          };

          callApi({
            url: this.props.router.params.id,
            data,
            method: "patch",
            type: "casestudy",
            header: true,
            identifier: true,
          })
            .then((res: any) => {
              toast.success("Case Study Updated Successfully");
              this.props.router.navigate("/case-study");
              this.setState({ loading: false });
            })
            .catch((err: any) => {
              toast.error(err.response.data.message);
              this.setState({ loading: false });
            });
        } else {
          // need to upload image to aws s3

          const filename = getFileName(this.state.imageObject);

          UploadImage({ file: this.state.imageObject, name: filename })
            .then((res) => {
              const image_url = res;
              const data = {
                title: this.state.title,
                description: this.state.shortDescription,
                image: image_url,
                body: this.state.content,
                adOneUrl: this.state.adOneUrl,
                adTwoUrl: this.state.adTwoUrl,
                buttonOneUrl: this.state.buttonOneUrl,
                buttonTwoUrl: this.state.buttonTwoUrl,
                category: this.state.category,
                tags: this.state.tags,
                // status: event.target.name === "btn1" ? "live" : "draft",
              };

              callApi({
                url: this.props.router.params.id,
                data,
                method: "patch",
                type: "casestudy",
                header: true,
                identifier: true,
              })
                .then((res: any) => {
                  toast.success("Case Study Updated Successfully");
                  this.props.router.navigate("/case-study");
                  this.setState({ loading: false });
                })
                .catch((err: any) => {
                  toast.error(err.response.data.message);
                  this.setState({ loading: false });
                });
            })
            .catch((res) => {});
        }
      }
    }
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={
              this.props?.title
                ? langObj.updateCaseStudy[this.props.lang]
                : langObj.addNewCaseStudy[this.props.lang]
            }
            showBtn1={
              this.props.admin.role === "subadmin"
                ? this.props.admin.caseStudy_access.write
                : true
            }
            showBtn2={
              this.props.admin.role === "subadmin"
                ? this.props.admin.caseStudy_access.write
                : true
            }
            showBtn3={true}
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text={langObj.save[this.props.lang]}
            btn2Text={langObj.saveAsDraft[this.props.lang]}
            btn3Text={langObj.cancel[this.props.lang]}
            onClickHandler={
              this.props.router.params.id ? this.updateBlog : this.handleSubmit
            }
          />
          <ContentBody>
            <>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                {langObj.bannerImage[this.props.lang]}
                <span style={{ color: "#a3a3a3", fontSize: ".8rem" }}>
                  {`(${langObj.uploadAnImageOfUpto5MB[this.props.lang]})`}
                </span>
                {this.state.imageUrl && (
                  <span
                    style={{
                      display: "inline-flex",
                      borderRadius: "10px",
                      marginLeft: "10px",
                      color: "#CB1829",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      this.setState({ imageUrl: "", imageObject: "" });
                    }}
                    title="Delete image"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                )}
              </p>

              {!this.state.imageUrl ? (
                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    textAlign: "center",
                    background: "transparent",
                    border: "1px dashed grey",
                    borderRadius: "10px",
                    margin: "10px",
                    cursor: "pointer",
                    position: "relative",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  +
                  <input
                    type="file"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      cursor: "pointer",
                    }}
                    accept=".heic,image/*"
                    onChange={this.handleImage}
                  ></input>
                </div>
              ) : (
                <div
                  style={{
                    width: "45%",
                    aspectRatio: "16/9",
                    backgroundImage: `url('${
                      this.props.router.params.id
                        ? this.state.isImageChanged
                          ? this.state.imageUrl
                          : this.state.imageUrl
                        : this.state.imageUrl
                    }')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginBottom: "40px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    position: "relative",
                  }}
                ></div>
              )}

              <InputField
                isLabel={true}
                value={this.state.title}
                lable={langObj.Title[this.props.lang]}
                type="text"
                isRequired={false}
                placeholder=""
                onChange={(event: any) => {
                  this.setState({ title: event.target.value });
                }}
              />

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.author}
                  lable={langObj.category[this.props.lang]}
                  isRequired={false}
                  placeholder={langObj.category[this.props.lang]}
                  onChange={(event: any) => {
                    this.setState({ category: event.target.value });
                  }}
                />
                <InputField
                  type="text"
                  isLabel={true}
                  value={this.state.tags}
                  lable={langObj.tags[this.props.lang]}
                  isRequired={false}
                  placeholder={langObj.commaSeperatedTags[this.props.lang]}
                  onChange={(event: any) => {
                    this.setState({
                      tags: event.target.value,
                    });
                  }}
                />
              </div>

              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  transform: "translateX(5px)",
                  marginTop: "20px",
                }}
              >
                {langObj.description[this.props.lang]}
              </p>
              <TextEditor
                content={this.state.shortDescription}
                handleChange={(value: any) => {
                  this.setState({ shortDescription: value });
                }}
              />
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  marginTop: "20px",
                  transform: "translateX(5px)",
                  marginBottom: "10px",
                }}
              >
                {langObj.body[this.props.lang]}
              </p>
              <TextEditor
                content={this.state.content}
                handleChange={(value: any) => {
                  this.setState({ content: value });
                }}
              />
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(AddUpdateCaseStudy);

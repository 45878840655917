import { useNavigate, useParams } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import InputField from "../../Components/InputField";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { countryCodes } from "../../Utils/countryCode";

const AddUpdateLeads = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyHQ, setCompanyHQ] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [altCountryCode, setAltCountryCode] = useState("");
  const [altMobileNumber, setAltMobileNumber] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [cooperateEmail, setCooperateEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [leadOwner, setLeadOwner] = useState("");
  const [status, setStatus] = useState("new");
  const [remarks, setRemarks] = useState("");
  const [subAdmins, setSubAdmins] = useState([]);
  const [campaign, setCampaign] = useState({
    id: "",
    source: "",
    medium: "",
    name: "",
    term: "",
    content: "",
    referrer: "",
  });

  const params = useParams();

  useEffect(() => {
    getSubAdmins();
    getData();
  }, []);

  const getSubAdmins = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}sub-admin`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        setSubAdmins(res.data.sub_admins);
        getData();
      })
      .catch((err: any) => {
        getData();
      });
  };

  const getData = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}contacts/` + params.id,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        let temp = res.data.contact;

        setFirstName(temp?.firstName);
        setLastName(temp?.lastName);
        setCountryCode(temp?.mobileCountryCode);
        setMobileNumber(temp?.mobileNumber);
        setAltCountryCode(temp?.altMobileCountryCode);
        setAltMobileNumber(temp?.altMobileNumber);
        setPersonalEmail(temp?.personalEmail);
        setCooperateEmail(temp?.cooperateEmail);
        setCompanyName(temp?.companyName);
        setCompanyWebsite(temp?.companyWebsite);
        setCompanyHQ(temp?.companyHQ);
        setAddress(temp?.address);
        setCity(temp?.city);
        setState(temp?.state);
        setCountry(temp?.country);
        setPincode(temp?.pincode);
        setStatus(temp?.status);
        setLeadOwner(temp?.leadOwner);
        setCampaign({
          id: temp?.campaign?.id || "",
          source: temp?.campaign?.source || "",
          medium: temp?.campaign?.medium || "",
          name: temp?.campaign?.name || "",
          term: temp?.campaign?.term || "",
          content: temp?.campaign?.content || "",
          referrer: temp?.campaign?.referrer || "",
        });
        setLoading(false);
      })
      .catch((error: any) => {
        toast.error("Something went wrong.");
        setLoading(false);
      });
  };

  const validateInput = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const mobilePattern = /^\d{10}$/; // Assuming 10-digit mobile numbers

    if (!firstName) {
      toast.error("First Name is required.");
      return false;
    }

    if (personalEmail && !emailPattern.test(personalEmail)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (cooperateEmail && !emailPattern.test(cooperateEmail)) {
      toast.error("Please enter a valid cooperate email address.");
      return false;
    }

    if (companyWebsite && !urlPattern.test(companyWebsite)) {
      toast.error("Please enter a valid company URL.");
      return false;
    }

    if (mobileNumber && !mobilePattern.test(mobileNumber)) {
      toast.error("Please enter a valid 10-digit mobile number.");
      return false;
    }

    if (mobileNumber && !countryCode) {
      toast.error("Country Code is required with mobile number.");
      return false;
    }

    if (altMobileNumber && !mobilePattern.test(altMobileNumber)) {
      toast.error("Please enter a valid 10-digit alt mobile number.");
      return false;
    }

    if (altMobileNumber && !altCountryCode) {
      toast.error("Country Code is required with alternate mobile number.");
      return false;
    }

    return true;
  };

  const handleSave = () => {
    // Add save logic here, for example, an API call to save the lead
    if (validateInput()) {
      const data = {
        firstName,
        lastName,
        companyName,
        companyWebsite,
        companyHQ,
        mobileCountryCode: countryCode,
        mobileNumber,
        altMobileCountryCode: altCountryCode,
        altMobileNumber,
        personalEmail,
        cooperateEmail,
        address,
        city,
        state,
        country,
        pincode,
        status,
        remarks,
        leadOwner: leadOwner === "" ? null : leadOwner,
        campaign,
      };
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_BASE_URL}contacts/` + params.id,
        method: "patch",
        data: { ...data, identifier: localStorage.getItem("identifier") },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res: any) => {
          navigate("/contacts");
          setLoading(false);
          toast.success("Contact Updated successfully.");
        })
        .catch((err: any) => {
          setLoading(false);
          toast.error("Something went wrong.");
        });
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={"Create Contact"}
          showBtn1={true}
          showBtn2={true}
          btn1Name="btn1"
          btn1type="filled"
          btn1Text="Save"
          btn2Name="btn2"
          btn2type="outlined"
          btn2Text="Cancel"
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              handleSave();
            } else if (e.target.name === "btn2") {
              navigate("/contacts");
            }
          }}
        />
        <ContentBody>
          <div className="grid">
            <div className="card grid">
              <h3>Contact Information</h3>
              <div className="grid_2">
                <InputField
                  type="text"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                  isLabel={true}
                  lable={"First Name"}
                />
                <InputField
                  type="text"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                  isLabel={true}
                  lable={"Last Name"}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <p>Mobile Number</p>
                  <div
                    style={{
                      height: "52px",
                      width: "100%",
                      border: "1px solid rgb(222,222,222)",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <select
                      value={countryCode}
                      onChange={(e: any) => setCountryCode(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        height: "100%",
                        width: "fit-content",
                        flexShrink: 0,
                      }}
                    >
                      <option value="" selected disabled>
                        + 00
                      </option>
                      {countryCodes.map((val: any, index: any) => (
                        <option value={val} key={index}>
                          {val}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      value={mobileNumber}
                      onChange={(e: any) => setMobileNumber(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "0 10px 10px 0",
                        flex: 1,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                  }}
                >
                  <p>Alternate Mobile Number</p>
                  <div
                    style={{
                      height: "52px",
                      width: "100%",
                      border: "1px solid rgb(222,222,222)",
                      borderRadius: "10px",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <select
                      value={altCountryCode}
                      onChange={(e: any) => setAltCountryCode(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "10px",
                        height: "100%",
                        width: "fit-content",
                        flexShrink: 0,
                      }}
                    >
                      <option value="" selected disabled>
                        + 00
                      </option>
                      {countryCodes.map((val: any, index: any) => (
                        <option value={val} key={index}>
                          {val}
                        </option>
                      ))}
                    </select>
                    <input
                      type="number"
                      value={altMobileNumber}
                      onChange={(e: any) => setAltMobileNumber(e.target.value)}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "0 10px 10px 0",
                        flex: 1,
                      }}
                    />
                  </div>
                </div>
                <InputField
                  type="text"
                  value={personalEmail}
                  onChange={(e: any) => setPersonalEmail(e.target.value)}
                  isLabel={true}
                  lable={"Personal Email"}
                />
                <InputField
                  type="text"
                  value={cooperateEmail}
                  onChange={(e: any) => setCooperateEmail(e.target.value)}
                  isLabel={true}
                  lable={"Cooperate Email"}
                />
              </div>
            </div>
            <div className="card grid">
              <h3>Company Information</h3>
              <div className="grid_2">
                <InputField
                  type="text"
                  value={companyName}
                  onChange={(e: any) => setCompanyName(e.target.value)}
                  isLabel={true}
                  lable={"Company Name"}
                />
                <InputField
                  type="text"
                  value={companyWebsite}
                  onChange={(e: any) => setCompanyWebsite(e.target.value)}
                  isLabel={true}
                  lable={"Company Website"}
                />
                <InputField
                  type="text"
                  value={companyHQ}
                  onChange={(e: any) => setCompanyHQ(e.target.value)}
                  isLabel={true}
                  lable={"Company HQ"}
                />
              </div>
            </div>
            <div className="card grid">
              <h3>Address</h3>
              <div className="grid_2">
                <InputField
                  type="text"
                  value={address}
                  onChange={(e: any) => setAddress(e.target.value)}
                  isLabel={true}
                  lable={"Address"}
                />
                <InputField
                  type="text"
                  value={city}
                  onChange={(e: any) => setCity(e.target.value)}
                  isLabel={true}
                  lable={"City"}
                />
                <InputField
                  type="text"
                  value={state}
                  onChange={(e: any) => setState(e.target.value)}
                  isLabel={true}
                  lable={"State"}
                />
                <InputField
                  type="text"
                  value={country}
                  onChange={(e: any) => setCountry(e.target.value)}
                  isLabel={true}
                  lable={"Country"}
                />
                <InputField
                  type="text"
                  value={pincode}
                  onChange={(e: any) => setPincode(e.target.value)}
                  isLabel={true}
                  lable={"Pincode"}
                />
              </div>
            </div>
            <div className="card grid">
              <h3>Campaign</h3>
              <div className="grid_2">
                <InputField
                  type="text"
                  value={campaign.id}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({ ...prev, id: e.target.value }))
                  }
                  isLabel={true}
                  lable={"ID"}
                />
                <InputField
                  type="text"
                  value={campaign.source}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({ ...prev, source: e.target.value }))
                  }
                  isLabel={true}
                  lable={"Source"}
                />
                <InputField
                  type="text"
                  value={campaign.medium}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({ ...prev, medium: e.target.value }))
                  }
                  isLabel={true}
                  lable={"Medium"}
                />
                <InputField
                  type="text"
                  value={campaign.name}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({ ...prev, name: e.target.value }))
                  }
                  isLabel={true}
                  lable={"Name"}
                />
                <InputField
                  type="text"
                  value={campaign.term}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({ ...prev, term: e.target.value }))
                  }
                  isLabel={true}
                  lable={"Term"}
                />
                <InputField
                  type="text"
                  value={campaign.content}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({
                      ...prev,
                      content: e.target.content,
                    }))
                  }
                  isLabel={true}
                  lable={"Content"}
                />
                <InputField
                  type="text"
                  value={campaign.referrer}
                  onChange={(e: any) =>
                    setCampaign((prev) => ({
                      ...prev,
                      referrer: e.target.value,
                    }))
                  }
                  isLabel={true}
                  lable={"Referrer"}
                />
              </div>
            </div>
            <div className="card grid">
              <div className="grid_2">
                <InputField
                  type="select"
                  value={status}
                  options={[
                    { title: "New", value: "new" },
                    { title: "Open", value: "open" },
                    { title: "In Progress", value: "in_progress" },
                    { title: "Open Deal", value: "open_deal" },
                    { title: "Unqualified", value: "unqualified" },
                    {
                      title: "Attempted to Contact",
                      value: "attempted_to_contact",
                    },
                    { title: "Connected", value: "connected" },
                    { title: "Bad Timing", value: "bad_timing" },
                    { title: "Unassigned", value: "unassigned" },
                  ]}
                  accessText="title"
                  accessValue="value"
                  onChange={(e: any) => {
                    setStatus(e.target.value);
                  }}
                  isLabel={true}
                  lable={"Status"}
                />
                <InputField
                  type="select"
                  value={leadOwner}
                  options={[
                    { text: "None", value: "" },
                    ...subAdmins.map((v) => ({
                      value: v._id,
                      text: v.email,
                    })),
                  ]} // Example options
                  accessText="text"
                  accessValue="value"
                  onChange={(e: any) => setLeadOwner(e.target.value)}
                  isLabel={true}
                  lable={"Lead Owner"}
                />
              </div>
              <InputField
                type="textarea"
                value={remarks}
                onChange={(e: any) => setRemarks(e.target.value)}
                isLabel={true}
                lable={"Remarks"}
              />
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default AddUpdateLeads;

import React, { useRef, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import styles from "./uploadProduct.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faChevronDown,
  faDeleteLeft,
  faPlus,
  faTrash,
  faTrashAlt,
  faTurnDown,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { product_review_head } from "../../Utils/Data";
import DropBtn from "../../Components/DropBtn";
import Pagination from "../../Components/Table/Pagination";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import toast from "react-hot-toast";
import callApi from "../../Apis/callApi";
import axios from "axios";

const UploadProduct = () => {
  const [checked, setChecked]: any = useState([]);
  const [selected, setSelected]: any = React.useState([]);
  const [loading, setLoading]: any = React.useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const inputRef: any = useRef();

  const navigate = useNavigate();

  const bulkUpload = (file: any) => {
    // now read this excel file and covert it into json and then send it to the backend
    // @ts-ignore
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      let data = e.target.result;
      let workbook = XLSX.read(data, { type: "binary" });
      let sheet_name_list = workbook.SheetNames;

      let xlData = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[0]],
        { header: 1, defval: "" }
      );

      if (xlData.length < 3) {
        toast.error("No Data Found");
        return;
      }

      let header1: any = xlData[0];

      let k = 0;
      while (true) {
        if (
          header1[k].includes("advancePricingValues") ||
          header1[k].includes("pricing") ||
          header1[k].includes("inventory") ||
          header1[k].includes("variant") ||
          header1[k].includes("specifications") ||
          header1[k].includes("shipping") ||
          header1[k].includes("isAvailable") ||
          header1[k].includes("profit") ||
          header1[k].includes("seListing") ||
          header1[k].includes("document")
        ) {
          if (header1[k].includes("advancePricingValues")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }

          if (header1[k].includes("pricing")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            header1[k + 3] = header1[k];
            header1[k + 4] = header1[k];
            header1[k + 5] = header1[k];
            header1[k + 6] = header1[k];
            header1[k + 7] = header1[k];
            k += 8;
            continue;
          }

          if (header1[k].includes("inventory")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            header1[k + 3] = header1[k];
            header1[k + 4] = header1[k];
            header1[k + 5] = header1[k];
            header1[k + 6] = header1[k];
            k += 7;
            continue;
          }

          if (header1[k].includes("variant")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            header1[k + 3] = header1[k];
            header1[k + 4] = header1[k];
            k += 5;
            continue;
          }

          if (header1[k].includes("specifications")) {
            header1[k + 1] = header1[k];
            k += 2;
            continue;
          }

          if (header1[k].includes("shipping")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }

          if (header1[k].includes("isAvailable")) {
            header1[k + 1] = header1[k];
            k += 2;
            continue;
          }

          if (header1[k].includes("profit")) {
            header1[k + 1] = header1[k];
            k += 2;
            continue;
          }

          if (header1[k].includes("document")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }

          if (header1[k].includes("seListing")) {
            header1[k + 1] = header1[k];
            header1[k + 2] = header1[k];
            k += 3;
            continue;
          }
        } else {
          if (header1[k] === "") {
            break;
          }
        }
        k++;
      }

      header1 = header1.filter((val: any) => val);

      let header2: any = xlData[1];
      let dataRows: any = xlData.slice(2);

      let result: any = [];

      for (let i = 0; i < dataRows.length; i++) {
        if (dataRows[i].filter((val: any) => val).length === 0) break;
        let obj: any = {};

        obj.identifier = localStorage.getItem("identifier");

        for (let j = 0; j < dataRows[i].length; j++) {
          if (header2[j] === "" && header1[j] !== "") {
            if (header1[j] === "similarProduct") {
              // obj[header1[j]] = dataRows[i][j].split(",");
            } else if (header1[j] === "tags") {
              obj[header1[j]] = dataRows[i][j].split(",");
            } else if (header1[j] === "advancePricing") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "isBestSeller") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "isFeatured") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "requestQuote") {
              obj[header1[j]] = !!dataRows[i][j];
            } else if (header1[j] === "status") {
              obj[header1[j]] = dataRows[i][j] || "active";
            } else {
              obj[header1[j]] = dataRows[i][j];
            }
          }

          if (
            header2[j] !== "" &&
            header1[j].includes("advancePricingValues")
          ) {
            if (obj?.advancePricingValues) {
              obj.advancePricingValues.push({
                [header2[j]]: dataRows[i][j],
                [header2[j + 1]]: dataRows[i][j + 1],
                [header2[j + 2]]: dataRows[i][j + 2],
              });
            } else {
              obj.advancePricingValues = [
                {
                  [header2[j]]: dataRows[i][j],
                  [header2[j + 1]]: dataRows[i][j + 1],
                  [header2[j + 2]]: dataRows[i][j + 2],
                },
              ];
            }
            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("pricing")) {
            obj.pricing = {
              price: dataRows[i][j],
              comparePrice: dataRows[i][j + 1],
              makingCharge: dataRows[i][j + 2],
              cgst: dataRows[i][j + 3],
              sgst: dataRows[i][j + 4],
              igst: dataRows[i][j + 5],
              purchaseCurrency: dataRows[i][j + 6],
              minimumOrderQuantity: dataRows[i][j + 7],
            };
            j += 7;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("inventory")) {
            obj.inventory = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
              [header2[j + 3]]: dataRows[i][j + 3],
              [header2[j + 4]]: !!dataRows[i][j + 4],
              [header2[j + 5]]: dataRows[i][j + 5],
              [header2[j + 6]]: dataRows[i][j + 6],
            };

            obj.inventory.addQuantity = [
              {
                value: obj?.inventory?.addQuantity,
                date: new Date().toString(),
              },
            ];

            j += 6;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("variant")) {
            let t: any = {};

            if (dataRows[i][j]) t.variantProductId = dataRows[i][j];
            if (dataRows[i][j]) t.slug = dataRows[i][j + 1];
            if (dataRows[i][j]) t.optionSku = dataRows[i][j + 2];
            if (dataRows[i][j]) t.options_name = dataRows[i][j + 3];
            if (dataRows[i][j]) t.options_value = dataRows[i][j + 4];

            if (obj?.variant) {
              obj.variant.push(t);
            } else {
              obj.variant = [t];
            }
            j += 4;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("specifications")) {
            if (obj?.specifications) {
              obj.specifications.push({
                options_name: dataRows[i][j],
                options_value: dataRows[i][j + 1].split(","),
              });
            } else {
              obj.specifications = [
                {
                  options_name: dataRows[i][j],
                  options_value: dataRows[i][j + 1].split(","),
                },
              ];
            }

            j += 1;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("shipping")) {
            obj.shipping = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
            };
            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("document")) {
            obj.document = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
            };
            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("seListing")) {
            obj.seListing = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
              [header2[j + 2]]: dataRows[i][j + 2],
            };

            j += 2;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("isAvailable")) {
            obj.isAvailable = {
              [header2[j]]: !!dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
            };
            j += 1;
            continue;
          }

          if (header2[j] !== "" && header1[j].includes("profit")) {
            obj.profit = {
              [header2[j]]: dataRows[i][j],
              [header2[j + 1]]: dataRows[i][j + 1],
            };
            j += 1;
            continue;
          }
        }

        result.push(obj);
      }

      setSelected((prev: any) => [...prev, ...result]);
      inputRef.current.value = "";
    };
  };

  const createBulkProduct = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}products/bulkUpload`,

      method: "PATCH",
      data: selected,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        navigate("/products");
        toast.success("Bulk upload completed");
      })
      .catch((err: any) => {
        toast.error(err.response.message);
      });
  };

  return (
    <Content>
      <ContentHead
        title={"Bulk Product Upload"}
        showBtn1={true}
        showBtn2={true}
        showBtn3={true}
        btn1Text={"Save"}
        btn2Text={"Cancel"}
        btn3Text={"Download Excel Format"}
        btn1Name="btn1"
        btn2Name="btn2"
        btn3Name="btn3"
        btn1type="filled"
        btn2type="outlined_del"
        btn3type="light_grey"
        onClickHandler={(e: any) => {
          if (e.target.name === "btn1") {
            createBulkProduct();
          } else if (e.target.name === "btn2") {
            navigate("/products");
          } else if (e.target.name === "btn3") {
            const a = document.createElement("a");
            a.href = "/bo_bulk_product_upload.xlsx";
            a.download = "Catalog";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }}
      />
      <ContentBody>
        <div className="grid">
          <div
            className="card grid"
            style={
              selected.length !== 0
                ? {
                    height: 0,
                    overflow: "hidden",
                    padding: 0,
                    margin: 0,
                  }
                : {}
            }
          >
            <h3>Upload File</h3>
            <div className={styles.media_container}>
              <FontAwesomeIcon
                icon={faPlus}
                color={"#36619b"}
                fontSize={"2.5rem"}
              />
              <p>Upload Excel File</p>
              {/* only except excel files */}
              <input
                ref={inputRef}
                type="file"
                accept="
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            "
                onChange={(e: any) => {
                  bulkUpload(e.target.files[0]);
                }}
              />
            </div>
          </div>

          {selected.length !== 0 && (
            <div className="card grid">
              <div className={styles.table_container_head}>
                <h3>Review Products</h3>
                <DropBtn
                  text="Actions"
                  isIcon={false}
                  open_drop_down_on_action_click={true}
                  icon={faChevronDown}
                  drop_list={
                    selected.length > 0
                      ? [
                          {
                            id: "delete_selected",
                            title: "Delete Selected Products",
                            icon: faTrashAlt,
                          },
                          ...action_drop,
                        ]
                      : action_drop
                  }
                  on_click={() => {
                    inputRef.current.click();
                  }}
                />
              </div>

              <div className={styles.table_container}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      {product_review_head.map((val: any, index: any) => (
                        <th key={index}>{val}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[...selected]
                      .splice(
                        (page - 1) * perPage === 0
                          ? 0
                          : (page - 1) * perPage + 1,
                        perPage
                      )
                      .map((val: any, index: any) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setChecked((prev: any) => {
                                    return [...prev, val._id];
                                  });
                                } else {
                                  setChecked((prev: any) => {
                                    return prev.filter(
                                      (value: any) => value !== val._id
                                    );
                                  });
                                }
                              }}
                            />
                          </td>
                          <td>{index + 1 + perPage * (page - 1)}</td>
                          <td>{val?.title}</td>
                          <td>{val.category}</td>
                          <td>{val.subCategory}</td>
                          <td>{val.productType}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <div className={styles.table_pagination}>
                <Pagination
                  pageNo={page}
                  changePage={(val: any) => {
                    setPage(val);
                  }}
                  total={selected.length}
                  showDrop={true}
                  dropValue={perPage}
                  changeDrop={(val: any) => {
                    setPerPage(Number(val));
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </ContentBody>
    </Content>
  );
};

const action_drop = [
  { id: "delete_all", title: "Delete All", icon: faTrash },
  {
    id: "upload_another_file",
    title: "Upload Another File",
    icon: faUpload,
  },
];

const dummy_data = [
  {
    _id: 1,
    title: "product name",
    category: "category name",
    subCategory: "sub category name",
    type: "type name",
  },
  {
    _id: 2,
    title: "product name",
    category: "category name",
    subCategory: "sub category name",
    type: "type name",
  },
  {
    _id: 3,
    title: "product name",
    category: "category name",
    subCategory: "sub category name",
    type: "type name",
  },
  {
    _id: 4,
    title: "product name",
    category: "category name",
    subCategory: "sub category name",
    type: "type name",
  },
];

export default UploadProduct;

import React, { useEffect, useState } from "react";
import styles from "./subAdmin.module.css";
import SingleUser from "./singleUser";
import { Link, useNavigate } from "react-router-dom";
import TableLoading from "../../LoadingUi/TableLoading";
import axios from "axios";
import toast from "react-hot-toast";
import { ReactComponent as TrashIcon } from "../../../Assets/icons/trash.svg";
import Modal from "../../Modal";
import { useAdminContext } from "../../../Store/adminContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

type Props = {};

let inFocus = "";

const SubAdminPanel = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [permissions, setPermissions]: any = useState([]);
  const [modal, setModal] = useState(false);

  const { admin }: any = useAdminContext();

  const navigate = useNavigate();

  useEffect(() => {
    getAdminByToken();
  }, []);

  const getAdminByToken = () => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}admins/getAdminByToken`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        const user = res.data.data;
        let per = [
          { title: "blog", value: user?.blog || false },
          { title: "contact", value: user?.contact || false },
          { title: "career", value: user?.career || false },
          { title: "caseStudy", value: user?.caseStudy || false },
          { title: "ecommerce", value: user?.ecommerce || false },
          { title: "compliance", value: user?.compliance || false },
          { title: "service", value: user?.service || false },
        ];

        per = per.filter((v) => v.value);

        const final_per: any = {};

        per.forEach((v) => {
          if (v.title === "ecommerce") {
            final_per["catalog_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["sales_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["shoppableVideo_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["users_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["discount_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["content_access"] = {
              read: false,
              write: false,
              delete: false,
            };
          } else if (v.title === "career") {
            final_per["job_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["applicant_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["store_access"] = {
              read: false,
              write: false,
              delete: false,
            };
            final_per["overview_access"] = {
              read: false,
              write: false,
              delete: false,
            };
          } else {
            final_per[v.title + "_access"] = {
              read: false,
              write: false,
              delete: false,
            };
          }
        });

        setPermissions(Object.keys(final_per));
        getSubAdmins();
      })
      .catch((err) => {});
  };

  const getSubAdmins = () => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}sub-admin`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setData(res.data.sub_admins);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData([]);
        toast.error("Something went wrong while fetching sub-admins");
      });
  };

  const handleAction = () => {
    setModal(false);
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}sub-admin/${inFocus}`,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getSubAdmins();
          inFocus = "";
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          inFocus = "";
          return "Error in deleting the product.";
        },
      }
    );
  };

  if (loading)
    return (
      <>
        <h3>Team Members</h3>
        <TableLoading
          head={[
            { title: "Email" },
            { title: "Blog" },
            { title: "Ecommerce" },
            { title: "Contact" },
            { title: "Case Study" },
          ]}
        />
      </>
    );

  if (!loading && data.length === 0)
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>Team Members</h3>
          <Link className={styles.btn} to={"/settings/addNewSubAdmin"}>
            <FontAwesomeIcon icon={faAdd} /> Add Member
          </Link>
        </div>
        <div
          style={{
            width: "100%",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.2rem",
            color: "grey",
          }}
        >
          No Sub-Admin Found
        </div>
      </>
    );

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          setModal(false);
          inFocus = "";
        }}
        onBtn2Click={handleAction}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Team Members</h3>
        {admin.numberOfSubAmdin > data.length ? (
          <Link className={styles.btn} to={"/settings/addNewSubAdmin"}>
            <FontAwesomeIcon icon={faAdd} />
            Add Member
          </Link>
        ) : (
          <button className={styles.btn_cancel} disabled={true}>
            <FontAwesomeIcon icon={faAdd} />
            Add Member
          </button>
        )}
      </div>
      <div className={styles.table_main}>
        <table className={styles.table_container}>
          <thead>
            <tr>
              <th>Name</th>
              {permissions.map((v: any, i: any) => {
                return (
                  <th key={i} style={{ textTransform: "capitalize" }}>
                    {v.replace("_access", "")}
                  </th>
                );
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((subadmin: any, index) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    navigate(`/settings/subadmin/update/${subadmin._id}`)
                  }
                >
                  <td style={{ textTransform: "capitalize" }}>
                    {subadmin.firstName} {subadmin.lastName}
                  </td>
                  {permissions.map((p: any, index: any) => {
                    return <td key={index}>{AccessLevel(subadmin[p])}</td>;
                  })}
                  <td
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setModal(true);
                      inFocus = subadmin._id;
                    }}
                  >
                    <TrashIcon className={styles.del_icon} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const AccessLevel = (subadmin: any) => {
  const v = [
    subadmin.read ? "Read" : "",
    subadmin.write ? "Write" : "",
    subadmin.delete ? "Delete" : "",
  ].filter((vv) => vv);

  if (v.length === 3) return "All";
  if (v.length === 0) return "❌";
  if (v.length === 1) return "Only " + v[0];
  return v.join(", ");
};

export default SubAdminPanel;

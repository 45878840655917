import React, { Component } from "react";
import Content from "../../../Components/Content/Content";
import ContentHead from "../../../Components/Content/ContentHead";
import withRouter from "../../../Components/withRouter";
import ContentBody from "../../../Components/Content/ContentBody";
import InputField from "../../../Components/InputField";
import TextEditor from "../../../Components/TextEditor";
import callApi from "../../../Apis/callApi";
import axios from "axios";
import { toast } from "react-hot-toast";

type props = {};

class CreateJob extends Component<any, props> {
  state = {
    title: "",
    department: "",
    description: "",
    shortDescription: "",
    position: "",
    type: "",
    skils: "",
    experience: "",
    qualification: "",
    salary: "",
    noticePeriod: "",
    location: "",
    workingDays: "",
    shiftTime: "",
    benefits: "",
    hiringProcess: "",
    numberOfOpenings: "",
    loading: false,
  };

  componentDidMount(): void {
    if (this.props.router.params.id) {
      this.setState({ loading: true });
      this.getData();
    }
  }

  getData = () => {
    let data = {};

    axios({
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}jobs/${this.props.router.params.id}`,
    })
      .then((res) => {
        let data = res.data.job;

        this.setState({
          title: data.title,
          department: data.department,
          description: data.description,
          shortDescription: data?.shortDescription,
          position: data.position,
          type: data.typeName,
          skils: data.skils,
          experience: data.experience,
          qualification: data.qualification,
          salary: data.salary,
          noticePeriod: data.noticePeriod,
          location: data.location,
          workingDays: data.workingDays,
          shiftTime: data.shiftTime,
          benefits: data.benefits,
          hiringProcess: data.hiringProcess,
          numberOfOpenings: data.numberOfOpenings,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  validate = () => {
    if (!this.state.title) {
      toast.error("Title is required.");
      return false;
    }

    return true;
  };

  handleSubmit = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/jobs");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });
        let data = {
          title: this.state.title,
          department: this.state.department,
          description: this.state.description,
          shortDescription: this.state.shortDescription,
          position: this.state.position,
          typeName: this.state.type,
          skils: this.state.skils,
          experience: this.state.experience,
          qualification: this.state.qualification,
          salary: this.state.salary,
          noticePeriod: this.state.noticePeriod,
          location: this.state.location,
          workingDays: this.state.workingDays,
          shiftTime: this.state.shiftTime,
          benefits: this.state.benefits,
          hiringProcess: this.state.hiringProcess,
          numberOfOpenings: this.state.numberOfOpenings,
          identifier: localStorage.getItem("identifier"),
          status: event.target.name === "btn1" ? "live" : "draft",
        };

        axios({
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}jobs`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
          data,
        })
          .then((res) => {
            this.setState({ loading: false });
            this.props.router.navigate("/jobs");
          })
          .catch((err) => {
            this.setState({ loading: true });
          });
      }
    }
  };

  updateJob = (event: any) => {
    if (event.target.name === "btn3") {
      this.props.router.navigate("/jobs");
    } else {
      if (this.validate()) {
        this.setState({ loading: true });

        let data = {
          title: this.state.title,
          department: this.state.department,
          description: this.state.description,
          shortDescription: this.state.shortDescription,
          position: this.state.position,
          typeName: this.state.type,
          skils: this.state.skils,
          experience: this.state.experience,
          qualification: this.state.qualification,
          salary: this.state.salary,
          noticePeriod: this.state.noticePeriod,
          location: this.state.location,
          workingDays: this.state.workingDays,
          shiftTime: this.state.shiftTime,
          benefits: this.state.benefits,
          hiringProcess: this.state.hiringProcess,
          numberOfOpenings: this.state.numberOfOpenings,
          identifier: localStorage.getItem("identifier"),
          status: event.target.name === "btn1" ? "live" : "draft",
        };

        axios({
          method: "patch",
          url: `${process.env.REACT_APP_BASE_URL}jobs/${this.props.router.params.id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
          data,
        })
          .then((res) => {
            this.props.router.navigate("/jobs");
            this.setState({ loading: false });
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={this.props?.title ? this.props.title : "Add New Job"}
            showBtn1={
              this.props.admin.role === "subadmin"
                ? this.props.admin.job_access.write
                : true
            }
            showBtn2={
              this.props.admin.role === "subadmin"
                ? this.props.admin.job_access.write
                : true
            }
            showBtn3={true}
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            onClickHandler={
              this.props.router.params.id ? this.updateJob : this.handleSubmit
            }
          />
          <ContentBody>
            <>
              <InputField
                isLabel={true}
                value={this.state.title}
                lable="Job Title"
                type="text"
                isRequired={true}
                placeholder="Job title"
                onChange={(event: any) => {
                  this.setState({ title: event.target.value });
                }}
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  isLabel={true}
                  value={this.state.department}
                  lable="Department"
                  type="text"
                  isRequired={false}
                  placeholder="Department"
                  onChange={(event: any) => {
                    this.setState({ department: event.target.value });
                  }}
                />
                <InputField
                  isLabel={true}
                  value={this.state.position}
                  lable="Position"
                  type="text"
                  isRequired={false}
                  placeholder="Position"
                  onChange={(event: any) => {
                    this.setState({ position: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  isLabel={true}
                  value={this.state.type}
                  lable="Type"
                  type="text"
                  isRequired={false}
                  placeholder="Type"
                  onChange={(event: any) => {
                    this.setState({ type: event.target.value });
                  }}
                />
                <InputField
                  isLabel={true}
                  value={this.state.experience}
                  lable="Experience"
                  type="text"
                  isRequired={false}
                  placeholder="Experience"
                  onChange={(event: any) => {
                    this.setState({ experience: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  isLabel={true}
                  value={this.state.noticePeriod}
                  lable="Notice Period"
                  type="text"
                  isRequired={false}
                  placeholder="Notice period"
                  onChange={(event: any) => {
                    this.setState({ noticePeriod: event.target.value });
                  }}
                />
                <InputField
                  isLabel={true}
                  value={this.state.location}
                  lable="Location"
                  type="text"
                  isRequired={false}
                  placeholder="Location"
                  onChange={(event: any) => {
                    this.setState({ location: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  isLabel={true}
                  value={this.state.workingDays}
                  lable="Working Days"
                  type="number"
                  isRequired={false}
                  placeholder="Working days"
                  onChange={(event: any) => {
                    this.setState({ workingDays: event.target.value });
                  }}
                />
                <InputField
                  isLabel={true}
                  value={this.state.shiftTime}
                  lable="Shift Timings"
                  type="text"
                  isRequired={false}
                  placeholder="Shift timings"
                  onChange={(event: any) => {
                    this.setState({ shiftTime: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  isLabel={true}
                  value={this.state.numberOfOpenings}
                  lable="Number of Openings"
                  type="number"
                  isRequired={false}
                  placeholder="Number of openings"
                  onChange={(event: any) => {
                    this.setState({ numberOfOpenings: event.target.value });
                  }}
                />
                <InputField
                  isLabel={true}
                  value={this.state.salary}
                  lable="Salary"
                  type="text"
                  isRequired={false}
                  placeholder="Salary"
                  onChange={(event: any) => {
                    this.setState({ salary: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <InputField
                  isLabel={true}
                  value={this.state.experience}
                  lable="Experience"
                  type="text"
                  isRequired={false}
                  placeholder="Experience"
                  onChange={(event: any) => {
                    this.setState({ experience: event.target.value });
                  }}
                />
                <InputField
                  isLabel={true}
                  value={this.state.skils}
                  lable="Skills (Comma separated values)"
                  type="text"
                  isRequired={false}
                  placeholder="skill-1, skill-2"
                  onChange={(event: any) => {
                    this.setState({ skils: event.target.value });
                  }}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "0px",
                  marginTop: "20px",
                }}
              >
                <>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      transform: "translateX(5px)",
                    }}
                  >
                    Qualification
                  </p>
                  <TextEditor
                    content={this.state.qualification}
                    handleChange={(
                      value: any,
                      delta: any,
                      source: any,
                      editor: any
                    ) => {
                      this.setState({ qualification: value });
                    }}
                  />
                </>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "0px",
                  marginTop: "20px",
                }}
              >
                <>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      transform: "translateX(5px)",
                    }}
                  >
                    Benefits
                  </p>
                  <TextEditor
                    content={this.state.benefits}
                    handleChange={(
                      value: any,
                      delta: any,
                      source: any,
                      editor: any
                    ) => {
                      this.setState({ benefits: value });
                    }}
                  />
                </>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "0px",
                  marginTop: "20px",
                }}
              >
                <>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      transform: "translateX(5px)",
                    }}
                  >
                    Hiring Process
                  </p>
                  <TextEditor
                    content={this.state.hiringProcess}
                    handleChange={(
                      value: any,
                      delta: any,
                      source: any,
                      editor: any
                    ) => {
                      this.setState({ hiringProcess: value });
                    }}
                  />
                </>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "0px",
                  marginTop: "20px",
                }}
              >
                <>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      transform: "translateX(5px)",
                    }}
                  >
                    Short Description
                  </p>
                  <TextEditor
                    content={this.state.shortDescription}
                    handleChange={(
                      value: any,
                      delta: any,
                      source: any,
                      editor: any
                    ) => {
                      this.setState({ shortDescription: value });
                    }}
                  />
                </>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "0px",
                  marginTop: "20px",
                }}
              >
                <>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      transform: "translateX(5px)",
                    }}
                  >
                    Description
                  </p>
                  <TextEditor
                    content={this.state.description}
                    handleChange={(
                      value: any,
                      delta: any,
                      source: any,
                      editor: any
                    ) => {
                      this.setState({ description: value });
                    }}
                  />
                </>
              </div>
            </>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(CreateJob);

import React, { useEffect, useState } from "react";
import styles from "./orders.module.css";
import axios from "axios";
import Orders from "../../../Components/Analytics/Orders/Orders";
import NewOrder from "../../../Components/Analytics/OrdersMain/NewOrder";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";

type Props = {};
const url = process.env.REACT_APP_BASE_URL;
export type SubcategoryData = {
  total: number;
  date: String;
};

interface Subcategories {
  [key: string]: SubcategoryData[];
}

interface Datasets {
  [key: string]: Subcategories;
}

const OrdersPage = (props: Props) => {
  const [order, setOrder] = useState<any>(null);

  const today = new Date();

  const [endDate, setEndDate] = useState(today.toISOString());
  const [startDate, setStartDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 2).toISOString()
  );
  const getOrdersData = () => {
    axios({
      url: `${url}analytics/getOrderInDetails?startDate=${startDate}&endDate=${endDate}&identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        const { datasets } = res.data as Datasets;
        setOrder(datasets);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getOrdersData();
  }, [startDate, endDate]);
  return (
    <div className={styles.container}>
      <div className={styles.Top}>
        <Link
          className={styles.Link}
          to={"/analytics"}
          style={{ background: "none" }}
        >
          <MdArrowBack size={22} color="#0E81F1" />
        </Link>
        <h1 className={styles.Heading}>Orders</h1>
      </div>
      <div className="styles.filterContainer">
        {/* <FilterData
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                filterProductsByCategory={filterProductsByCategory}
                categories={categories}
                
            /> */}
      </div>
      <div className={styles.chartContainer}>
        {order && (
          <NewOrder
            order={order}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        )}
      </div>
    </div>
  );
};

export default OrdersPage;

import React, { Component } from "react";
import styles from "../../Styles/userdetails.module.css";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import Table from "../../Components/Table/Table";
import axios from "axios";
import withRouter from "../../Components/withRouter";

type Props = {
  pastOrders: any;
  ongoingOrders: any;
  customerData: any;
};

class UserDetails extends Component<any, Props> {
  state = {
    pastOrders: [],
    ongoingOrders: [],
    customerData: {},
  };

  componentDidMount(): void {
    this.getOrderData();
  }

  getOrderData = () => {
    axios({
      method: "get",
      url:
        `${process.env.REACT_APP_BASE_URL}orders/getordersbyuseridadmin/` +
        this.props.router.params.id +
        "/" +
        localStorage.getItem("identifier"),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        let allOrders = res.data.orders;

        // let val = allOrders[0].customerId;

        let pOrders: any = [];
        let oOrders: any = [];

        allOrders.forEach((order: any) => {
          if (order.fulfilmentStatus === "completed") {
            pOrders.push(order);
          } else {
            oOrders.push(order);
          }
        });

        this.setState({
          pastOrders: [...pOrders],
          ongoingOrders: [...oOrders],
          // customerData: val,
        });
      })
      .catch((err) => {});
  };

  render() {
    return (
      <Content>
        <ContentHead title="User Details" />
        <ContentBody>
          <div className={styles.container}>
            <div className={styles.details_container}>
              <div className={styles.details_first}>
                <p>
                  {/* @ts-ignore */}
                  First Name : <span>{this.state.customerData.firstName}</span>
                </p>
                <p>
                  {/* @ts-ignore */}
                  Last Name : <span>{this.state.customerData.lastName}</span>
                </p>
                <p>
                  {/* @ts-ignore */}
                  DOB : <span>{this.state.customerData.dob}</span>
                </p>
                <p>
                  {/* @ts-ignore */}
                  Email : <span>{this.state.customerData.email}</span>
                </p>
              </div>

              <div className={styles.details_second}>
                <div>
                  <p>Shipping Address</p>
                  {/* @ts-ignore */}
                  <pre></pre>
                </div>
                <div>
                  <p>Billing Address</p>
                  <pre></pre>
                </div>
              </div>
            </div>
            <div className={styles.orders}>
              <h2>Past Orders</h2>
              <div style={{ overflow: "hidden", flexGrow: 0 }}>
                {this.state.pastOrders.length !== 0 ? (
                  <Table
                    head={[
                      { title: "S.NO." },
                      { title: "Product" },
                      { title: "Quantity" },
                      { title: "Price" },
                    ]}
                    body={[...this.state.pastOrders]}
                    body_keys={["productId.title", "quantity", "total"]}
                    number={true}
                    onRowClick={(val: any) => {
                      this.props.router.navigate("/orders/details/" + val._id);
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "grey",
                    }}
                  >
                    No Order Found
                  </div>
                )}
              </div>
            </div>
            <div className={styles.orders}>
              <h2>Ongoing Orders</h2>
              {this.state.ongoingOrders.length !== 0 ? (
                <Table
                  head={[
                    { title: "S.NO." },
                    { title: "Product" },
                    { title: "Quantity" },
                    { title: "Price" },
                  ]}
                  body={[...this.state.ongoingOrders]}
                  body_keys={["productId.title", "quantity", "total"]}
                  number={true}
                  onRowClick={(val: any) => {
                    this.props.router.navigate("/orders/details/" + val._id);
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "grey",
                  }}
                >
                  No Order Found
                </div>
              )}
            </div>
          </div>
        </ContentBody>
      </Content>
    );
  }
}

export default withRouter(UserDetails);

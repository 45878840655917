import React from "react";
import InputField from "../InputField";
import { folder_name } from "../../Utils/UploadImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const SimilarProducts = (props: any) => {
  const handleOptionClick = (e: any) => {
    props.onChange([...props.similarProduct, e.target.value]);
  };

  return (
    <div className="card grid">
      <h3>Similar Products</h3>
      <InputField
        type="select"
        isLabel={true}
        lable={"Select Product"}
        options={[...props?.products]}
        accessText="title"
        accessValue="_id"
        value=""
        onChange={handleOptionClick}
        placeholder="Select Products"
      />
      <ul className="grid" style={{ listStyle: "none" }}>
        {props?.similarProduct?.map((el: any, index: any) => {
          let product = props?.products?.filter(
            (val: any) => val._id === el
          )[0];

          return (
            <li
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                border: "1px dashed black",
                borderRadius: "20px",
                padding: "10px",
                position: "relative",
              }}
            >
              <img
                src={product?.mediaUrl[0]}
                alt=""
                style={{
                  height: "70px",
                  width: "70px",
                  objectFit: "contain",
                  objectPosition: "center",
                  border: "1px solid black",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              />
              <div>
                <span
                  style={{ fontSize: ".9rem", textTransform: "capitalize" }}
                >
                  {product?.category}
                </span>
                <h4 style={{ fontWeight: 400, textTransform: "capitalize" }}>
                  {product?.title}
                </h4>
              </div>
              <FontAwesomeIcon
                icon={faClose}
                style={{
                  position: "absolute",
                  height: "20px",
                  width: "20px",
                  background: "#eff5fc",
                  color: "#36619b",
                  padding: "5px",
                  borderRadius: "100%",
                  right: 0,
                  top: 0,
                  transform: "translate(50%, -50%)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let temp = [...props.similarProduct];
                  temp.splice(index, 1);
                  props.onChange(temp);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SimilarProducts;

import React, { useEffect, useState } from "react";
import styles from "./mostViewesProdustPAge.module.css";
import axios from "axios";
import HorizontalBarChart from "../../../Components/Charts/HorizontalBarChart";
import FilterData from "../../../Components/Analytics/filterData";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

type Props = {};
type MostViewedProducts = Product[];
type Product = {
  title: string;
  impression: number;
  category: string;
};
const url = process.env.REACT_APP_BASE_URL;
const MostViewedProductPage = (props: Props) => {
  const [mostViewedProducts, setMostViewedProducts] =
    useState<MostViewedProducts>();
  const [selectedCategory, setSelectedCategory] = useState("");
  useEffect(() => {
    getMostViewedProducts();
  }, []);

  const getMostViewedProducts = () => {
    axios({
      url: `${url}analytics/getTopViewedProducts?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        setMostViewedProducts(
          res.data.topViewedProducts.map((v: any) => ({
            title: v.title,
            impression: v.impression,
            category: v.category,
          }))
        );
      })
      .catch((err) => {});
  };

  const filterProductsByCategory = (): Product[] => {
    if (!selectedCategory) {
      return mostViewedProducts as Product[];
    }
    return mostViewedProducts?.filter(
      (product: Product) =>
        product.category.toLowerCase() === selectedCategory.toLowerCase()
    ) as Product[];
  };
  const filteredData = filterProductsByCategory();

  // Get unique categories
  const getUniqueCategories = () => {
    const categories = new Set();
    mostViewedProducts?.forEach((product) => categories.add(product?.category));
    return Array.from(categories) as string[];
  };

  const categories = getUniqueCategories();
  const data = {
    labels: filteredData?.map((data) => data.title),
    datasets: [
      {
        label: "Most Viewed",
        data: filteredData?.map((data) => data.impression),
        backgroundColor: "#397EE6",
      },
    ],
  };
  return (
    <div className={styles.container}>
      <div className={styles.Top}>
        <Link
          className={styles.Link}
          to={"/analytics"}
          style={{ background: "none" }}
        >
          <MdArrowBack size={22} color="#0E81F1" />
        </Link>
        <h1 className={styles.Heading}>Most Viewed Products</h1>
      </div>
      <div className="styles.filterContainer">
        <FilterData
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
        />
      </div>
      <div className={styles.chartContainer}>
        <HorizontalBarChart data={data} />
      </div>
    </div>
  );
};

export default MostViewedProductPage;

import { useEffect, useState } from "react";
import AppCard from "./AppCard";
import IntegrationModel from "./IntegrationModel";
import axios from "axios";

const AppsContainer = () => {
  const [open, setOpen] = useState(false);
  const [app, setApp]: any = useState(null);
  const [integrated, setIntegrated]: any = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllIntegrations();
  }, []);

  const getAllIntegrations = () => {
    setLoading(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}admins/get-all-integrations`,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setIntegrated(res.data.integrations);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleIntegration = (v: any) => {
    setApp(v);
    setOpen(true);
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="loader"></span>
      </div>
    );

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div>
          <h4 style={{ fontWeight: 500 }}>Integrated</h4>
        </div>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1rem",
            paddingBottom: "20px",
          }}
        >
          {applications
            .filter((v) => integrated.includes(v.integrationName))
            .map((application, index) => {
              return (
                <AppCard
                  application={application}
                  key={index}
                  onIntegrateClick={handleIntegration}
                  isIntegrated={true}
                />
              );
            })}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <div>
          <h4 style={{ fontWeight: 500 }}>Available</h4>
        </div>
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1rem",
          }}
        >
          {applications
            .filter((v) => !integrated.includes(v.integrationName))
            .map((application, index) => {
              return (
                <AppCard
                  application={application}
                  key={index}
                  onIntegrateClick={handleIntegration}
                  isIntegrated={false}
                />
              );
            })}
        </div>
      </div>
      <IntegrationModel
        open={open}
        setOpen={setOpen}
        app={app}
        setApp={setApp}
        getAllIntegrations={getAllIntegrations}
      />
    </>
  );
};

const applications = [
  {
    title: "Sendgrid",
    logo: "/apps/sendgrid.svg",
    description:
      "SendGrid is a cloud-based email delivery service that helps send transactional and marketing emails reliably and at scale.",
    fields: [
      {
        label: "key",
        name: "key1",
        type: "text",
        placeholder: "Enter API Key",
        required: true,
      },
    ],
    integrationName: "sendgrid",
  },
  {
    title: "Phonepe",
    logo: "/apps/phonepe.svg",
    description:
      "PhonePe is a leading digital payment platform in India, enabling secure UPI transactions, bill payments, and online shopping services.",
    fields: [
      {
        label: "Merchant Id",
        name: "key1",
        type: "text",
        placeholder: "Enter Merchant Id",
        required: true,
      },
      {
        label: "Secret Key",
        name: "key2",
        type: "text",
        placeholder: "Enter Secret Key",
        required: true,
      },
    ],
    integrationName: "phonepe",
  },
  {
    title: "Razorpay",
    logo: "/apps/razorpay.png",
    description:
      "Razorpay is an Indian payment gateway offering businesses seamless solutions for accepting, processing, and disbursing payments online.",
    fields: [
      {
        label: "Merchant Id",
        name: "key1",
        type: "text",
        placeholder: "Enter Merchant Id",
        required: true,
      },
      {
        label: "Secret Key",
        name: "key2",
        type: "text",
        placeholder: "Enter Secret Key",
        required: true,
      },
    ],
    integrationName: "razorpay",
  },
  {
    title: "Tag Manager",
    category: "script",
    logo: "/apps/google.svg",
    description:
      "Google Tag Manager is a free tool that simplifies managing and deploying marketing tags and tracking codes on websites efficiently.",
    fields: [
      {
        label: "Head Script",
        name: "key1",
        type: "textarea",
        placeholder: "Enter Google Tag Manager Head Script",
        required: true,
      },
      {
        label: "Body Script",
        name: "key2",
        type: "textarea",
        placeholder: "Enter Google Tag Manager Body Script",
        required: true,
      },
    ],
    integrationName: "googleTagManager",
  },
  {
    title: "Analytics",
    category: "script",
    logo: "/apps/google.svg",
    description:
      "Google Analytics is a web analytics tool that helps track website traffic, user behavior, and performance insights for data-driven decisions.",
    fields: [
      {
        label: "Analytics Script",
        name: "key1",
        type: "textarea",
        placeholder: "Enter Google Analytics Script",
        required: true,
      },
    ],
    integrationName: "googleAnalytics",
  },
  {
    title: "Search Console",
    category: "script",
    logo: "/apps/google.svg",
    description:
      "Google Search Console is a free tool that helps monitor website performance, track search rankings, and troubleshoot indexing issues.",
    fields: [
      {
        label: "Script",
        name: "key1",
        type: "textarea",
        placeholder: "Enter Script",
        required: true,
      },
    ],
    integrationName: "googleSearchConsole",
  },
  {
    title: "InTargos",
    category: "logistics",
    logo: "/apps/intargos.webp",
    description:
      "Intargos is an AI-driven logistics platform optimizing e-commerce shipping with real-time tracking, multi-carrier integration, and data-driven analytics.",
    fields: [
      {
        label: "Keys",
        name: "key1",
        type: "text",
        placeholder: "Enter InTargos Keys",
        required: true,
      },
      // {
      //   label: "Integration Method",
      //   name: "integrationMethod",
      //   type: "select",
      //   options: [
      //     { title: "Manual", value: "Manual" },
      //     { title: "Automatic", value: "Automatic" },
      //   ],
      //   accessText: "title",
      //   accessValue: "value",
      //   placeholder: "Select Integration Method",
      //   required: true,
      // },
    ],
    rules: [
      [
        {
          label: "Deliverable Pin Codes",
          name: "deliverablePinCodes",
          type: "multiple",
          options: [],
          placeholder: "Deliverable Pin Codes",
          required: true,
        },
      ],
    ],
    integrationName: "inTargos",
  },
  {
    title: "Sequel",
    category: "logistics",
    logo: "/apps/sequel.png",
    description:
      "Sequel Logistics provides secure, specialized supply chain solutions for high-value shipments, critical healthcare logistics, and sensitive technology equipment globally.",
    fields: [
      {
        label: "Keys",
        name: "key1",
        type: "text",
        placeholder: "Enter Sequel Keys",
        required: true,
      },
      // {
      //   label: "Integration Method",
      //   name: "integrationMethod",
      //   type: "select",
      //   options: [
      //     { title: "Manual", value: "Manual" },
      //     { title: "Automatic", value: "Automatic" },
      //   ],
      //   accessText: "title",
      //   accessValue: "value",
      //   placeholder: "Select Integration Method",
      //   required: true,
      // },
    ],
    rules: [
      [
        {
          label: "Deliverable Pin Codes",
          name: "deliverablePinCodes",
          type: "multiple",
          options: [],
          placeholder: "Deliverable Pin Codes",
          required: true,
        },
      ],
    ],
    integrationName: "sequel",
  },
  {
    title: "Ship Rocket",
    category: "logistics",
    logo: "/apps/shiprocket.webp",
    description:
      "Shiprocket is an Indian logistics platform streamlining shipping for businesses, offering courier integration, real-time tracking, and cost-effective solutions.",
    fields: [],
    rules: [],
    integrationName: "shipRocket",
    comingSoon: true,
  },
  {
    title: "Flipkart",
    logo: "/apps/flipkart-icon.svg",
    description:
      "Flipkart is an Indian e-commerce giant offering diverse products, known for competitive pricing, fast delivery, and festive sales events.",
    fields: [],
    rules: [],
    integrationName: "flipkart",
    comingSoon: true,
  },
];

export default AppsContainer;

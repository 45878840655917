import { findIndex } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { blogg } from "../Utils/Data";
import { useAdminContext } from "./adminContext";

const SideBarContext = createContext({});

type props = {
  children: any;
};

const SideBarProvider = (props: props) => {
  const [identifier, setIdentifier] = useState("");
  const [sidebar, setSidebar] = useState(blogg);
  const { admin }: any = useAdminContext();

  useEffect(() => {
    if (localStorage.getItem("token-BO-ADMIN")) {
      let temp: any = sidebar;

      if (admin?.role === "admin" || admin?.role === "superAdmin") {
        // check for blog
        if (localStorage.getItem("blog") === "false") {
          const bIndex = findIndex(temp, (val: any) => {
            return val.title === "blog";
          });

          if (bIndex !== -1) temp.splice(bIndex, 1);
        }

        if (
          localStorage.getItem("blog") === "true" &&
          localStorage.getItem("ecommerce") === "false"
        ) {
          const aIndex = findIndex(temp, (val: any) => {
            return val.title === "overview";
          });
          if (aIndex !== -1) temp.splice(aIndex, 1);

          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "catalog";
          });
          if (cIndex !== -1) temp.splice(cIndex, 1);
        }

        // check for contact
        if (localStorage.getItem("contact") === "false") {
          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "lead";
          });

          if (cIndex !== -1) temp.splice(cIndex, 1);
        }

        // check for jobs
        if (localStorage.getItem("career") === "false") {
          const cIndex1 = findIndex(temp, (val: any) => {
            return val.title === "job";
          });
          if (cIndex1 !== -1) temp.splice(cIndex1, 1);

          const cIndex2 = findIndex(temp, (val: any) => {
            return val.title === "applicants";
          });
          if (cIndex2 !== -1) temp.splice(cIndex2, 1);
        }

        if (
          localStorage.getItem("ecommerce") === "false" &&
          localStorage.getItem("service") === "true"
        ) {
          const eIndex = findIndex(temp, (val: any) => {
            return val.title === "catalog";
          });

          if (eIndex !== -1) temp.splice(eIndex, 1);

          // const dIndex = findIndex(temp, (val: any) => {
          //   return val.title === "Discounts";
          // });
          // temp.splice(dIndex, 1);

          const vIndex = findIndex(temp, (val: any) => {
            return val.title === "productVideo";
          });

          if (vIndex !== -1) temp.splice(vIndex, 1);
        }

        // check for ecommerce
        if (
          localStorage.getItem("ecommerce") === "false" &&
          localStorage.getItem("service") === "false"
        ) {
          // const cIndex = findIndex(temp, (val: any) => {
          //   return val.title === "content";
          // });

          // temp.splice(cIndex, 1);

          const eIndex = findIndex(temp, (val: any) => {
            return val.title === "catalog";
          });

          if (eIndex !== -1) temp.splice(eIndex, 1);

          const oIndex = findIndex(temp, (val: any) => {
            return val.title === "sales";
          });

          if (oIndex !== -1) temp.splice(oIndex, 1);

          const uIndex = findIndex(temp, (val: any) => {
            return val.title === "user";
          });

          if (uIndex !== -1) temp.splice(uIndex, 1);

          const dIndex = findIndex(temp, (val: any) => {
            return val.title === "discount";
          });

          if (dIndex !== -1) temp.splice(dIndex, 1);

          const vIndex = findIndex(temp, (val: any) => {
            return val.title === "productVideo";
          });

          if (vIndex !== -1) temp.splice(vIndex, 1);

          const auIndex = findIndex(temp, (val: any) => {
            return val.title === "audiences";
          });

          if (auIndex !== -1) {
            temp[auIndex].child = temp[auIndex].child.filter((v: any) => {
              return v.title !== "user" && v.title !== "segments";
            });
          }
        }

        if (localStorage.getItem("service") === "false") {
          const sIndex = findIndex(temp, (val: any) => {
            return val.title === "service";
          });
          if (sIndex !== -1) temp.splice(sIndex, 1);
        }

        if (localStorage.getItem("caseStudy") === "false") {
          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "caseStudy";
          });

          temp.splice(cIndex, 1);
        }

        if (localStorage.getItem("productVideo") === "false") {
          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "shoppable video";
          });
          if (cIndex !== -1) temp.splice(cIndex, 1);
        }
      } else if (admin?.role === "subadmin") {
        // for blog
        if (
          !(
            admin.blog_access.read ||
            admin.blog_access.write ||
            admin.blog_access.delete
          )
        ) {
          const bIndex = findIndex(temp, (val: any) => {
            return val.title === "blog";
          });

          temp.splice(bIndex, 1);
        }

        // for caseStudy
        if (
          !(
            admin.caseStudy_access.read ||
            admin.caseStudy_access.write ||
            admin.caseStudy_access.delete
          )
        ) {
          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "caseStudy";
          });

          temp.splice(cIndex, 1);
        }

        if (
          !(
            admin.contact_access.read ||
            admin.contact_access.write ||
            admin.contact_access.delete
          )
        ) {
          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "lead";
          });

          temp.splice(cIndex, 1);
        }

        if (
          !(
            admin.job_access.read ||
            admin.job_access.write ||
            admin.job_access.delete
          )
        ) {
          const cIndex1 = findIndex(temp, (val: any) => {
            return val.title === "job";
          });
          temp.splice(cIndex1, 1);
        }

        if (
          !(
            admin.applicant_access.read ||
            admin.applicant_access.write ||
            admin.applicant_access.delete
          )
        ) {
          const cIndex2 = findIndex(temp, (val: any) => {
            return val.title === "applicants";
          });
          temp.splice(cIndex2, 1);
        }

        if (!admin.service) {
          if (localStorage.getItem("service") === "false") {
            const sIndex = findIndex(temp, (val: any) => {
              return val.title === "service";
            });
            temp.splice(sIndex, 1);
          }
        }

        if (
          !(
            admin.catalog_access.read ||
            admin.catalog_access.write ||
            admin.catalog_access.delete
          )
        ) {
          const eIndex = findIndex(temp, (val: any) => {
            return val.title === "catalog";
          });

          temp.splice(eIndex, 1);
        }

        if (
          !(
            admin.sales_access.read ||
            admin.sales_access.write ||
            admin.sales_access.delete
          )
        ) {
          const oIndex = findIndex(temp, (val: any) => {
            return val.title === "sales";
          });

          temp.splice(oIndex, 1);
        }

        if (
          !(
            admin.users_access.read ||
            admin.users_access.write ||
            admin.users_access.delete
          )
        ) {
          const uIndex = findIndex(temp, (val: any) => {
            return val.title === "user";
          });
          temp.splice(uIndex, 1);
        }

        if (
          !(
            admin.discount_access.read ||
            admin.discount_access.write ||
            admin.discount_access.delete
          )
        ) {
          const dIndex = findIndex(temp, (val: any) => {
            return val.title === "discount";
          });
          temp.splice(dIndex, 1);
        }

        if (
          !(
            admin.shoppableVideo_access.read ||
            admin.shoppableVideo_access.write ||
            admin.shoppableVideo_access.delete
          )
        ) {
          const vIndex = findIndex(temp, (val: any) => {
            return val.title === "productVideo";
          });
          temp.splice(vIndex, 1);
        }

        if (
          !(
            admin.content_access.read ||
            admin.content_access.write ||
            admin.content_access.delete
          )
        ) {
          // const cIndex = findIndex(temp, (val: any) => {
          //   return val.title === "content";
          // });
          // temp.splice(cIndex, 1);
        }

        if (
          !(
            admin.compliance_access.read ||
            admin.compliance_access.write ||
            admin.compliance_access.delete
          )
        ) {
          const cIndex = findIndex(temp, (val: any) => {
            return val.title === "compliance";
          });

          temp.splice(cIndex, 1);
        }

        if (
          !(
            admin.overview_access.read ||
            admin.overview_access.write ||
            admin.overview_access.delete
          )
        ) {
          const aIndex = findIndex(temp, (val: any) => {
            return val.title === "overview";
          });
          temp.splice(aIndex, 1);
        }
      }

      setSidebar([...temp]);
    }
  }, []);

  const value = {
    identifier: identifier,
    sidebar: sidebar,
    setIdentifier: (val: any) => {
      setIdentifier(val);
    },
    updateSideBar: (val: any) => {
      localStorage.setItem("blog", val.blog);
      localStorage.setItem("contact", val.contact);
      localStorage.setItem("career", val.career);
      localStorage.setItem("ecommerce", val.ecommerce);
      localStorage.setItem("service", val.service);
      localStorage.setItem("caseStudy", val.caseStudy);
      localStorage.setItem("productVideo", val.productVideo.isActive);

      let temp: any = sidebar;

      // check for blog
      if (!val.blog) {
        const bIndex = findIndex(temp, (val: any) => {
          return val.title === "blog";
        });

        temp.splice(bIndex, 1);
      }

      // check for contact
      if (!val.contact) {
        const cIndex = findIndex(temp, (val: any) => {
          return val.title === "lead";
        });

        temp.splice(cIndex, 1);
      }

      // check for jobs
      if (!val.career) {
        const cIndex1 = findIndex(temp, (val: any) => {
          return val.title === "job";
        });
        temp.splice(cIndex1, 1);

        const cIndex2 = findIndex(temp, (val: any) => {
          return val.title === "applicants";
        });

        temp.splice(cIndex2, 1);
      }

      if (!val.caseStudy) {
        const cIndex = findIndex(temp, (val: any) => {
          return val.title === "caseStudy";
        });

        temp.splice(cIndex, 1);
      }

      if (!val.ecommerce && val.service) {
        const eIndex = findIndex(temp, (val: any) => {
          return val.title === "catalog";
        });
        temp.splice(eIndex, 1);

        const dIndex = findIndex(temp, (val: any) => {
          return val.title === "discount";
        });
        temp.splice(dIndex, 1);
      }

      // check for ecommerce
      if (!val.ecommerce && !val.service) {
        const eIndex = findIndex(temp, (val: any) => {
          return val.title === "catalog";
        });
        temp.splice(eIndex, 1);

        const oIndex = findIndex(temp, (val: any) => {
          return val.title === "order";
        });
        temp.splice(oIndex, 1);

        const uIndex = findIndex(temp, (val: any) => {
          return val.title === "user";
        });
        temp.splice(uIndex, 1);

        const dIndex = findIndex(temp, (val: any) => {
          return val.title === "discount";
        });
        temp.splice(dIndex, 1);
      }

      setSidebar([...temp]);
    },
  };

  return (
    <SideBarContext.Provider value={value}>
      {props.children}
    </SideBarContext.Provider>
  );
};

export { SideBarProvider, SideBarContext };

import { useState } from "react";
import styles from "./productConfiguration.module.css";
import { ReactComponent as ImportIcon } from "../../../Assets/icons/import.svg";
import { ReactComponent as FileIcon } from "../../../Assets/icons/file.svg";
import { ReactComponent as TrashIcon } from "../../../Assets/icons/trash.svg";
import Info from "../../info";
import InputField from "../../InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

type Props = {
  files: any;
  setFiles: any;
  encoding: string;
  setEncoding: any;
  delimiter: string;
  setDelimiter: any;
  tsvDelimiter: string;
  setTsvDelimiter: any;
  duplicate: any;
  setDuplicate: any;
  platform: any;
  setPlatform: any;
  pages: any;
  setPages: any;
  pageFrom: any;
  setPageFrom: any;
  pageTo: any;
  setPageTo: any;
  entries: any;
  setEntries: any;
  getSheetsInFile: any;
  sheets: any;
  customEntries: any;
  setCustomEntries: any;
};

const ProductConfiguration = ({
  files,
  setFiles,
  encoding,
  setEncoding,
  delimiter,
  setDelimiter,
  tsvDelimiter,
  setTsvDelimiter,
  duplicate,
  setDuplicate,
  platform,
  setPlatform,
  pages,
  setPages,
  pageFrom,
  setPageFrom,
  pageTo,
  setPageTo,
  entries,
  setEntries,
  getSheetsInFile,
  sheets,
  customEntries,
  setCustomEntries,
}: Props) => {
  return (
    <div className={styles.configuration_container}>
      <div className={styles.import_container}>
        {files.length === 0 && (
          <div className={styles.import_icon}>
            <ImportIcon />
          </div>
        )}

        {files.length !== 0 && (
          <div className={styles.files}>
            {files.map((file: any, index: any) => {
              return (
                <div key={index} className={styles.file}>
                  <FileIcon className={styles.file_icon} />
                  <p className={styles.file_name}>{file.name}</p>
                  <div
                    className={styles.remove_file}
                    onClick={() => {
                      let temp = [...files];
                      temp.splice(index, 1);
                      setFiles(temp);
                    }}
                  >
                    <TrashIcon />
                    <p>Remove</p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <p>Drag and drop file to import</p>
        <div className={styles.choose_btn}>
          <input
            type="file"
            accept=".csv,.tsv,.xls,.xlsx,text/csv,text/tab-separated-values,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            multiple
            onChange={(e: any) => {
              setFiles((prev: any) => [...prev, ...e.target.files]);
              getSheetsInFile(e.target.files[0]);
            }}
          />
          <p>Choose File</p>
        </div>
        <p>Maximum File Size: 25 MB, File Format: CSV or TSV or XLS</p>
      </div>
      <p className={styles.sample_text}>
        Download a{" "}
        <a
          download={"ShopIQ_Sample_Sheet.xlsx"}
          href="/bo_bulk_product_upload.xlsx"
          style={{
            padding: 0,
            textDecoration: "underline",
            color: "var(--brand-color)",
            background: "none",
          }}
        >
          sample file
        </a>{" "}
        and compare it to your import file to ensure you have the file perfect
        for the import.{" "}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>Imported From</label>
          <Info
            text="Select Platform from which sheet is imported."
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "ShopIQ", value: "shopiq" },
              { title: "Amazon", value: "amazon" },
              // { title: "Shopify", value: "shopify" },
            ]}
            accessText="title"
            accessValue="value"
            value={platform}
            onChange={(e: any) => setPlatform(e.target.value)}
          />
        </div>
      </div>
      {/* Pages to read */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
            marginTop: "15px",
          }}
        >
          <label>Pages To Read</label>
          <Info
            text="Select Pages you want to import."
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "All", value: "all" },
              { title: "Custom", value: "custom" },
            ]}
            accessText="title"
            accessValue="value"
            value={pages}
            onChange={(e: any) => {
              if (e.target.value === "all") {
                let temp: any = {};
                sheets.forEach((v: any) => {
                  temp[v] = { from: "", to: "" };
                });
                setCustomEntries(temp);
              } else if (e.target.value === "custom") {
                setCustomEntries({});
              }

              setPages(e.target.value);
            }}
          />
          {pages !== "all" && (
            <>
              <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
                <InputField
                  type="select"
                  options={sheets
                    .filter((v: any) => !pageFrom.includes(v))
                    .map((sheet: any) => ({
                      title: sheet,
                      value: sheet,
                    }))}
                  accessText="title"
                  accessValue="value"
                  isLabel={true}
                  lable={"Select Sheets"}
                  value={""}
                  onChange={(e: any) => {
                    let temp = { ...customEntries };
                    temp[e.target.value] = { from: "", to: "" };
                    setCustomEntries(temp);
                    setPageFrom((prev: any) => [...prev, e.target.value]);
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                {pageFrom?.map((v: any, i: any) => {
                  return (
                    <div
                      key={i}
                      style={{
                        background: "var(--blue-light)",
                        color: "var(--blue-dark)",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        marginTop: "5px",
                        fontSize: ".9rem",
                      }}
                    >
                      <p>{v}</p>
                      <FontAwesomeIcon
                        icon={faClose}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let temp = [...pageFrom];
                          let left: any = temp.splice(i, 1);

                          let tt = { ...customEntries };
                          delete tt[left];
                          setCustomEntries(tt);
                          setPageFrom(temp);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <p style={{ fontSize: ".9rem", marginTop: "5px", color: "grey" }}>
                Number of Sheets Available : {sheets.length}
              </p>
            </>
          )}
        </div>
      </div>
      {/* Entries to read */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
            marginTop: "15px",
          }}
        >
          <label>Entries To Read</label>
          <Info
            text="Number of entries to read"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%", display: "flex", flexDirection: "column" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "All", value: "all" },
              { title: "Custom", value: "custom" },
            ]}
            accessText="title"
            accessValue="value"
            value={entries}
            onChange={(e: any) => {
              setEntries(e.target.value);
              if (e.target.value === "all") {
                setCustomEntries({});
              }
            }}
          />

          {entries !== "all" && (
            <>
              {Object.keys(customEntries).map((ce: any, i: any) => {
                return (
                  <div
                    style={{ display: "flex", gap: "20px", marginTop: "10px" }}
                    key={i}
                  >
                    <div
                      style={{
                        fontSize: ".8rem",
                        width: "50%",
                        marginTop: "auto",
                        paddingBottom: "15px",
                      }}
                    >
                      {ce}
                    </div>
                    <InputField
                      type="number"
                      isLabel={true}
                      lable={"From"}
                      value={customEntries[ce].from}
                      onChange={(e: any) => {
                        let temp: any = { ...customEntries };
                        temp[ce].from = e.target.value;
                        setCustomEntries(temp);
                      }}
                    />
                    <InputField
                      type="number"
                      isLabel={true}
                      lable={"To"}
                      value={customEntries[ce].to}
                      onChange={(e: any) => {
                        let temp: any = { ...customEntries };
                        temp[ce].to = e.target.value;
                        setCustomEntries(temp);
                      }}
                    />
                  </div>
                );
              })}
              {/* <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
                <InputField
                  type="number"
                  isLabel={true}
                  lable={"From"}
                  value={pageFrom}
                  onChange={(e: any) => setPageFrom(e.target.value)}
                />
                <InputField
                  type="number"
                  isLabel={true}
                  lable={"To"}
                  value={pageTo}
                  onChange={(e: any) => setPageTo(e.target.value)}
                />
              </div> */}
              <p style={{ fontSize: ".9rem", marginTop: "5px", color: "grey" }}>
                Example: from 0 to 10 means value rows from 0 to value rows to
                10
              </p>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>Character Encoding</label>
          <Info
            text="Select Character Encoding"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "UTF-8 ( Unicode )", value: "utf-8" },
              { title: "UTF-16 ( Unicode )", value: "utf-16" },
              { title: "ISO-8859-1", value: "iso-8859-1" },
              { title: "ISO-8859-2", value: "iso-8859-2" },
              { title: "ISO-8859-9 ( Turkish )", value: "iso-8859-9" },
              { title: "GB2312 ( Simplified Chinese )", value: "gb2312" },
              { title: "Big5 ( Traditional Chinese )", value: "big5" },
              { title: "Shift_JIS ( Japanese )", value: "shift-jis" },
            ]}
            accessText="title"
            accessValue="value"
            value={encoding}
            onChange={(e: any) => setEncoding(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>CSV File Delimiter</label>
          <Info
            text="File Delimiter"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "Tab", value: "tab" },
              { title: "Comma ( , )", value: "comma" },
              { title: "Semi-Colon ( ; )", value: "semi-colon" },
            ]}
            accessText="title"
            accessValue="value"
            value={delimiter}
            onChange={(e: any) => setDelimiter(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>TSV File Delimiter</label>
          <Info
            text="TSV File Delimiter"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "Tab", value: "tab" },
              { title: "Comma ( , )", value: "comma" },
              { title: "Semi-Colon ( ; )", value: "semi-colon" },
            ]}
            accessText="title"
            accessValue="value"
            value={tsvDelimiter}
            onChange={(e: any) => setTsvDelimiter(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "50px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexShrink: 0,
          }}
        >
          <label>Duplicate Handling</label>
          <Info
            text="Duplicate Handling"
            containerStyle={{ height: "18px", width: "18px" }}
          />
        </div>
        <div style={{ width: "60%" }}>
          <InputField
            type="select"
            isLabel={false}
            options={[
              { title: "Skip Duplicates", value: "skip" },
              { title: "Overwrite Duplicates", value: "overwrite" },
            ]}
            accessText="title"
            accessValue="value"
            value={duplicate}
            onChange={(e: any) => setDuplicate(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductConfiguration;

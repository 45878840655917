import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import styles from "./segments.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/trash.svg";
import Modal from "../../Components/Modal";
import toast from "react-hot-toast";

const baseUrl = "https://backend.retaino.in/api/segments";

const Segments = () => {
  const navigate = useNavigate();
  const [loding, setLoading] = useState(true);
  const [segments, setSegments] = useState([]);

  const [modal, setModal] = useState(false);
  const [inFocus, setInFocus] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: `${baseUrl}?identifier=${localStorage.getItem("identifier")}`,
      method: "get",
    })
      .then((res: any) => {
        setLoading(false);
        setSegments(res.data.segments);
      })
      .catch((err: any) => {
        setLoading(false);
        setSegments([]);
      });
  };

  const handleDelete = () => {
    setModal(false);
    const promise = new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `https://backend.retaino.in/api/segments/${inFocus}`,
      })
        .then(() => {
          setInFocus("");
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        getData();
      });
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => {
          setModal(false);
          setInFocus("");
        }}
        onBtn2Click={handleDelete}
        state={modal}
      />
      <Content>
        <ContentHead
          title={"Segments"}
          showBtn1={true}
          btn1Name={"btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          onClickHandler={() => navigate("/segments/add")}
        />
        <ContentBody style={{ padding: 0 }}>
          <div className={styles.table_container}>
            <table className={styles.responsive_table}>
              <thead>
                <tr>
                  <th>S.NO.</th>
                  <th>Title</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {segments.map((segment: any, index: any) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => navigate(`/segments/${segment._id}`)}
                    >
                      <td>{index + 1}</td>
                      <td>{segment.segmentName}</td>
                      <td>
                        {segment?.createdAt
                          ? segment.createdAt
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-")
                          : "--"}
                      </td>
                      <td>
                        {segment?.updatedAt
                          ? segment.updatedAt
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("-")
                          : "--"}
                      </td>
                      <td>
                        <DeleteIcon
                          style={{
                            height: "22px",
                            width: "22px",
                          }}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setInFocus(segment._id);
                            setModal(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default Segments;

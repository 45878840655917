import { useEffect, useState } from "react";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import styles from "./contactLogs.module.css";
import axios from "axios";

const ContactLogs = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      method: "get",
      url:
        `${process.env.REACT_APP_BASE_URL}contact-logs?identifier=` +
        localStorage.getItem("identifier"),
    })
      .then((res: any) => {
        setData(res.data.data?.fromTo);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const getChangedFields = (fromObj: any, toObj: any) => {
    const changedFields = [];
    for (let key in fromObj) {
      if (fromObj[key] !== toObj[key]) {
        changedFields.push({
          field: key,
          fromValue: fromObj[key],
          toValue: toObj[key],
        });
      }
    }
    return changedFields;
  };

  return (
    <Content>
      <ContentHead title={"Contact Logs"} />
      <ContentBody style={{ paddingLeft: "50px" }}>
        <div className={styles.container}>
          {data.map((v: any, index: any) => {
            let from = v?.from;
            let to = v?.to;

            let title = v.type === "updated" ? "Updated" : "";
            switch (v.type) {
              // done : always going to be single
              case "createdByAdmin":
                title = "Created By Admin";
                break;
              // done : always going to be single
              case "createdByUser":
                title = "Created By User";
                break;
              // pending: can be multiple
              case "deleted":
                title = "Deleted";
                break;
              // pending: can be multiple
              case "updated":
                title = "Updated";
                break;
              // pending: can be multiple
              case "bulk-upload":
                title = "Bulk Upload";
                break;
              default:
                title = v.type;
                break;
            }
            let changed: any = [];

            if (title === "Updated") {
              const changedFields = getChangedFields(from[0], to[0]);
              changed = changedFields.filter(
                (v) =>
                  v.field !== "remarks" &&
                  v.field !== "createdAt" &&
                  v.field !== "modifiedAt"
              );
              if (
                changedFields.length === 1 &&
                changedFields[0].field === "remarks"
              ) {
                title = "Added Remark";
              }
            }

            return (
              <div className={styles.sub_container} key={index}>
                <div className={styles.dot}></div>
                <div className={styles.heading}>
                  <p>{title}</p>
                  {v.by && <p>By: {v.by}</p>}
                  <span>
                    {v.date.split("T")[0].split("-").reverse().join("/")}{" "}
                    {v.date.split("T")[1].split(".")[0]}
                  </span>
                  {(title === "Created By Admin" ||
                    title === "Created By User") && (
                    <div className={styles.history_table_container}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: "left" }}>Fields</th>
                            <th style={{ textAlign: "left" }}>Values</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(to[0])
                            ?.filter((v) => {
                              return (
                                v !== "identifier" &&
                                v !== "mobileCountryCode" &&
                                v !== "altMobileCountryCode" &&
                                v !== "_id" &&
                                v !== "__v" &&
                                v !== "id" &&
                                v !== "createdAt" &&
                                v !== "modifiedAt"
                              );
                            })
                            ?.map((v: any, i: any) => {
                              const obj = to[0];

                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {contact_fields[v]}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {v === "remarks"
                                      ? obj?.remarks[0]?.content || "--"
                                      : obj[v] || "--"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {title === "Deleted" && (
                    <div className={styles.history_table_container}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: "left" }}>Fields</th>
                            <th style={{ textAlign: "left" }}>Values</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(from[0])
                            ?.filter((v) => {
                              return (
                                v !== "identifier" &&
                                v !== "mobileCountryCode" &&
                                v !== "altMobileCountryCode" &&
                                v !== "_id" &&
                                v !== "__v" &&
                                v !== "id" &&
                                v !== "createdAt" &&
                                v !== "modifiedAt"
                              );
                            })
                            ?.map((v: any, i: any) => {
                              const obj = from[0];

                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {contact_fields[v]}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {v === "remarks"
                                      ? obj?.remarks[0]?.content || "--"
                                      : obj[v] || "--"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {title === "Updated" && (
                    <div className={styles.history_table_container}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: "left" }}>Fields</th>
                            <th style={{ textAlign: "left" }}>From</th>
                            <th style={{ textAlign: "left" }}>To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {from.map((fromObj: any, i: any) => {
                            const toObj = to[i];
                            const changedFields = getChangedFields(
                              fromObj,
                              toObj
                            );

                            return changed.map((field: any, j: any) => {
                              return (
                                <tr key={j}>
                                  <td>{contact_fields[field.field]}</td>
                                  <td>{field.fromValue}</td>
                                  <td>{field.toValue}</td>
                                </tr>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {title === "Added Remark" && (
                    <div>{to[0].remarks[0].content}</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </ContentBody>
    </Content>
  );
};

const contact_fields: any = {
  firstName: "First Name",
  lastName: "Last Name",
  companyName: "Company Name",
  companyWebsite: "Company Website",
  companyHQ: "Company HQ",
  mobileNumber: "Mobile Number",
  altMobileNumber: "Alt. Mobile Number",
  personalEmail: "Email",
  cooperateEmail: "Cooperate Email",
  country: "Country",
  state: "State",
  city: "City",
  address: "Address",
  pincode: "Pincode",
  remarks: "Remarks",
  status: "Status",
};

export default ContactLogs;

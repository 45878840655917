import { useState } from "react";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import styles from "../../Styles/bulkImageupload.module.css";
import { parseCSVOrTSV, parseExcel } from "../../Utils/excelFunctions";
import ContactMapFields from "../../Components/Leads/ImportLeads/contactMapFields";
import { SlCloudUpload } from "react-icons/sl";
import { ReactComponent as FileIcon } from "../../Assets/icons/file.svg";
import { ReactComponent as TrashIcon } from "../../Assets/icons/trash.svg";
import ContactPreview from "../../Components/Leads/ImportLeads/contactPreview";
import { ReactComponent as InfoIcon } from "../../Assets/icons/info.svg";
import { useNavigate } from "react-router-dom";
import SingleRow from "../../Components/Leads/singleRow";
import { GoCopy } from "react-icons/go";
import { MdOutlineCheck } from "react-icons/md";
import { uploadBulkImages } from "../../Apis/callApi";
import { toast } from "react-hot-toast";
import axios from "axios";

import { FaFileExport } from "react-icons/fa6";

type ImageData = {
  id: number;
  name: string;
  url: string;
  copied: boolean;
  selected: boolean;
};

type Status = "idle" | "uploading" | "success" | "failed";

const BulkImageUpload = () => {
  const [stage, setStage] = useState("map_fields");
  const [files, setFiles] = useState<any>(null);

  const [headers, setHeaders] = useState([]);
  const [assignedFields, setAssignedFields] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    personalEmail: "",
    cooperateEmail: "",
    mobileNumber: "",
    altMobileNumber: "",
    contactOwner: "",
    companyName: "",
    companyWebsite: "",
    companyHQ: "",
    status: "",
    reason1: "",
    reason2: "",
    message: "",
    country: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    remarks: "",
  });
  const [data, setData]: any = useState([]);
  const [ready, setReady]: any = useState([]);
  const [skipped, setSkipped]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicate, setDuplicate] = useState("skip");
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const [status, setStatus] = useState<Status>("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [images, setImages] = useState<any>();

  const handleFolderSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files;
    if (files) {
      const selectedFiles = Array.from(files).filter(
        (file: File) =>
          file.type.startsWith("image/") || file.type.startsWith("video/")
      );
      try {
        const urls = await uploadBulkImages(selectedFiles);

        let temp: any = [];
        urls.data.images.map((val: any, ind: any) => {
          temp.push({ id: ind, url: val, copied: false, selected: false });
        });
        setImages(temp);
        toast.success("Images Uploaded");
        // setLoading(false);
      } catch (error) {
        toast.error("Failed! Try again");
        // setLoading(false)
      }
    }
  };

  const uploadBulkImages = async (images: File[]) => {
    setUploadProgress(0);
    setStatus("uploading");
    try {
      const formData = new FormData();

      images.forEach((image) => {
        formData.append("files", image);
      });

      const response = await axios.post(
        `${
          process.env.REACT_APP_BASE_URL
        }uploadbulkimages/uploadbulkimages?identifier=${localStorage.getItem(
          "identifier"
        )}`,
        formData,
        {
          onUploadProgress(progressEvent) {
            const progress = progressEvent.total
              ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
              : 0;
            setUploadProgress(progress);
          },
        }
      );
      setUploadProgress(100);
      setStatus("success");
      return {
        success: true,
        data: response.data,
      };
    } catch (error: any) {
      setStatus("failed");
      setUploadProgress(0);
      return {
        success: false,
        error: error.response?.data?.message || "Something went wrong",
      };
    }
  };

  const handleShowMapMediaModal = () => {
    setShowModal(true);
  };

  const handleMapMedia = async () => {
    try {
      await axios.get(
        `https://api.shopiq.app/api/uploadbulkimages/files?identifier=${localStorage.getItem(
          "identifier"
        )}`
      );
      setShowModal(false);
      toast.success("Media mapped successfully");
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const handleCopyAll = () => {
    const selectedImages: any = images.filter((img: any) => img.selected);
    const urls = selectedImages.map((img: any) => img.url).join("\n");

    navigator.clipboard.writeText(urls).then(() => {
      setImages((prev: any) =>
        prev.map((img: any) =>
          img.selected ? { ...img, copied: true, selected: false } : img
        )
      );
      setSelectAll(false);
    });
  };
  const handleCopy = (id: number, url: string) => {
    navigator.clipboard.writeText(url).then(() => {
      setImages((prev: any) =>
        prev.map((img: any) => (img.id === id ? { ...img, copied: true } : img))
      );
    });
  };

  const toggleCheckbox = (id: number) => {
    setImages((prev: any) =>
      prev.map((img: any) =>
        img.id === id ? { ...img, selected: !img.selected } : img
      )
    );
  };

  const toggleSelectAll = () => {
    setSelectAll((prev) => !prev);
    const allSelected = images?.every((val: any) => val.selected === true);

    setImages(images.map((img: any) => ({ ...img, selected: !allSelected })));
  };

  const handleExportToCSV = () => {
    const csvData = images.map((img: any) => ({
      URL: img.url,
    }));
    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["Name,URL"]
        .concat(csvData.map((row: any) => `${row.Name},${row.URL}`))
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "image_urls.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={images ? "Uploaded Images" : "Image Bulk Upload"}
          showBtn1={true}
          btn1Name={"btn1"}
          btn1Text={"Browse Media"}
          btn1type="filled"
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              navigate("/browse-media");
            }
          }}
        />
        <ContentBody
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            paddingBlock: "5vh",
          }}
        >
          <div style={{ flex: 1 }}>
            {/* <div className={styles.heading}>Image Bulk Upload</div> */}

            {/* )} */}
            {!images ? (
              <div className={styles.configuration_container}>
                {status === "uploading" ? (
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                      display: "grid",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <h3 style={{ marginBottom: "10px" }}>
                      {status == "uploading" && uploadProgress == 100
                        ? "Optimizing Images"
                        : "Uploading Images..."}
                    </h3>
                    {status == "uploading" && uploadProgress == 100 ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#f3f3f3",
                              borderRadius: "10px",
                              overflow: "hidden",
                              border: "1px solid black",
                              padding: "2px",
                            }}
                          >
                            <div
                              className="optimizing"
                              style={{
                                width: "50%",
                                height: "20px",
                                backgroundColor: "#2563EB",
                                textAlign: "center",
                                lineHeight: "20px",
                                color: "white",
                                borderRadius: "10px",
                              }}
                            ></div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#f3f3f3",
                          borderRadius: "10px",
                          overflow: "hidden",
                          border: "1px solid black",
                        }}
                      >
                        <div
                          style={{
                            width: `${uploadProgress}%`,
                            height: "20px",
                            backgroundColor: "#2563EB",
                            textAlign: "center",
                            lineHeight: "20px",
                            color: "white",
                          }}
                        >
                          {uploadProgress}%
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={styles.import_container}>
                    <div className={styles.import_icon}>
                      <SlCloudUpload />
                    </div>

                    <div className={styles.choose_btn}>
                      <input
                        type="file"
                        //@ts-expect-error
                        webkitdirectory=""
                        multiple
                        name="fileList"
                        onChange={handleFolderSelect}
                      />
                      <p>Choose Folder</p>
                    </div>
                    <p>Folder Format: Folder , subFolder , Zip </p>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.container}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <button
                    className={styles.copyAllButton}
                    onClick={handleShowMapMediaModal}
                    disabled={status != "success"}
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid blue",
                      color: "blue",
                    }}
                  >
                    Map media
                  </button>
                  <button
                    className={styles.copyAllButton}
                    onClick={handleCopyAll}
                    disabled={images?.every((img: any) => !img.selected)}
                  >
                    <GoCopy />
                  </button>
                  <button
                    className={styles.exportButton}
                    onClick={handleExportToCSV}
                  >
                    <FaFileExport />
                  </button>
                </div>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                        />
                      </th>
                      <th>#</th>
                      <th>Image</th>
                      <th>URL</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {images.map((image: any, id: any) => (
                      <tr
                        key={id}
                        className={image.copied ? styles.copiedRow : ""}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={image.selected}
                            onChange={() => toggleCheckbox(image.id)}
                          />
                        </td>
                        <td>{image.id}</td>
                        <td>
                          <img
                            src={image.url}
                            alt={"..."}
                            className={styles.thumbnail}
                          />
                        </td>
                        <td
                          style={{
                            maxWidth: "10rem",
                            wordBreak: "break-word",
                            color: "blue",
                          }}
                        >
                          {image.url}
                        </td>
                        <td>
                          <button
                            className={`${styles.copyButton} ${
                              image.copied ? styles.doneButton : ""
                            }`}
                            onClick={() => handleCopy(image.id, image.url)}
                            disabled={image.copied}
                          >
                            {image.copied ? <MdOutlineCheck /> : <GoCopy />}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* {stage === "preview" && (
              <ContactPreview
                headers={headers}
                assignedFields={assignedFields}
                ready={ready}
                skipped={skipped}
                duplicate={duplicate}
              />
            )} */}
          </div>
        </ContentBody>
      </Content>
      {showModal && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <h3 className={styles.modalHeader}>
              Do you really want to Map media?
            </h3>
            <p>
              This will map all your images with their SKUs and can't be Undone.
            </p>
            <div className={styles.modalActions}>
              <button onClick={handleMapMedia} className={styles.modalButton}>
                Confirm
              </button>
              <button
                onClick={() => setShowModal(false)}
                className={`${styles.modalButton} ${styles.modalButtonCancel}`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BulkImageUpload;

import React, { useState, useEffect } from "react";
import axios from "axios";
import withRouter from "../../Components/withRouter";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as CloseIcon } from "../../Assets/icons/cross.svg";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import Modal from "../../Components/Modal";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import styles from "./users.module.css";
import ContentBody from "../../Components/Content/ContentBody";
import SingleRowUser from "./singleRowUser";
import InputField from "../../Components/InputField";
import ToggleSwitch from "../../Components/ToggleSwitch";

interface UserProps {
  router: any;
}

const Users: React.FC<UserProps> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [secondaryView, setSecondaryView] = useState(false);
  const [inView, setInView]: any = useState({});
  const [tab, setTab] = useState("overview");

  const [contacts, setContacts]: any = useState([]);
  const [updatedContacts, setUpdatedContacts]: any = useState([]);

  const [modal, setModal] = useState(false);

  const [subAdmins, setSubAdmins] = useState([]);

  const [contactOwnerDropdown, setContactOwnerDropdown] = useState(false);
  const [createDateDropdown, setCreateDateDropdown] = useState(false);
  const [lastActivityDropdown, setLastActivityDropdown] = useState(false);
  const [leadStatusDropdown, setLeadStatusDropdown] = useState(false);
  const [actionsDropdown, setActionsDropdown] = useState(false);

  const [updateMode, setUpdateMode] = useState(false);

  const [remark, setRemark] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [disableForAction, setDisableForAction] = useState(false);

  const [users, setUsers] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const [block, setBlock] = useState(false);

  const [segmentDropdown, setSegmentDropdown] = useState(false);

  const [filter, setFilter] = useState({
    segment: "",
  });

  const [segments, setSegments] = useState([]);

  useEffect(() => {
    getSegments();
  }, []);

  useEffect(() => {
    handlePage();
  }, [location.search, filter]);

  const handlePage = () => {
    getData();
  };

  const getSegments = () => {
    axios({
      url: `https://backend.retaino.in/api/segments/?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res: any) => {
        setSegments(res.data.segments);
      })
      .catch((err: any) => {
        setSegments([]);
      });
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    let segment_id = filter?.segment || "";

    let query = "";

    if (segment_id) {
      query = `&segmentId=${segment_id}`;
    }

    axios({
      url: `https://backend.retaino.in/api/users/filter-user?identifier=${localStorage.getItem(
        "identifier"
      )}${query}`,
      method: "GET",
    })
      .then((res) => {
        setUsers(res.data.allowedUsers);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setUsers([]);
      });
  };

  const handleRowClick = (val: any) => {
    router.navigate(`/users/${val._id}`);
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={"Users"}
          showBtn1={false}
          btn1Name={updateMode ? "update_btn1" : "btn1"}
          btn1Text={"Add New"}
          isIcon1={true}
          iconType1={faPlus}
          btn1type="filled"
          isMoreSettings={false}
          moreSettingsList={[
            { icon: "import", title: "Import", name: "import" },
            { icon: "export", title: "Export", name: "export" },
            // { icon: "logs", title: "Logs", name: "logs" },
          ]}
          handleMoreClick={(name: any) => {
            if (name === "import") {
              navigate("/contacts/import-file");
            } else if (name === "export") {
              // exportExcel();
            } else if (name === "logs") {
              navigate("/contacts/logs");
            }
          }}
          onClickHandler={(e: any) => {
            if (e.target.name === "btn1") {
              navigate("/users/add");
            }
          }}
          customElement={
            <div className={styles.search_section}>
              <FontAwesomeIcon icon={faSearch} />
              <input
                placeholder="Search name, phone, email ..."
                value={searchTerm}
                // onChange={handleChange}
              />
            </div>
          }
        />
        <ContentBody style={{ padding: 0 }}>
          {(segmentDropdown ||
            createDateDropdown ||
            lastActivityDropdown ||
            leadStatusDropdown ||
            actionsDropdown) && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: "transparent",
                zIndex: 100,
              }}
              onClick={() => {
                setSegmentDropdown(false);
                setCreateDateDropdown(false);
                setLastActivityDropdown(false);
                setLeadStatusDropdown(false);
                setActionsDropdown(false);
              }}
            ></div>
          )}

          {/* Table Head and Table Body with secondary view */}
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <FilterContainer
              filter={filter}
              setFilter={setFilter}
              segmentDropdown={segmentDropdown}
              setSegmentDropdown={setSegmentDropdown}
              segments={segments}
            />
            {secondaryView ? (
              <div className={styles.secondary_view}>
                <div className={styles.secondary_list_view}>
                  {users.map((val: any, index: any) => {
                    return (
                      <div
                        key={index}
                        className={styles.secondary_short_item}
                        style={
                          val._id === inView?._id
                            ? {
                                background: "var(--brand-color)",
                                color: "white",
                              }
                            : {}
                        }
                        onClick={() => setInView(val)}
                      >
                        {/* <input
                          type="checkbox"
                          // checked={val.actions.select}
                          onClick={() => {
                            // changeSelect(val._id);
                          }}
                        /> */}
                        <div>
                          <p>
                            {val?.firstName || val?.lastName
                              ? [val?.firstName, val?.lastName].join(" ")
                              : "--"}
                          </p>
                          <span>{val?.email}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.secondary_detail_view}>
                  <div>
                    <div className={styles.sdv_head}>
                      <p style={{ textTransform: "capitalize" }}>
                        {inView?.firstName ||
                        inView?.middleName ||
                        inView?.lastName
                          ? [
                              inView?.firstName?.trim() || "",
                              inView?.middleName?.trim() || "",
                              inView?.lastName?.trim() || "",
                            ]?.join(" ")
                          : "Undefined"}
                      </p>
                      <div className={styles.sdv_buttons}>
                        <CloseIcon
                          className={styles.sdv_close_btn}
                          onClick={() => {
                            setSecondaryView(false);
                            setInView(null);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.secondary_content}>
                    <div>
                      <span>First Name</span>
                      <p>{inView?.firstName || "--"}</p>
                    </div>

                    <div>
                      <span>Middle Name</span>
                      <p>{inView?.middleName || "--"}</p>
                    </div>
                    <div>
                      <span>Last Name</span>
                      <p>{inView?.lastName || "--"}</p>
                    </div>

                    <div>
                      <span>Mobile Number</span>
                      <p>{inView?.mobile.number}</p>
                    </div>

                    <div>
                      <span>Email</span>
                      <p>{inView?.email || "--"}</p>
                    </div>

                    <div>
                      <span>Billing Address</span>

                      <p>
                        {/* @ts-ignore */}
                        {inView?.billingAddress[0]?.name},{" "}
                        {
                          // @ts-ignore
                          inView?.billingAddress[0]?.addLineOne
                        }{" "}
                        {
                          // @ts-ignore
                          inView?.billingAddress[0]?.addLineTwo
                        }{" "}
                        {
                          //@ts-ignore
                          inView?.billingAddress[0]?.landmark
                        }{" "}
                        {/* @ts-ignore */}
                        {inView?.billingAddress[0]?.city}{" "}
                        {
                          // @ts-ignore
                          inView?.billingAddress[0]?.state
                        }{" "}
                        - {/* @ts-ignore */}
                        {inView?.billingAddress[0]?.pincode}
                      </p>
                    </div>
                    <div>
                      <span>Shipping Address</span>

                      <p>
                        {/* @ts-ignore */}
                        {inView?.shippingAddress[0]?.name},{" "}
                        {
                          // @ts-ignore
                          inView?.shippingAddress[0]?.addLineOne
                        }
                        ,{" "}
                        {
                          // @ts-ignore
                          inView?.shippingAddress[0]?.addLineTwo
                        }{" "}
                        ,
                        {
                          //@ts-ignore
                          inView?.shippingAddress[0]?.landmark
                        }{" "}
                        ,{/* @ts-ignore */}
                        {inView?.shippingAddress[0]?.city} ,
                        {
                          // @ts-ignore
                          inView?.shippingAddress[0]?.state
                        }{" "}
                        , - {/* @ts-ignore */}
                        {inView?.shippingAddress[0]?.pincode},
                      </p>
                    </div>
                  </div>
                  <div className={styles.secondary_content}>
                    <div style={{ gridColumn: "1/4" }}>
                      <h3 style={{ fontWeight: "500" }}>Settings</h3>
                    </div>
                    <div
                      style={{
                        gridColumn: "1/4",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                      }}
                    >
                      <div
                        style={{
                          height: "45px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid lightgrey",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <p>Block User</p>
                        <ToggleSwitch
                          value={block}
                          onClick={() => setBlock((prev) => !prev)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        gridColumn: "1/4",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                      }}
                    >
                      <InputField
                        isLabel={true}
                        lable={"Payment Method Allowed"}
                        type="select"
                        options={[
                          { title: "All", value: "all" },
                          { title: "Online", value: "online" },
                          { title: "COD", value: "cod" },
                        ]}
                        accessText="title"
                        accessValue="value"
                        value=""
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.table_container}>
                <table className={styles.responsive_table}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>DOB</th>

                      <th>Last Activity DATE</th>

                      <th>Create Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((val: any, index: any) => {
                      return (
                        <SingleRowUser
                          data={val}
                          key={index}
                          // changeSelect={changeSelect}
                          disableForAction={disableForAction}
                          updatedContacts={updatedContacts}
                          setUpdatedContacts={setUpdatedContacts}
                          subAdmins={subAdmins}
                          onRowClick={() => {
                            setInView(val);
                            setSecondaryView(true);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

const FilterContainer = ({
  filter = {},
  setFilter,
  segments = [],
  segmentDropdown,
  setSegmentDropdown,
}: any) => {
  const [navSegmentSearch, setNavSegmentSearch] = useState("");

  return (
    <div className={styles.filter_container}>
      <div
        className={styles.drop_down_container}
        style={{
          opacity: "1",
          cursor: "pointer",
          pointerEvents: "all",
        }}
      >
        <div
          className={styles.drop_down_content}
          onClick={() => setSegmentDropdown(true)}
          style={
            filter?.segment
              ? {
                  padding: "7px 10px",
                  borderRadius: "5px",
                  background: "#d9e7f8",
                }
              : {}
          }
        >
          <p>Segments</p>
          <DownIcon />
          {filter?.segment && (
            <FontAwesomeIcon
              icon={faClose}
              color={"grey"}
              style={{ marginLeft: "5px" }}
              onClick={(e: any) => {
                e.stopPropagation();
                setFilter((prev: any) => ({
                  ...prev,
                  segment: "",
                }));
              }}
            />
          )}
        </div>
        {segmentDropdown && (
          <div className={styles.drop_down_section}>
            <div style={{ padding: "10px" }}>
              <div className={styles.contact_owner_search}>
                <FontAwesomeIcon icon={faSearch} />
                <input
                  type="text"
                  placeholder="Search contact owner"
                  value={navSegmentSearch}
                  onChange={(e: any) => setNavSegmentSearch(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                padding: "0",
                maxHeight: "250px",
                overflowY: "auto",
              }}
            >
              {segments
                .filter((v: any) => {
                  if (!navSegmentSearch) return true;

                  return v.segmentName
                    .toLowerCase()
                    .trim()
                    .includes(navSegmentSearch.toLowerCase().trim());
                })
                .map((segment: any, index: any) => {
                  return (
                    <div
                      className={styles.contact_owner_single_list}
                      key={index}
                      onClick={() => {
                        setFilter((prev: any) => ({
                          ...prev,
                          segment: segment._id,
                        }));
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={filter.segment === segment._id}
                      />
                      <div>
                        <p
                          style={{ fontSize: ".9rem" }}
                        >{`${segment?.segmentName}`}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(Users);

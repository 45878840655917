import axios from "axios";

export const checkForImg = (ext: any) => {
  if (!ext) return true;

  if (
    ext.toLowerCase().endsWith("jpg") ||
    ext.toLowerCase().endsWith("jpeg") ||
    ext.toLowerCase().endsWith("png") ||
    ext.toLowerCase().endsWith("gif") ||
    ext.toLowerCase().endsWith("webp") ||
    ext.toLowerCase().endsWith("heic") ||
    ext.toLowerCase().endsWith("svg")
  ) {
    return true;
  } else {
    return false;
  }
};

const UploadImage = ({ file, name }: { file: any; name?: any }) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("files", file);
    formData.append("path", folder_name());

    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }uploadBulkImages/uploadBulkImages?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "post",
      data: formData,
    })
      .then((res: any) => {
        resolve(res.data.images[0]);
      })
      .catch((err: any) => {
        reject(false);
      });
  });
};

export const folder_name = () => {
  return localStorage.getItem("identifier")?.includes("bion")
    ? "bion/"
    : localStorage.getItem("identifier")?.includes("whitetales")
    ? "whitetales/"
    : localStorage.getItem("identifier")?.split(".").join("") + "/";
};

export default UploadImage;

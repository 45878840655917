import styles from "./contactMapFields.module.css";
import { ReactComponent as InfoIcon } from "../../../Assets/icons/info.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faClose } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";

type Props = {
  files: any[];
  assignedFields: any;
  setAssignedFields: any;
  headers: any;
};

const ContactMapFields = ({
  files,
  assignedFields,
  setAssignedFields,
  headers,
}: Props) => {
  return (
    <div className={styles.container}>
      <p className={styles.selected_files}>
        Selected Files : {files.map((f) => f.name).join(", ")}
      </p>
      <div className={styles.info_text_with_icon}>
        <InfoIcon />
        <p>
          The best match to each field on the selected file have been auto
          selected.
        </p>
      </div>
      <div className={styles.contact_table}>
        <h4>Contact Details</h4>
        <table>
          <thead>
            <tr>
              <th>ShopIQ FIELDS</th>
              <th>IMPORTED FIELDS HEADERS</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => {
              return (
                <SingleRow
                  key={index}
                  field={field}
                  assignedFields={assignedFields}
                  setAssignedFields={setAssignedFields}
                  headers={headers}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SingleRow = ({
  field,
  assignedFields,
  setAssignedFields,
  headers,
}: any) => {
  const [drop, setDrop] = useState(false);
  const inputRef: any = useRef<HTMLDivElement>(null);
  const dropRef: any = useRef<HTMLDivElement>(null);
  const [dropStyle, setDropStyle] = useState({});

  const drop_values = headers.filter((v: any) => {
    return !Object.values(assignedFields).includes(v);
  });

  const handleDropOpen = () => {
    setDrop(true);
  };

  useEffect(() => {
    if (drop && inputRef.current && dropRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const dropRect = dropRef.current.getBoundingClientRect();

      let top = inputRect.bottom;
      let left = inputRect.right;

      if (inputRect.bottom + dropRect.height > window.innerHeight) {
        // top = inputRect.top - dropRect.height;
        setDropStyle({
          bottom: `110%`,
        });
      } else {
        setDropStyle({
          top: `110%`,
        });
      }

      if (inputRect.right + dropRect.width > window.innerWidth) {
        left = inputRect.left - dropRect.width;
      }
    }
  }, [drop]); // This effect runs whenever `drop` changes

  return (
    <>
      <tr onClick={() => handleDropOpen()} ref={inputRef}>
        <td>{field.title}</td>
        <td>
          <div className={styles.drop_container}>
            <p>{assignedFields[field.value]}</p>
            {drop && (
              <div className={styles.drop} style={dropStyle} ref={dropRef}>
                {drop_values.map((dv: any, index: any) => {
                  return (
                    <div
                      key={index}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setAssignedFields((prev: any) => ({
                          ...prev,
                          [field.value]: dv,
                        }));
                        setDrop(false);
                      }}
                    >
                      {dv}
                    </div>
                  );
                })}
              </div>
            )}
            <div className={styles.actions}>
              {assignedFields[field.value] && (
                <FontAwesomeIcon
                  icon={faClose}
                  className={styles.closeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAssignedFields((prev: any) => ({
                      ...prev,
                      [field.value]: "",
                    }));
                  }}
                />
              )}
              {assignedFields[field.value] && <div></div>}
              <FontAwesomeIcon icon={faAngleDown} className={styles.dropIcon} />
            </div>
          </div>
        </td>
      </tr>
      {drop && (
        <div
          className={styles.drop_cover}
          onClick={(e) => {
            e.stopPropagation();
            setDrop(false);
          }}
        ></div>
      )}
    </>
  );
};

const fields = [
  { title: "First Name", value: "firstName" },
  { title: "Middle Name", value: "middleName" },
  { title: "Last Name", value: "lastName" },
  { title: "Email", value: "personalEmail" },
  { title: "Cooperate Email", value: "cooperateEmail" },
  { title: "Phone Number", value: "mobileNumber" },
  { title: "Alt. Phone Number", value: "altMobileNumber" },
  { title: "Contact Owner", value: "contactOwner" },
  { title: "Company Name", value: "companyName" },
  { title: "Company Website", value: "companyWebsite" },
  { title: "Company HQ", value: "companyHQ" },
  { title: "Lead Status", value: "status" },
  { title: "Reason 1", value: "reason1" },
  { title: "Reason 2", value: "reason2" },
  { title: "Message", value: "message" },
  { title: "Country", value: "country" },
  { title: "State", value: "state" },
  { title: "City", value: "city" },
  { title: "Address", value: "address" },
  { title: "Pincode", value: "pincode" },
  { title: "Remarks", value: "remarks" },
];

export default ContactMapFields;

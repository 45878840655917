import React, { useState, useEffect } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import withRouter from "../../Components/withRouter";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../Components/Table/Pagination";
import { jobs_table_key } from "../../Utils/Data";
import { useAdminContext } from "../../Store/adminContext";

interface JobsProps {
  router: any;
}

const Jobs: React.FC<JobsProps> = ({ router }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [jobs, setJobs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [inFocus, setInFocus] = useState<string>("");
  const [modal, setModal] = useState<boolean>(false);
  const [total, setTotal] = useState(0);

  const { admin }: any = useAdminContext();

  useEffect(() => {
    handlePage();
  }, [location.search]);

  const handlePage = () => {
    let search = new URLSearchParams(location.search);
    getData();
  };

  const getData = () => {
    setLoading(true);
    let search = new URLSearchParams(location.search);

    axios({
      method: "get",
      url: `${
        process.env.REACT_APP_BASE_URL
      }jobs?identifier=${localStorage.getItem("identifier")}&page=${
        Number(search.get("page")) || 1
      }&limit=${Number(search.get("limit")) || 15}`,
    })
      .then((res) => {
        setTotal(res?.data?.total);
        setJobs(res.data.jobs);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleAction = (val: string, id: string) => {
    if (val === "delete") {
      setModal(true);
      setInFocus(id);
    }

    if (val === "archive" || val === "unarchive") {
      // call archive api
      const status = val === "archive" ? "draft" : "live";

      const promise = new Promise((resolve, reject) => {
        axios({
          method: "patch",
          url: `${process.env.REACT_APP_BASE_URL}jobs/${id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
          data: { status: status },
        })
          .then((res) => {
            resolve(true);
          })
          .catch(() => {
            reject();
          });
      });

      toast
        .promise(
          promise,
          {
            loading: `${val === "archive" ? "Archiving" : "Unarchiving"}...`,
            success: `${
              val === "archive" ? "Archived" : "Unarchived"
            } Successfully.`,
            error: "Error when archiving the entry.",
          },
          { success: { duration: 2000 }, error: { duration: 2000 } }
        )
        .then(() => {
          getData();
        });
    }
  };

  const deleteItem = () => {
    setModal(false);
    const promise = new Promise<void>((resolve, reject) => {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_BASE_URL}jobs/${inFocus}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res) => {
          setInFocus("");
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });

    toast
      .promise(
        promise,
        {
          loading: "Deleting...",
          success: "Deleted Successfully.",
          error: "Error when deleting the entry.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      )
      .then(() => {
        getData();
      });
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        onBtn1Click={() => setModal(false)}
        onBtn2Click={deleteItem}
        state={modal}
      />
      <WithTableView
        heading="Jobs"
        head={getHead(admin)!}
        body={jobs}
        body_keys={getKeys(admin)}
        show_btn_1={admin.role === "subadmin" ? admin.job_access.write : true}
        isIcon={true}
        iconType="add"
        btn1Text=" New Job"
        btn1Type="filled"
        onClickHandler={() => {
          router.navigate("/jobs/create_job");
        }}
        onRowClick={(val: any) => {
          router.navigate(`/jobs/update_job/${val._id}`);
        }}
        onActionClick={handleAction}
        loading={loading}
        customElement={
          <Pagination
            pageNo={
              Number(new URLSearchParams(location.search).get("page")) || 1
            }
            changePage={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("page", String(val));

              navigate(`/jobs?${String(search)}`);
            }}
            total={total}
            showDrop={true}
            perPage={
              Number(new URLSearchParams(location.search).get("limit")) || 15
            }
            changeDrop={(val: number) => {
              let search = new URLSearchParams(location.search);

              search.set("limit", String(val));

              navigate(`/jobs?${String(search)}`);
            }}
          />
        }
      />
    </>
  );
};

const getHead = (admin: any) => {
  const baseHead = [
    { title: "S.No.", value: "s.no" },
    { title: "Title", value: "title" },
    { title: "Position", value: "title" },
    { title: "Department", value: "department" },
    { title: "Status", value: "status" },
    // { title: "Action", value: "action" },
  ];

  if (admin.role === "subadmin") {
    if (admin.job_access.delete) {
      return [...baseHead, { title: "Action", value: "action" }];
    }

    if (admin.job_access.write) {
      return [...baseHead, { title: "Action", value: "action" }];
    }

    if (admin.job_access.read) {
      return baseHead;
    }
  } else {
    return [...baseHead, { title: "Action", value: "action" }];
  }
};

const getKeys = (admin: any) => {
  const baseKeys = ["title", "position", "department", "status"];

  if (admin.role === "subadmin") {
    if (admin.job_access.delete) {
      return [...baseKeys, "action:delete,archive"];
    }

    if (admin.job_access.write) {
      return [...baseKeys, "action:archive"];
    }

    if (admin.job_access.read) {
      return baseKeys;
    }
  } else {
    return [...baseKeys, "action:delete,archive"];
  }
};

export default withRouter(Jobs);

import React, { useEffect, useState } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { sub_category_table_head } from "../../Utils/Data";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/Modal";
import toast from "react-hot-toast";
import { useAdminContext } from "../../Store/adminContext";

const base_url = process.env.REACT_APP_BASE_URL;
// const base_url = "http://localhost:8080";

let inFocus = "";

const SubCategories = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);

  const { admin }: any = useAdminContext();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: `${base_url}productsubcategory/?identifier=${localStorage.getItem(
        "identifier"
      )}&limit=10000`,
      method: "get",
    })
      .then((res: any) => {
        setData(res.data.productSubCategories);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        setData([]);
      });
  };

  const handleAction = () => {
    setModal(false);

    const promise = new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${base_url}productsubcategory/${inFocus}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
        },
      })
        .then((res) => {
          inFocus = "";
          resolve(true);
          getData();
        })
        .catch(() => {
          reject();
        });
    });

    toast.promise(
      promise,
      {
        loading: "Deleting...",
        success: "Deleted Successfully.",
        error: "Error when deleting the entry.",
      },
      { success: { duration: 2000 }, error: { duration: 2000 } }
    );
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          inFocus = "";
          setModal(false);
        }}
        onBtn2Click={handleAction}
      />
      <WithTableView
        loading={loading}
        heading={"Sub Categories"}
        head={categoryHead(admin)}
        body={data}
        body_keys={categoryKeys(admin)}
        show_btn_1={isUserAllowedToEdit(admin)}
        btn1Text={"Add Sub-Category"}
        btn1Type="filled"
        onRowClick={(val: any) => {
          navigate("/sub-categories/update/" + val._id);
        }}
        onClickHandler={() => {
          navigate("/sub-categories/add");
        }}
        onActionClick={(name: any, id: any) => {
          inFocus = id;
          setModal(true);
        }}
      />
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

const categoryHead = (admin: any) => {
  const base = [
    { title: "S.No.", value: "s.no" },
    { title: "Title", value: "title" },
    // { title: "Action", value: "action" },
  ];

  if (admin.role !== "subadmin")
    return [...base, { title: "Action", value: "action" }];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base, { title: "Action", value: "action" }];
    }
    if (admin.catalog_access.write) {
      return [...base];
    }
  }

  return base;
};

const categoryKeys = (admin: any) => {
  const base_keys = ["title"];

  if (admin.role !== "subadmin") return [...base_keys, "action:delete"];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_keys, "action:delete"];
    }
    if (admin.catalog_access.write) {
      return [...base_keys];
    }
  }

  return [...base_keys];
};

export default SubCategories;

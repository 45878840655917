import React, { useEffect, useState } from "react";
import styles from "./label.module.css";

const Label = (props: any) => {
  const [height, setheight] = useState<number>(0);
  useEffect(() => {
    const cont = document.getElementById("main_cont");
    if (cont) {
      const heightInPixels = cont.clientHeight;
      setheight((heightInPixels / 87) * 22);
    }
  }, [props]);

  return (
    <div
      className={styles.outer_shipping_label}
      ref={props.refr}
      id="main_cont"
    >
      <style>{`@page { size:120mm ${height}mm;}`}</style>

      {props.labelData.companyAddress ===
        "undefined,undefined,undefined,undefined" ||
      props.options.length === 0 ? (
        <h5>Loading...</h5>
      ) : (
        <div className={styles.shippingLabel} ref={props.refr}>
          <div className={styles.header}>
            <span style={{ borderRight: "1px solid black" }}>
              <img src={props?.labelData?.logoUrl} className={styles.logo} />
              <div className={styles.serviceType}>
                <label>Service Type:</label>
                <span>{props.labelData?.serviceType}</span>
              </div>
            </span>
            <div className={styles.shippedBy}>
              <h3>Shipped by (if undelivered return to):</h3>
              <p>{props.labelData?.companyName}</p>
              <p>{props.labelData?.companyAddress}</p>
              <p>{props.labelData?.companyStateZip}</p>
              <p>{props.labelData?.companyCountry}</p>
            </div>
          </div>
          <div className={styles.second_head}>
            <div className={styles.shipTo}>
              <h3>Ship to:</h3>
              <p>{props.labelData?.customerName}</p>
              <p>{props.labelData?.customerAddress}</p>
              <p>{props.labelData?.customerStateZip}</p>
              <p>{props.labelData?.customerCountry}</p>
            </div>
            <div className={styles.paymentMode}>
              <h3>Payment Mode:</h3>
              <span>{props.labelData?.paymentMode}</span>
            </div>
          </div>

          <div className={styles.packageDetails}>
            <h3>Package Details:</h3>
            <span>
              <div>
                <label>Order Date:</label>
                <span>{props.labelData?.orderDate}</span>
              </div>
              <div>
                <label>Ship Date:</label>
                <span>{props.labelData?.shipDate}</span>
              </div>
              <div>
                <label>Weight:</label>
                <span>{props.labelData?.weight}</span>
              </div>
              <div>
                <label>SO Number:</label>
                <span>{props.labelData?.soNumber}</span>
              </div>

              <div>
                <label>Tracking ID:</label>
                <span>{props.labelData?.trackingId}</span>
              </div>
            </span>
            <div>
              <label>Carrier/Delivery Partner Name:</label>
              <span>{props.labelData?.carrierName}</span>
            </div>
          </div>
          <div className={styles.third_header}>
            <div className={styles.instructions}>
              <h3>Delivery Instructions</h3>
              <ul className={styles.selectedOptions}>
                {props?.selectedOptions?.map((option: any) => (
                  <li key={option.value} className={styles.selectedOption}>
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.company_logo}>
              <p>Powered By </p>
              <img src="/logo.png" alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Label;

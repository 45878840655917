import React from "react";
import ContentHead from "../../../Components/Content/ContentHead";
import ContentBody from "../../../Components/Content/ContentBody";
import Content from "../../../Components/Content/Content";
import styles from "../../../Styles/discounts.module.css";
import InputField from "../../../Components/InputField";
import ToggleSwitch from "../../../Components/ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import withRouter from "../../../Components/withRouter";
import { ReactComponent as Down } from "../../../Assets/icons/down.svg";
import toast from "react-hot-toast";
import moment from "moment";
import { calculatePrice } from "../../../Utils/caculatePrice";

class AddServiceOrder extends React.Component<any, any> {
  state = {
    userToggle: false,
    uniqueServices: [],
    services: [],
    selectedService: "",
    discounts: [],
    selectedLocation: "",
    selectedSubLocation: "",
    selectedCategory: "",
    quantity: "",
    date: "",
    startTime: "",
    endTime: "",
    selectedDiscount: "",
    payment: "",
    email: "",
    otpSent: false,
    key: "",
    setVToken: "",
    otp: "",
    userId: "",
    verified: false,
    loading: false,
    selectedSubCategory: "",
    TImings: [...JSON.parse(JSON.stringify(tim))],
    products: [],
    selectedProduct: "",
    endTimings: [],
    firstName: "",
    lastName: "",
    mobileNumber: "",
    isUserAccountVerified: false,
    billingAddress: {
      addLineOne: "",
      addLineTwo: "",
      city: "",
      state: "",
      pincode: "",
    },
    paymentStatus: "",
    paymentDetails: [],
    selectedServiceId: "",
    basePrice: 0,
    gst: 0,
    discount: "",
    discountType: "",
    discountValue: 0,
    user: null,
    allUsers: [],
  };

  componentDidMount(): void {
    if (localStorage.getItem("service") === "true") {
      this.getServices();
    }

    this.getKey();

    if (localStorage.getItem("ecommerce") === "true") {
      this.getProducts();
    }
  }

  getProducts = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}products/?identifier=` +
        localStorage.getItem("identifier") +
        "&page=1&limit=1000",
      method: "GET",
    })
      .then((res: any) => {
        this.setState({ products: res.data.products });
      })
      .catch((err: any) => {});
  };

  getKey = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}users/verifyuser`,
      method: "POST",
      data: {
        identifier: `${localStorage.getItem("identifier")}`,
        consent: true,
      },
    })
      .then((res) => {
        this.setState({ key: res.data.key });
      })
      .catch((error) => {});
  };

  getServices = () => {
    axios({
      url: `${process.env.REACT_APP_BASE_URL}service/`,
      method: "GET",
    })
      .then((res: any) => {
        this.setState({ services: [...res.data.services] });

        // get unique services title from the services array
        let uniqueServices = [
          ...res.data.services.map((item: any) => item.title),
        ];

        // @ts-ignore
        uniqueServices = [...new Set(uniqueServices)];

        this.setState({ uniqueServices: [...uniqueServices] });

        this.getAllLocations();
      })
      .catch((error: any) => {});
  };

  getAllLocations = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}service/distinctLocations/` +
        localStorage.getItem("identifier"),
      method: "GET",
    })
      .then((res: any) => {
        let data = res.data.data.filter((val: any) => val._id);

        this.getAllUsers();
      })
      .catch((err: any) => {});
  };

  getAllUsers = () => {
    axios({
      url: "https://backend.retaino.in/api/users/" + this.state.key,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {
        this.setState({ allUsers: [...res.data.allowedUsers] });
        this.getAllDiscounts();
      })
      .catch((err: any) => {
        this.getAllDiscounts();
      });
  };

  getAllDiscounts = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}discounts?identifier=` +
        localStorage.getItem("identifier"),
      method: "GET",
    })
      .then((res: any) => {
        this.setState({ discounts: res.data.discounts });
      })
      .catch((err: any) => {});
  };

  sendOtp = () => {
    axios({
      url: "https://backend.retaino.in/api/users/verifyuser",
      method: "POST",
      data: {
        email: this.state.email,
        key: this.state.key,
        consent: true,
      },
    })
      .then((res) => {
        this.setState({ setVToken: res.data.verificationToken });
      })
      .catch((err) => {});
  };

  verifyOtp = () => {
    axios({
      url:
        "https://backend.retaino.in/api/users/verifyotp/" +
        this.state.setVToken,
      method: "POST",
      data: {
        otp: this.state.otp,
      },
    })
      .then((res: any) => {
        this.setState({ userId: res.data.userData.userId, verified: true });
      })
      .catch((err: any) => {});
  };

  // get all services (done)
  // get all locations (done)
  // get all categories (done)
  // get all customers
  // get all discounts (done)

  handleClick = (e: any) => {
    if (e.target.name === "btn2") {
      this.props.router.navigate("/orders");
    }
    if (e.target.name === "btn1") {
      this.createOrder();
    }
  };

  handleAddDate = (e: any) => {
    this.setState({ loading: true, date: e.target.value });

    // to make call we need location, subLocation, category, subCategory, and date
    axios({
      url: `${
        process.env.REACT_APP_BASE_URL
      }service/unavailableTimeSlot?identifier=${localStorage.getItem(
        "identifier"
      )}&location=${this.state.selectedLocation}&subLocation=${
        this.state.selectedSubLocation
      }&category=${this.state.selectedCategory}&subCategory=${
        this.state.selectedSubCategory
      }&date=${e.target.value}T00:00:00.000Z`,
      method: "GET",
    })
      .then((res: any) => {
        this.setState({ selectedServiceId: res.data.serviceID });

        if (
          // res.data?.message === "All slots are available" ||
          res.data?.message === "Unavailable time slots: "
        ) {
          let ts = res.data?.unavailableTimeSlots;

          let startTime = ts
            .map((val: any) => [
              val.startTime.split("T")[1],
              val.endTime.split("T")[1],
            ])
            .map((val: any) => [
              val[0].split(":").slice(0, 2).join(":"),
              val[1].split(":").slice(0, 2).join(":"),
            ]);

          let final = [...JSON.parse(JSON.stringify(tim))];

          let t = startTime.map((val: any) => {
            let si = val[0];
            let ei = val[1];

            let indexOfsi = this.state.TImings.map((val) => val.time).indexOf(
              si
            );
            let indexOfei =
              this.state.TImings.map((val) => val.time).indexOf(ei) - 1;

            final = final.map((val, index) => {
              let temp = val;
              if (index >= indexOfsi && index <= indexOfei) {
                temp.enabled = false;
              }

              return temp;
            });
          });

          this.setState({ loading: false, TImings: [...final] });

          // let start = this.state.TImings.map((val) => {
          //   if (startTime.map((val1: any) => val1[0]).includes(val.time)) {
          //     return { ...val, enabled: false };
          //   }
          //   return val;
          // });

          // let end = start.map((val) => {
          //   if (startTime.map((val1: any) => val1[1]).includes(val.time)) {
          //     return { ...val, enabled: true };
          //   }
          //   return val;
          // });
        } else {
          alert("Sorry! No service found");
          this.setState({ loading: false });
        }
      })
      .catch((error: any) => {});
  };

  handleStartTime = (e: any) => {
    this.setState({ endTimings: [] });
    this.setState({ startTime: e.target.value });

    let selectedIndex = this.state.TImings.map((val) => val.time).indexOf(
      e.target.value
    );

    let result = [];

    for (let i = selectedIndex + 1; i < this.state.TImings.length; i++) {
      if (this.state.TImings[i].enabled) {
        result.push(this.state.TImings[i]);
      } else {
        break;
      }
    }

    this.setState({ endTimings: [...result] });
  };

  handleUserCreate = () => {
    axios({
      url: "https://backend.retaino.in/api/users/" + this.state.userId,
      method: "PATCH",
      data: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        mobile: { number: this.state.mobileNumber },
        billingAddress: [
          {
            ...this.state.billingAddress,
            name: this.state.firstName + " " + this.state.lastName,
          },
        ],
      },
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      // },
    })
      .then((res: any) => {
        this.setState({ isUserAccountVerified: true });
        toast.success("User Created Successfully.");
      })
      .catch((err: any) => {
        toast.error("Something went wrong.");
      });
  };

  addPaymentDetails = () => {
    this.setState({
      paymentDetails: [
        ...this.state.paymentDetails,
        {
          paidAmount: 0,
          modeOfPayment: "",
          dateOfPayment: new Date(),
          status: true,
        },
      ],
    });
  };

  selectEndDate = (e: any) => {
    this.setState({ endTime: e.target.value });

    let startTime = moment(this.state.startTime, "HH:mm");

    let endTime = moment(e.target.value, "HH:mm");

    const day = moment(this.state.date, "DD-MM-YYYY").format("dddd");

    let selected_service = this.state.services.filter(
      (val: any) => val._id === this.state.selectedServiceId
    )[0];

    const price = calculatePrice(startTime, endTime, selected_service, day);

    this.setState({
      basePrice: price.basePrice,
      gst: price.gst,
    });
  };

  calculateTotal = () => {
    return Number(
      (
        this.state.basePrice +
        (this.state.selectedDiscount
          ? -1 *
            Number(
              this.state.discountType === "percentage"
                ? (
                    (this.state.basePrice * this.state.discountValue) /
                    100
                  ).toFixed(2)
                : this.state.discountValue.toFixed(2)
            )
          : 0) +
        this.state.gst
      ).toFixed(2)
    );
  };

  selectDiscount = (e: any) => {
    let temp: any = this.state.discounts.filter(
      (val: any) => val._id === e.target.value
    )[0];

    if (temp.bulkDiscount) {
      this.setState({
        discountType: temp.bulkDiscountDetails[0].discountType,
        discountValue: temp.bulkDiscountDetails[0].discountValue,
      });
    } else {
      if (temp.value.number && temp.value.typeName) {
        this.setState({
          discountType: temp.value.typeName,
          discountValue: temp.value.number,
        });
      }
    }

    this.setState({ selectedDiscount: e.target.value });
  };

  createOrder = () => {
    axios({
      url: "",
      method: "POST",
      data: {
        identifier: localStorage.getItem("identifier"),
        serviceId: this.state.selectedServiceId,
        date: new Date(
          this.state.date.split("-").reverse().join("-")
        ).toISOString(),
        startTime: moment
          .utc(`${this.state.date} ${this.state.startTime}`, "DD-MM-YYYY HH:mm")
          .toISOString(),
        endTime: moment
          .utc(`${this.state.date} ${this.state.endTime}`, "DD-MM-YYYY HH:mm")
          .toISOString(),
        location: this.state.selectedLocation,
        subLocation: this.state.selectedSubLocation,
        category: this.state.selectedCategory.toLowerCase(),
        // customerId: data.customerId,
        discount: [{ couponId: this.state.selectedDiscount || "" }],
        total: this.calculateTotal(),
        subTotal: this.state.basePrice,
        tax: this.state.gst,
        // billingAddress: data.billingAddress,
        subCategory: this.state.selectedCategory,
        modeOfPayment: this.state.payment,
        paymentStatus: this.state.paymentStatus,
        paymentDetails:
          this.state.paymentStatus === "partial"
            ? this.state.paymentDetails
            : [],
      },
    })
      .then((res: any) => {})
      .catch((err: any) => {});
  };

  selectUser = (e: any) => {
    axios({
      url:
        "https://backend.retaino.in/api/users/getuserdetailsbyid/" +
        e.target.value,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res: any) => {})
      .catch((err: any) => {});
  };

  render() {
    return (
      <Content>
        <ContentHead
          title="Create Order"
          showBtn1={true}
          showBtn2={true}
          btn1Name="btn1"
          btn2Name="btn2"
          btn1Text="Save"
          btn2Text="Cancel"
          btn1type="filled"
          btn2type="outlined_del"
          onClickHandler={this.handleClick}
        />
        <ContentBody>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div className={`${styles.card}`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2>Order Details</h2>
                <p>₹ {this.calculateTotal()}</p>
              </div>
              {localStorage.getItem("ecommerce") === "true" ? (
                <InputField
                  type={"select"}
                  options={[...this.state.products]}
                  accessText={"title"}
                  accessValue={"_id"}
                  isLabel={true}
                  lable={"Product"}
                  value={this.state.selectedProduct}
                  onChange={(e: any) => {
                    this.setState({ selectedProduct: e.target.value });
                  }}
                  placeholder="Select the product"
                />
              ) : (
                <InputField
                  type={"select"}
                  options={[
                    ...this.state.uniqueServices.map((val: any) => ({
                      title: val,
                      value: val,
                    })),
                  ]}
                  accessText={"title"}
                  accessValue={"value"}
                  isLabel={true}
                  lable={"Select Service"}
                  value={this.state.selectedService}
                  onChange={(e: any) => {
                    this.setState({ selectedService: e.target.value });
                  }}
                />
              )}
              {localStorage.getItem("service") !== "false" && (
                <div
                  className={styles.grid_3}
                  style={{ gridTemplateColumns: "repeat(4,1fr)" }}
                >
                  <InputField
                    type={"select"}
                    options={
                      this.state.selectedService
                        ? [
                            // @ts-ignore
                            ...new Set(
                              this.state.services
                                .filter(
                                  (val: any) =>
                                    val.title === this.state.selectedService
                                )
                                .map((val: any) => val.location)
                            ),
                          ].map((val) => ({ title: val, value: val }))
                        : []
                    }
                    accessText={"title"}
                    accessValue={"value"}
                    isLabel={true}
                    lable={"Select Location"}
                    value={this.state.selectedLocation}
                    onChange={(e: any) =>
                      this.setState({ selectedLocation: e.target.value })
                    }
                    placeholder="Select Location"
                  />
                  <InputField
                    type={"select"}
                    // in this will filter using title and location from the services array
                    options={
                      this.state.selectedLocation
                        ? [
                            ...this.state.services
                              .filter(
                                (val: any) =>
                                  val.title === this.state.selectedService &&
                                  val.location === this.state.selectedLocation
                              )
                              .map((val: any) => ({
                                title: val.subLocation,
                                value: val.subLocation,
                              })),
                          ]
                        : []
                    }
                    accessText="title"
                    accessValue="value"
                    isLabel={true}
                    lable={"Sub Location"}
                    value={this.state.selectedSubLocation}
                    onChange={(e: any) =>
                      this.setState({ selectedSubLocation: e.target.value })
                    }
                  />
                  <InputField
                    type={"select"}
                    options={
                      this.state.selectedSubLocation
                        ? [
                            ...this.state.services
                              .filter(
                                (val: any) =>
                                  val.title === this.state.selectedService &&
                                  val.location ===
                                    this.state.selectedLocation &&
                                  val.subLocation ===
                                    this.state.selectedSubLocation
                              )
                              .map((val: any) => ({
                                title: val.category,
                                value: val.category,
                              })),
                          ]
                        : []
                    }
                    accessText={"title"}
                    accessValue={"value"}
                    isLabel={true}
                    lable={"Select Category"}
                    value={this.state.selectedCategory}
                    onChange={(e: any) =>
                      this.setState({ selectedCategory: e.target.value })
                    }
                    placeholder="Select Category"
                  />
                  <InputField
                    type="select"
                    options={
                      this.state.selectedSubLocation &&
                      this.state.selectedCategory
                        ? [
                            ...this.state.services
                              .filter((val: any) => {
                                return (
                                  val.title === this.state.selectedService &&
                                  val.location ===
                                    this.state.selectedLocation &&
                                  val.subLocation ===
                                    this.state.selectedSubLocation &&
                                  val.category === this.state.selectedCategory
                                );
                              })
                              .map((val: any) => ({
                                title: val.subCategory,
                                value: val.subCategory,
                              })),
                          ]
                        : []
                    }
                    accessText="title"
                    accessValue="value"
                    isLabel={true}
                    lable={"Sub Category"}
                    value={this.state.selectedSubCategory}
                    onChange={(e: any) =>
                      this.setState({ selectedSubCategory: e.target.value })
                    }
                    placeholder="Select Sub Category"
                  />
                </div>
              )}
              {localStorage.getItem("ecommerce") === "true" &&
                this.state.selectedProduct && (
                  <div className="grid_2">
                    {this.state.products
                      .filter(
                        (val: any) => val._id === this.state.selectedProduct
                      )[0]
                      // @ts-ignore
                      ?.variant.map((vall: any) => {
                        return (
                          <InputField
                            type="select"
                            options={vall.options_value.map((val: any) => ({
                              title: val.split(":")[0],
                              value: val,
                            }))}
                            accessText="title"
                            accessValue="value"
                            isLabel={true}
                            lable={vall.options_name}
                            value={this.state.selectedSubCategory}
                            onChange={(e: any) =>
                              this.setState({
                                selectedSubCategory: e.target.value,
                              })
                            }
                            placeholder={`Select ${vall.options_name}`}
                          />
                        );
                      })}
                  </div>
                )}
              <div className={styles.grid_3}>
                {this.state.selectedLocation &&
                  this.state.selectedSubLocation &&
                  this.state.selectedCategory &&
                  this.state.selectedSubCategory && (
                    <>
                      <InputField
                        type={"date"}
                        isLabel={true}
                        lable={"Date"}
                        value={this.state.date}
                        onChange={this.handleAddDate}
                      />

                      {this.state.date ? (
                        <>
                          <div className="input_style">
                            <label>Start Time</label>
                            <div>
                              <select
                                value={this.state.startTime}
                                onChange={this.handleStartTime}
                              >
                                <option value="" disabled selected>
                                  Select Start Time
                                </option>
                                {this.state.TImings.map((x, i) => (
                                  <option
                                    key={i}
                                    value={x.time}
                                    disabled={!x.enabled}
                                  >
                                    {(String(
                                      Number(x.time.split(":")[0]) % 12 || 12
                                    ).length === 2
                                      ? Number(x.time.split(":")[0]) % 12 || 12
                                      : "0" +
                                        (Number(x.time.split(":")[0]) % 12 ||
                                          12)) +
                                      ":" +
                                      x.time.split(":")[1] +
                                      " " +
                                      (Number(x.time.split(":")[0]) < 12
                                        ? "AM"
                                        : "PM")}
                                  </option>
                                ))}
                              </select>
                              <Down />
                            </div>
                          </div>
                          {this.state.startTime ? (
                            <div className="input_style">
                              <label>End Time</label>
                              <div>
                                <select
                                  value={this.state.endTime}
                                  onChange={this.selectEndDate}
                                >
                                  <option value="" disabled selected>
                                    Select End Time
                                  </option>
                                  {this.state.endTimings.map(
                                    (x: any, i: any) => (
                                      <option
                                        key={i}
                                        value={x.time}
                                        disabled={!x.enabled}
                                      >
                                        {(String(
                                          Number(x.time.split(":")[0]) % 12 ||
                                            12
                                        ).length === 2
                                          ? Number(x.time.split(":")[0]) % 12 ||
                                            12
                                          : "0" +
                                            (Number(x.time.split(":")[0]) %
                                              12 || 12)) +
                                          ":" +
                                          x.time.split(":")[1] +
                                          " " +
                                          (Number(x.time.split(":")[0]) < 12
                                            ? "AM"
                                            : "PM")}
                                      </option>
                                    )
                                  )}
                                </select>
                                <Down />
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </>
                      ) : (
                        <>
                          <div></div>
                          <div></div>
                        </>
                      )}
                    </>
                  )}

                <InputField
                  type={"number"}
                  isLabel={true}
                  lable={"Quantity"}
                  value={this.state.quantity}
                  onChange={(e: any) =>
                    this.setState({ quantity: e.target.value })
                  }
                  placeholder="Enter Quantity"
                />
              </div>
            </div>
            <div className={styles.grid_2}>
              <div className={styles.card}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>Customer Details</h2>
                  {!this.state.isUserAccountVerified && (
                    <div>
                      <button
                        className={styles.add_btn}
                        onClick={() =>
                          this.setState({ userToggle: !this.state.userToggle })
                        }
                      >
                        {this.state.userToggle ? (
                          <>Cancel</>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faAdd} /> New Customer
                          </>
                        )}
                      </button>
                      {this.state.userToggle && (
                        <button
                          className={styles.add_btn}
                          onClick={this.handleUserCreate}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  )}
                </div>
                {this.state.userToggle ? (
                  <div className={styles.grid_2}>
                    <div style={{ position: "relative" }}>
                      <InputField
                        type={"email"}
                        isLabel={true}
                        lable={"Email"}
                        value={this.state.email}
                        onChange={(e: any) => {
                          if (!this.state.otpSent) {
                            this.setState({ email: e.target.value });
                          }
                        }}
                        placeholder="Enter Email"
                      />
                      {this.state.email !== "" &&
                        (this.state.verified ? (
                          <p
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: "105%",
                              height: "60px",
                              color: "green",
                              fontSize: "1rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Verified
                          </p>
                        ) : (
                          <>
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                left: "105%",
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              <button
                                style={{
                                  height: "60px",
                                  background: "black",
                                  color: "white",
                                  minWidth: "150px",
                                  borderRadius: "15px",
                                  border: "none",
                                  fontSize: "1.1rem",
                                  letterSpacing: "0.05rem",
                                  whiteSpace: "nowrap",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.sendOtp();
                                  this.setState({ otpSent: true });
                                }}
                              >
                                {this.state.otpSent ? "Resend Otp" : "Verify"}
                              </button>
                              {this.state.otpSent && (
                                <button
                                  style={{
                                    height: "60px",
                                    background: "black",
                                    color: "white",
                                    minWidth: "100px",
                                    borderRadius: "15px",
                                    border: "none",
                                    fontSize: "1.1rem",
                                    letterSpacing: "0.05rem",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.setState({ otpSent: false });
                                  }}
                                >
                                  Edit
                                </button>
                              )}
                            </div>
                          </>
                        ))}
                    </div>
                    <div></div>
                    {this.state.otpSent && !this.state.verified && (
                      <div style={{ position: "relative" }}>
                        <InputField
                          type={"number"}
                          isLabel={true}
                          lable={"Enter OTP to verify email"}
                          value={this.state.otp}
                          onChange={(e: any) => {
                            this.setState({ otp: e.target.value });
                          }}
                          placeholder="Enter OTP to verify email"
                        />
                        {String(this.state.otp).length === 6 && (
                          <button
                            style={{
                              position: "absolute",
                              bottom: 0,
                              left: "105%",
                              height: "60px",
                              background: "black",
                              color: "white",
                              minWidth: "150px",
                              borderRadius: "15px",
                              border: "none",
                              fontSize: "1.1rem",
                              letterSpacing: "0.05rem",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({ otpSent: true });
                              this.verifyOtp();
                            }}
                          >
                            Verify
                          </button>
                        )}
                      </div>
                    )}
                    {this.state.verified && (
                      <>
                        <InputField
                          type={"text"}
                          isLabel={true}
                          lable={"First Name"}
                          value={this.state.firstName}
                          onChange={(e: any) => {
                            this.setState({ firstName: e.target.value });
                          }}
                          placeholder="Enter First Name"
                        />
                        <InputField
                          type={"text"}
                          isLabel={true}
                          lable={"Last Name"}
                          value={this.state.lastName}
                          onChange={(e: any) => {
                            this.setState({ lastName: e.target.value });
                          }}
                          placeholder="Enter Last Name"
                        />
                        <InputField
                          type={"number"}
                          isLabel={true}
                          lable={"Phone No."}
                          value={this.state.mobileNumber}
                          onChange={(e: any) => {
                            this.setState({ mobileNumber: e.target.value });
                          }}
                          placeholder="Enter Phone No."
                        />
                        <InputField
                          type="text"
                          isLabel={true}
                          lable={"Address Line One"}
                          value={this.state.billingAddress.addLineOne}
                          onChange={(e: any) => {
                            this.setState({
                              billingAddress: {
                                ...this.state.billingAddress,
                                addLineOne: e.target.value,
                              },
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          isLabel={true}
                          lable={"Address Line Two"}
                          value={this.state.billingAddress.addLineTwo}
                          onChange={(e: any) => {
                            this.setState({
                              billingAddress: {
                                ...this.state.billingAddress,
                                addLineTwo: e.target.value,
                              },
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          isLabel={true}
                          lable={"City"}
                          value={this.state.billingAddress.city}
                          onChange={(e: any) => {
                            this.setState({
                              billingAddress: {
                                ...this.state.billingAddress,
                                city: e.target.value,
                              },
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          isLabel={true}
                          lable={"State"}
                          value={this.state.billingAddress.state}
                          onChange={(e: any) => {
                            this.setState({
                              billingAddress: {
                                ...this.state.billingAddress,
                                state: e.target.value,
                              },
                            });
                          }}
                        />
                        <InputField
                          type="number"
                          isLabel={true}
                          lable={"Pincode"}
                          value={this.state.billingAddress.pincode}
                          onChange={(e: any) => {
                            this.setState({
                              billingAddress: {
                                ...this.state.billingAddress,
                                pincode: e.target.value,
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  </div>
                ) : (
                  <InputField
                    type={"select"}
                    //@ts-ignore
                    options={this.state.allUsers?.map((val: any) => ({
                      title: val.email,
                      value: val._id,
                    }))}
                    accessText={"title"}
                    accessValue={"value"}
                    isLabel={true}
                    lable={"Select Customer"}
                    value={""}
                    onChange={() => {}}
                    placeholder="Select Customer"
                  />
                )}
              </div>
              <div className={styles.card}>
                <h2>Discount</h2>
                <InputField
                  type={"select"}
                  options={[...this.state.discounts]}
                  accessText={"discountTitle"}
                  accessValue={"_id"}
                  isLabel={true}
                  lable={"Select Discount"}
                  value={this.state.selectedDiscount}
                  onChange={this.selectDiscount}
                  placeholder="Select Discount"
                />
              </div>
              <div className={styles.card}>
                <h2>Mode of Payment</h2>
                <InputField
                  type={"select"}
                  options={[
                    { title: "Cash", value: "cod" },
                    { title: "UPI 1", value: "upi1" },
                    { title: "UPI 2", value: "upi2" },
                    { title: "Wallet", value: "wallet" },
                    { title: "Credit Card", value: "credit" },
                    { title: "Debit Card", value: "debit" },
                  ]}
                  accessText={"title"}
                  accessValue={"value"}
                  isLabel={true}
                  lable={"Select Payment Mode"}
                  value={this.state.payment}
                  onChange={(e: any) =>
                    this.setState({ payment: e.target.value })
                  }
                  placeholder="Select Payment Mode"
                />
              </div>
              <div className={styles.card}>
                <h2>Payment Status</h2>
                <InputField
                  type={"select"}
                  options={[
                    { title: "Paid", value: "paid" },
                    { title: "UnPaid", value: "unpaid" },
                    { title: "Partial", value: "partial" },
                  ]}
                  accessText={"title"}
                  accessValue={"value"}
                  isLabel={true}
                  lable={"Select Payment Mode"}
                  value={this.state.paymentStatus}
                  onChange={(e: any) =>
                    this.setState({ paymentStatus: e.target.value })
                  }
                  placeholder="Select Payment Status"
                />
                {this.state.paymentStatus === "partial" && (
                  <>
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      Partial Amount{" "}
                      <button
                        style={{
                          background: "#eff5fc",
                          color: "#36619b",
                          border: "none",
                          padding: "5px 10px",
                          fontSize: "16px",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                        onClick={this.addPaymentDetails}
                      >
                        Add Partial Amount
                      </button>
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      {this.state.paymentDetails.map(
                        (val: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="grid_2"
                              style={{
                                border: "1px dashed lightgrey",
                                borderRadius: "15px",
                                padding: "20px",
                                position: "relative",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faClose}
                                color={"#36619b"}
                                style={{
                                  position: "absolute",
                                  height: "20px",
                                  width: "20px",
                                  background: "#eff5fc",
                                  cursor: "pointer",
                                  borderRadius: "100%",
                                  padding: "5px",
                                  right: "0",
                                  top: "0",
                                  transform: "translate(50%,-50%)",
                                }}
                                onClick={() => {
                                  let temp = this.state.paymentDetails;
                                  temp.splice(index, 1);
                                  this.setState({
                                    paymentDetails: [...temp],
                                  });
                                }}
                              />
                              <InputField
                                type={"number"}
                                isLabel={true}
                                lable={"Paid Amount"}
                                value={val.paidAmount}
                                onChange={(e: any) => {
                                  let temp: any = this.state.paymentDetails;
                                  temp[index].paidAmount = Number(
                                    e.target.value
                                  );
                                  this.setState({ paymentDetails: [...temp] });
                                }}
                                placeholder="Enter Paid Amount"
                              />
                              <InputField
                                type={"select"}
                                options={[
                                  { title: "Cash", value: "cod" },
                                  { title: "UPI 1", value: "upi1" },
                                  { title: "UPI 2", value: "upi2" },
                                  { title: "Wallet", value: "wallet" },
                                  { title: "Credit Card", value: "credit" },
                                  { title: "Debit Card", value: "debit" },
                                ]}
                                accessText={"title"}
                                accessValue={"value"}
                                isLabel={true}
                                lable={"Select Payment Mode"}
                                value={val.modeOfPayment}
                                onChange={(e: any) => {
                                  let temp: any = this.state.paymentDetails;
                                  temp[index].modeOfPayment = e.target.value;
                                  this.setState({ paymentDetails: [...temp] });
                                }}
                                placeholder="Select Payment Mode"
                              />
                              {/* <InputField
                                type={"date"}
                                isLabel={true}
                                lable={"Date of payment"}
                                value={val.dateOfPayment}
                                onChange={(e: any) => {
                                  let temp: any = this.state.paymentDetails;
                                  temp[index].dateOfPayment = e.target.value;
                                  this.setState({ paymentDetails: [...temp] });
                                }}
                                placeholder="Enter Date of payment"
                              /> */}
                              {/* <div
                                style={{
                                  paddingTop: "28px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "60px",
                                    width: "100%",
                                    border: "1px dashed lightgrey",
                                    borderRadius: "15px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "10px 20px 10px 10px",
                                  }}
                                >
                                  Status{" "}
                                  <ToggleSwitch
                                    value={val.status}
                                    onClick={() => {
                                      let temp: any = this.state.paymentDetails;
                                      temp[index].status = !temp[index].status;
                                      this.setState({
                                        paymentDetails: [...temp],
                                      });
                                    }}
                                  />
                                </div>
                              </div> */}
                            </div>
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {this.state.loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(0,0,0,0.5)",
                zIndex: 100000,
              }}
            >
              <span className={"loader"}></span>
            </div>
          )}
        </ContentBody>
      </Content>
    );
  }
}

const tim = [
  { time: "00:00", enabled: false },
  { time: "00:30", enabled: false },
  { time: "01:00", enabled: false },
  { time: "01:30", enabled: false },
  { time: "02:00", enabled: false },
  { time: "02:30", enabled: false },
  { time: "03:00", enabled: false },
  { time: "03:30", enabled: false },
  { time: "04:00", enabled: false },
  { time: "04:30", enabled: false },
  { time: "05:00", enabled: true },
  { time: "05:30", enabled: true },
  { time: "06:00", enabled: true },
  { time: "06:30", enabled: true },
  { time: "07:00", enabled: true },
  { time: "07:30", enabled: true },
  { time: "08:00", enabled: true },
  { time: "08:30", enabled: true },
  { time: "09:00", enabled: true },
  { time: "09:30", enabled: true },
  { time: "10:00", enabled: true },
  { time: "10:30", enabled: true },
  { time: "11:00", enabled: true },
  { time: "11:30", enabled: true },
  { time: "12:00", enabled: true },
  { time: "13:30", enabled: true },
  { time: "14:00", enabled: true },
  { time: "14:30", enabled: true },
  { time: "15:00", enabled: true },
  { time: "15:30", enabled: true },
  { time: "16:00", enabled: true },
  { time: "16:30", enabled: true },
  { time: "17:00", enabled: true },
  { time: "17:30", enabled: true },
  { time: "18:00", enabled: true },
  { time: "18:30", enabled: true },
  { time: "19:00", enabled: true },
  { time: "19:30", enabled: true },
  { time: "20:00", enabled: true },
  { time: "20:30", enabled: true },
  { time: "21:00", enabled: true },
  { time: "21:30", enabled: true },
  { time: "20:00", enabled: true },
  { time: "20:30", enabled: true },
  { time: "21:00", enabled: true },
  { time: "21:30", enabled: true },
  { time: "22:00", enabled: true },
  { time: "22:30", enabled: true },
  { time: "23:00", enabled: true },
  { time: "23:30", enabled: false },
  { time: "00:00", enabled: false },
];

export default withRouter(AddServiceOrder);

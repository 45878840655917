import moment, { Moment, duration } from "moment";

export const calculatePrice = (
  startTime: Moment,
  endTime: Moment,
  service: any,
  day: string
) => {
  const arr = service.pricing.markupPrice?.filter((x: any) =>
    x.days.map((val: any) => val.toLowerCase()).includes(day.toLowerCase())
  );

  const { basePrice: normalBasePrice } = service.pricing;

  const diff = (endTime.diff(startTime) / (1000 * 60 * 60)) % 24;

  if (arr?.length === 0) {
    return {
      basePrice: normalBasePrice * diff,
      gst: normalBasePrice * diff * (service.pricing.igst / 100),
    };
  }

  const arrayOfTimes = [];

  while (startTime.isSameOrBefore(endTime)) {
    arrayOfTimes.push(startTime.format("HH:mm"));
    startTime.add(30, "minutes");
  }

  let basePrice = 0;

  const lim = arr?.length ? arr.length : 0;

  for (let i = 0; i < arrayOfTimes.length - 1; i++) {
    for (let j = 0; j < lim; j++) {
      if (arr) {
        let serviceStartTime = moment(arr[j].startTime, "HH:mm");
        let serviceEndTime = moment(
          arr[j].endTime === "00:00" ? "24:00" : arr[j].endTime,
          "HH:mm"
        );
        let startTime = moment(arrayOfTimes[i], "HH:mm");
        let endTime = moment(arrayOfTimes[i + 1], "HH:mm");
        const overlap1 = startTime.isBetween(
          serviceStartTime,
          serviceEndTime,
          null,
          "[]"
        );
        const overlap2 = endTime.isBetween(
          serviceStartTime,
          serviceEndTime,
          null,
          "[]"
        );

        //     startTime: startTime.format("HH:mm"),
        //     endTime: endTime.format('HH:mm'),
        //     serviceStartTime: serviceStartTime.format("HH:mm"),
        //     serviceEndTime: serviceEndTime.format("HH:mm")

        // } )
        if (overlap1 && overlap2) {
          basePrice += arr[j].price * 0.5;
          break;
        }
      }
    }
  }
  if (basePrice === 0) {
    return {
      basePrice: normalBasePrice * diff,
      gst: normalBasePrice * diff * 0.18,
    };
  }
  return {
    basePrice: basePrice,
    gst: basePrice * 0.18,
  };
};

export function formatDate(date: any): string {
  if (date == undefined) {
    return "";
  }

  date = new Date(date);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based in JavaScript
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

import React from "react";
import SingleComingFrom from "./SingleComingFrom";
import styles from "./comingFrom.module.css";
import { ComingFromType } from "../../../Pages/Analytics";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

type Props = {
  comingFrom: ComingFromType[];
  loading?: boolean;
};

const ComingFrom = ({ comingFrom, loading }: Props) => {
  const cf = comingFrom.splice(0, 5);

  if (loading)
    return (
      <>
        <h3>Session Source</h3>
        <div
          style={{
            width: "100%",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      </>
    );

  if (!loading && cf.length === 0)
    return (
      <>
        <h3>Session Source</h3>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "gray",
          }}
        >
          <span>No Data Found</span>
        </div>
      </>
    );
  return (
    <>
      <h3>Session Source</h3>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h5>Session Source</h5>
          <h5>Sessions</h5>
        </div>
        {cf && (
          <>
            <div className={styles.mainData}>
              {cf &&
                cf.map((data, key) => (
                  <SingleComingFrom
                    label={data.label}
                    count={data?.session}
                    key={key}
                  />
                ))}
            </div>
            <div className={styles.btnContainer}>
              <Link to={"/analytics/coming-from"} className={styles.btn}>
                See More
                <FaArrowRight size={16} />
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ComingFrom;

import { useNavigate, useParams } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import InputField from "../../Components/InputField";
import { useEffect, useState } from "react";
import ToggleSwitch from "../../Components/ToggleSwitch";
import { cities } from "../../Utils/cities";
import toast from "react-hot-toast";
import axios from "axios";

const AddUpdateSegments = () => {
  const params = useParams();
  const navigate = useNavigate();
  const title = params?.id ? "Update Segment" : "Add Segment";

  const [loading, setLoading] = useState(true);

  const [heading, setHeading] = useState("");

  const [age, setAge] = useState(false);
  const [ageStart, setAgeStart] = useState("");
  const [ageEnd, setAgeEnd] = useState("");

  const [device, setDevice] = useState(false);
  const [deviceType, setDeviceType] = useState("");

  const [location, setLocation] = useState(false);
  const [locations, setLocations]: any = useState([]);

  const [purchaseFreq, setPurchaseFreq] = useState(false);
  const [purchaseFreqNo, setPurchaseFreqNo] = useState("");
  const [purchaseFeqPeriod, setPurchaseFreqPeriod] = useState("");

  const [abandoned, setAbandoned] = useState(false);
  const [NumberOfAbandonedCart, setNumberOfAbandonedCart] = useState("");
  const [AbandonedCartPeriod, setAbandonedCartPeriod] = useState("");

  const [avgAmount, setAvgAmount] = useState(false);
  const [avgAmountFrom, setAvgAmountFrom] = useState("");
  const [avgAmountTo, setAvgAmountTo] = useState("");

  const [cart, setCart] = useState(false);
  const [cartCategory, setCartCategory] = useState("");
  const [cartPeriod, setCartPeriod] = useState("");

  const [session, setSession] = useState(false);
  const [sessionFrom, setSessionFrom] = useState("");
  const [sessionTo, setSessionTo] = useState("");

  const [comingFrom, setComingFrom] = useState(false);
  const [comingFromValue, setComingFromValue] = useState("");

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }productcategory/?identifier=${localStorage.getItem(
        "identifier"
      )}&limit=10000`,
    })
      .then((res: any) => {
        const data = res.data.productCategorys || [];
        setCategories(data.map((v: any) => v.title));
        if (params?.id) {
          getData();
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        if (params?.id) {
          getData();
        } else {
          setLoading(false);
        }
      });
  };

  const getData = () => {
    axios({
      method: "GET",
      url: `https://backend.retaino.in/api/segments/${params.id}`,
    })
      .then((res: any) => {
        const data = res.data.segment;
        setHeading(data.segmentName);
        const rules = data.rules;

        setAge(rules.age);
        setAgeStart(rules.ageRule.from);
        setAgeEnd(rules.ageRule.to);

        setDevice(rules.deviceType);
        setDeviceType(rules.deviceTypeRule);

        setLocation(rules.location);
        setLocations(rules.locationRule);

        setPurchaseFreq(rules.purchaseFrequency);
        setPurchaseFreqNo(rules.purchaseFrequencyRule.number);
        setPurchaseFreqPeriod(rules.purchaseFrequencyRule.time);

        setAbandoned(rules.abandonedCartFrequency);
        setNumberOfAbandonedCart(rules.abandonedCartFrequencyRule.number);
        setAbandonedCartPeriod(rules.abandonedCartFrequencyRule.time);

        setAvgAmount(rules.avgAmountSpend);
        setAvgAmountFrom(rules.avgAmountSpendRule.from);
        setAvgAmountTo(rules.avgAmountSpendRule.to);

        setCart(rules.haveProductsInCart);
        setCartCategory(rules.haveProductsInCartRule.category);
        setCartPeriod(rules.haveProductsInCartRule.period);

        setSession(rules.sessionTime);
        setSessionFrom(rules.sessionTimeRule.from);
        setSessionTo(rules.sessionTimeRule.to);

        setComingFrom(rules.comingFrom);
        setComingFromValue(rules.comingFromRule);

        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  const handleClick = (e: any) => {
    if (e.target.name === "btn1") {
      handleAddUpdate();
    } else if (e.target.name === "btn2") {
      navigate("/segments");
    }
  };

  const validate = () => {
    if (!heading) {
      toast.error("Segment Name is required.");
      return false;
    }

    if (age && !ageStart) {
      toast.error("Age From value is required.");
      return false;
    }
    if (age && !ageEnd) {
      toast.error("Age End value is required.");
      return false;
    }

    if (device && !deviceType) {
      toast.error("Device Type is required.");
      return false;
    }

    if (location && locations.length === 0) {
      toast.error("Locations are required.");
      return false;
    }

    if (purchaseFreq && !purchaseFreqNo) {
      toast.error("Purchase Frequency No. of purchases is required.");
      return false;
    }
    if (purchaseFreq && !purchaseFeqPeriod) {
      toast.error("Purchase Frequency time period is required.");
      return false;
    }

    if (abandoned && !NumberOfAbandonedCart) {
      toast.error("Abandoned Cart Number is required.");
      return false;
    }
    if (abandoned && !AbandonedCartPeriod) {
      toast.error("Abandoned Cart time period is required.");
      return false;
    }

    if (avgAmount && !avgAmountFrom) {
      toast.error("Avg. Amount From value is required.");
      return false;
    }
    if (avgAmount && !avgAmountTo) {
      toast.error("Avg. Amount To value is required.");
      return false;
    }

    if (cart && !cartCategory) {
      toast.error("Product in cart category value is required.");
      return false;
    }
    if (cart && !cartPeriod) {
      toast.error("Product in cart's time period is required.");
      return false;
    }

    if (session && !sessionFrom) {
      toast.error("Session Time's From value is required.");
      return false;
    }
    if (session && !sessionTo) {
      toast.error("Session Time's To value is required.");
      return false;
    }

    if (comingFrom && !comingFromValue) {
      toast.error("Coming From Values is required.");
      return false;
    }

    return true;
  };

  const handleAddUpdate = () => {
    if (validate()) {
      setLoading(true);
      let data = {
        identifier: localStorage.getItem("identifier"),
        segmentName: heading.trim(),
        rules: {
          age,
          ageRule: { from: ageStart, to: ageEnd },
          deviceType: device,
          deviceTypeRule: deviceType,
          location,
          locationRule: locations,
          purchaseFrequency: purchaseFreq,
          purchaseFrequencyRule: {
            number: purchaseFreqNo,
            time: purchaseFeqPeriod,
          },
          abandonedCartFrequency: abandoned,
          abandonedCartFrequencyRule: {
            number: NumberOfAbandonedCart,
            time: AbandonedCartPeriod,
          },
          avgAmountSpend: avgAmount,
          avgAmountSpendRule: { from: avgAmountFrom, to: avgAmountTo },
          haveProductsInCart: cart,
          haveProductsInCartRule: {
            category: cartCategory,
            period: cartPeriod,
          },
          sessionTime: session,
          sessionTimeRule: {
            from: sessionFrom,
            to: sessionTo,
          },
          comingFrom,
          comingFromRule: comingFromValue,
        },
      };

      axios({
        url: params?.id
          ? `https://backend.retaino.in/api/segments/${params.id}`
          : `https://backend.retaino.in/api/segments/`,
        method: params?.id ? "put" : "post",
        data: data,
      })
        .then((res: any) => {
          setLoading(false);
          toast.success("Created successfully.");
          navigate("/segments");
        })
        .catch((err: any) => {
          toast.error("Something went wrong.");
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentHead
          title={title}
          showBtn1={true}
          btn1Name="btn1"
          btn1Text="Save"
          btn1type="filled"
          showBtn2={true}
          btn2Name="btn2"
          btn2Text="Cancel"
          btn2type="outlined_del"
          onClickHandler={handleClick}
        />
        <ContentBody>
          <div className="grid">
            <div className="card grid_2">
              <InputField
                type="text"
                value={heading}
                onChange={(e: any) => setHeading(e.target.value)}
                isLabel={true}
                lable={"Segment Name"}
                placeholder="Enter Segment Name"
              />
            </div>
            <div className="card grid">
              <h3>Who They Are?</h3>
              <div className="grid_2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Age</p>
                    <ToggleSwitch
                      value={age}
                      onClick={() => setAge((prev) => !prev)}
                    />
                  </div>
                  {age && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          From
                        </span>
                        <input
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            padding: "10px",
                          }}
                          value={ageStart}
                          onChange={(e: any) => setAgeStart(e.target.value)}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          To
                        </span>
                        <input
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            padding: "10px",
                          }}
                          value={ageEnd}
                          onChange={(e: any) => setAgeEnd(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Device Type</p>
                    <ToggleSwitch
                      value={device}
                      onClick={() => setDevice((prev) => !prev)}
                    />
                  </div>
                  {device && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          Select Device
                        </span>
                        <select
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            padding: "10px",
                            width: "100%",
                          }}
                          value={deviceType}
                          onChange={(e: any) => setDeviceType(e.target.value)}
                        >
                          <option disabled={true} selected>
                            Select Device Type
                          </option>
                          <option value="mobile">Mobile</option>
                          <option value="tablet">Tablet</option>
                          <option value="desktop">Desktop</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Location</p>
                    <ToggleSwitch
                      value={location}
                      onClick={() => setLocation((prev) => !prev)}
                    />
                  </div>
                  {location && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          Select locations
                        </span>
                        <select
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            padding: "10px",
                            width: "100%",
                          }}
                          onChange={(e: any) =>
                            setLocations((prev: any) => [
                              ...prev,
                              e.target.value,
                            ])
                          }
                        >
                          <option disabled={true} selected>
                            Select Locations
                          </option>
                          {cities.map((v: any, i: any) => {
                            return <option value={v.name}>{v.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card grid">
              <h3>Behavior</h3>
              <div className="grid_2">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Purchase Frequency</p>
                    <ToggleSwitch
                      value={purchaseFreq}
                      onClick={() => setPurchaseFreq((prev) => !prev)}
                    />
                  </div>
                  {purchaseFreq && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          No. of purchase
                        </span>
                        <input
                          type="number"
                          value={purchaseFreqNo}
                          onChange={(e: any) =>
                            setPurchaseFreqNo(e.target.value)
                          }
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          Time period
                        </span>
                        <input
                          value={purchaseFeqPeriod}
                          onChange={(e: any) =>
                            setPurchaseFreqPeriod(e.target.value)
                          }
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Abandoned Cart Frequency</p>
                    <ToggleSwitch
                      value={abandoned}
                      onClick={() => setAbandoned((prev) => !prev)}
                    />
                  </div>
                  {abandoned && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          No. of abandoned carts
                        </span>
                        <input
                          value={NumberOfAbandonedCart}
                          onChange={(e: any) =>
                            setNumberOfAbandonedCart(e.target.value)
                          }
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          Time period
                        </span>
                        <input
                          value={AbandonedCartPeriod}
                          onChange={(e: any) =>
                            setAbandonedCartPeriod(e.target.value)
                          }
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Avg. Amount Spend</p>
                    <ToggleSwitch
                      value={avgAmount}
                      onClick={() => setAvgAmount((prev) => !prev)}
                    />
                  </div>
                  {avgAmount && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          From
                        </span>
                        <input
                          value={avgAmountFrom}
                          onChange={(e: any) =>
                            setAvgAmountFrom(e.target.value)
                          }
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          To
                        </span>
                        <input
                          value={avgAmountTo}
                          onChange={(e: any) => setAvgAmountTo(e.target.value)}
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Have Products in cart</p>
                    <ToggleSwitch
                      value={cart}
                      onClick={() => setCart((prev) => !prev)}
                    />
                  </div>
                  {cart && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          Category of product in cart
                        </span>
                        <select
                          value={cartCategory}
                          onChange={(e: any) => setCartCategory(e.target.value)}
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            padding: "10px",
                            width: "100%",
                          }}
                        >
                          <option selected disabled value="">
                            Select Category
                          </option>
                          {categories.map((c: any, i: any) => {
                            return <option key={i}>{c}</option>;
                          })}
                        </select>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          Added in last ( add value in days )
                        </span>
                        <input
                          value={cartPeriod}
                          onChange={(e: any) => setCartPeriod(e.target.value)}
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Session Time</p>
                    <ToggleSwitch
                      value={session}
                      onClick={() => setSession((prev) => !prev)}
                    />
                  </div>
                  {session && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          From ( in minutes )
                        </span>
                        <input
                          value={sessionFrom}
                          onChange={(e: any) => setSessionFrom(e.target.value)}
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          To ( in minutes )
                        </span>
                        <input
                          value={sessionTo}
                          onChange={(e: any) => setSessionTo(e.target.value)}
                          type="number"
                          style={{
                            height: "40px",
                            borderRadius: "10px",
                            border: "1px solid grey",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                  className="sub_container"
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p>Coming From</p>
                    <ToggleSwitch
                      value={comingFrom}
                      onClick={() => setComingFrom((prev) => !prev)}
                    />
                  </div>
                  {comingFrom && (
                    <div
                      style={{ display: "flex", width: "100%", gap: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <span style={{ fontSize: ".7rem", color: "grey" }}>
                          From ( in minutes )
                        </span>
                        <select
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                            padding: "10px",
                            width: "100%",
                          }}
                          value={comingFromValue}
                          onChange={(e: any) =>
                            setComingFromValue(e.target.value)
                          }
                        >
                          <option selected disabled>
                            Select Coming From
                          </option>
                          <option>Instagram</option>
                          <option>Google</option>
                          <option>Facebook</option>
                          <option>Direct</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

export default AddUpdateSegments;

import React from "react";
import styles from "../../Styles/services.module.css";
import Content from "../../Components/Content/Content";
import ContentHead from "../../Components/Content/ContentHead";
import ContentBody from "../../Components/Content/ContentBody";
import InputField from "../../Components/InputField";
import TextEditor from "../../Components/TextEditor";
import withRouter from "../../Components/withRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-hot-toast";
import UploadImage from "../../Utils/UploadImage";
import { getFileName } from "../../Utils/getFileName";

class AddUpdateServices extends React.Component<any, any> {
  state = {
    loading: false,
    mediaUrl: [],
    title: "",
    category: "",
    subCategory: "",
    serviceType: "",
    location: "",
    subLocation: "",
    minQuantity: "",
    maxQuantity: "",
    tags: [],
    tagTxt: "",
    inclusions: [],
    inclusionTxt: "",
    terms: "",
    cancellation: "",
    description: "",
    // pricing part
    basePrice: "",
    gst: "",
    hsnsac: "",
    markupPrice: [] /* { price, date[], startTime, endTime, day, duration } */,

    // Operation Hours
    operationHours: [] /* { day, startTime, endTime } */,

    // Inventory
    inventory: [] /* { title, date, location, subLocation } */,
    chargeTax: false,
    saveAs: "active", // inactive, draft
  };

  componentDidMount(): void {
    if (this.props.router.params?.id) {
      this.getData();
      this.setState({ loading: true });
    }
  }

  getData = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}service/` +
        this.props.router.params.id,
      method: "get",
    })
      .then((res: any) => {
        let _res = res.data.service;

        let markupPrice = _res.pricing.markupPrice.map((val: any) => {
          return {
            price: val.price,
            date: val.date,
            dateVal: "",
            startTime: val.startTime,
            endTime: val.endTime,
            day: typeof val.days === "string" ? [val.days] : val.days,
            duration: val.duration,
          };
        });

        this.setState({
          title: _res.title,
          category: _res.category,
          subCategory: _res.subCategory,
          serviceType: _res.serviceType,
          location: _res.location,
          subLocation: _res.subLocation,
          minQuantity: _res.minQuantity,
          maxQuantity: _res.maxQuantity,
          tags: _res.tags,
          inclusions: _res.inclusions,
          terms: _res.terms,
          cancellation: _res.cancellation,
          description: _res.description,
          mediaUrl: _res.mediaUrl.map((val: any) => ({ obj: "", url: val })),
          // pricing part
          basePrice: _res.pricing.basePrice,
          gst: _res.pricing.igst,
          chargeTax: _res.pricing?.igst ? true : false,
          hsnsac: _res.hsnsac,
          markupPrice: markupPrice,
          // Operation Hours
          operationHours: _res.operationHours,
          // Inventory
          inventory: _res.inventory,
          loading: false,
        });
      })
      .catch((err) => {});
  };

  handleMediaAdd = (e: any) => {
    let temp = {
      obj: e.target.files[0],
      url: URL.createObjectURL(e.target.files[0]),
    };

    this.setState({ mediaUrl: [...this.state.mediaUrl, temp] });
  };

  addMarkupPriceObj = () => {
    let temp = {
      price: "",
      date: [],
      dateVal: "",
      startTime: "",
      endTime: "",
      day: [],
      duration: "",
    };

    this.setState({ markupPrice: [...this.state.markupPrice, temp] });
  };

  addOperationHoursObj = () => {
    let temp = {
      day: "",
      startTime: "",
      endTime: "",
    };

    this.setState({ operationHours: [...this.state.operationHours, temp] });
  };

  addInventoryObj = () => {
    let temp = {
      title: "",
      date: "",
      location: "",
      subLocation: "",
    };

    this.setState({ inventory: [...this.state.inventory, temp] });
  };

  uploadAllMedia = () => {
    return new Promise((resolve, reject) => {
      let temp_list = [];

      for (let i = 0; i < this.state.mediaUrl.length; i++) {
        // @ts-ignore
        if (this.state.mediaUrl[i].obj !== "") {
          // @ts-ignore
          let fileName = getFileName(this.state.mediaUrl[i].obj);

          try {
            let vv = UploadImage({
              // @ts-ignore
              file: this.state.mediaUrl[i].obj,
              name: fileName,
            });

            temp_list.push(vv);
            // return [filename];
          } catch (err) {
            toast.error("error occurred while uploading media url.");
            reject(err);
          }
        } else {
          // @ts-ignore
          temp_list.push(this.state.mediaUrl[i].url);
        }
      }

      resolve(temp_list);
    });
  };

  createService = () => {
    this.setState({ loading: true });

    let data = {
      identifier: localStorage.getItem("identifier"),
      title: this.state.title,
      operationHours: this.state.operationHours,
      location: this.state.location,
      subLocation: this.state.subLocation,
      description: this.state.description,
      pricing: {
        basePrice: this.state.basePrice,
        markupPrice: this.state.markupPrice.map((val: any) => ({
          price: val.price,
          date: val.date,
          startTime: val.startTime,
          endTime: val.endTime,
          days: val.day,
          duration: val.duration,
        })),
        cgst: Number(this.state.gst) / 2,
        sgst: Number(this.state.gst) / 2,
        igst: Number(this.state.gst),
      },
      inventory: this.state.inventory,
      category: this.state.category,
      subCategory: this.state.subCategory,
      hsnsac: this.state.hsnsac,
      tags: this.state.tags,
      minQuantity: this.state.minQuantity,
      maxQuantity: this.state.maxQuantity,
      inclusions: this.state.inclusions,
      cancellation: this.state.cancellation,
      terms: this.state.terms,
      serviceType: this.state.serviceType,
      status: this.state.saveAs,
    };

    this.uploadAllMedia()
      .then((res: any) => {
        axios({
          method: this.props.router.params?.id ? "patch" : "post",
          url: this.props.router.params?.id
            ? `${process.env.REACT_APP_BASE_URL}service/` +
              this.props.router.params?.id
            : `${process.env.REACT_APP_BASE_URL}service`,
          data: { ...data, mediaUrl: [...res] },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res) => {
            this.setState({ loading: false });
            toast.success(
              this.props.router.params?.id
                ? "Service updated successfully!"
                : "Service added successfully!"
            );
            this.props.router.navigate("/services");
          })
          .catch(() => {
            this.setState({ loading: false });
            toast.error(
              this.props.router.params?.id
                ? "Something went wrong while updating service."
                : "Something went wrong while adding service."
            );
          });
      })
      .catch((err: any) => {
        toast.error("something went wrong while uploading media");
        this.setState({ loading: false });
      });
  };

  render(): React.ReactNode {
    return (
      <>
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <Content>
          <ContentHead
            title={
              this.props.router.params.id ? "Update Service" : "Add New Service"
            }
            showBtn1={true}
            showBtn2={true}
            showBtn3={true}
            btn1Name="btn1"
            btn2Name="btn2"
            btn3Name="btn3"
            btn1Text="Save"
            btn2Text="Save as Draft"
            btn3Text="Cancel"
            btn1type="filled"
            btn2type="outlined"
            btn3type="outlined_del"
            onClickHandler={(e: any) => {
              if (e.target.name === "btn1") {
                this.setState({ saveAs: "active" });
                this.createService();
              } else if (e.target.name === "btn2") {
                this.setState({ saveAs: "draft" });
                this.createService();
              } else if (e.target.name === "btn3") {
                this.props.router.navigate("/services");
              }
            }}
          />
          <ContentBody>
            <div className={styles.main}>
              {/* title and other stuff */}
              <div className={styles.card} style={{ gridColumn: "1/3" }}>
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Title"
                  placeholder="Enter Title"
                  value={this.state.title}
                  onChange={(e: any) =>
                    this.setState({ title: e.target.value.toLowerCase() })
                  }
                  style={{ gridColumn: "1/5" }}
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Category"
                  placeholder="Enter category"
                  value={this.state.category}
                  onChange={(e: any) =>
                    this.setState({ category: e.target.value.toLowerCase() })
                  }
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Sub Category"
                  placeholder="Enter sub category"
                  value={this.state.subCategory}
                  onChange={(e: any) =>
                    this.setState({ subCategory: e.target.value })
                  }
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Service Type"
                  placeholder="Enter type"
                  value={this.state.serviceType}
                  onChange={(e: any) =>
                    this.setState({ serviceType: e.target.value })
                  }
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Location"
                  placeholder="Enter Location"
                  value={this.state.location}
                  onChange={(e: any) =>
                    this.setState({ location: e.target.value })
                  }
                />
                <InputField
                  type="text"
                  isLabel={true}
                  lable="Sub Location"
                  placeholder="Enter sub location"
                  value={this.state.subLocation}
                  onChange={(e: any) =>
                    this.setState({ subLocation: e.target.value.toLowerCase() })
                  }
                />
                <InputField
                  type="number"
                  isLabel={true}
                  lable="Min Quantity"
                  placeholder="Enter min quantity"
                  value={String(this.state.minQuantity)}
                  onChange={(e: any) =>
                    this.setState({ minQuantity: e.target.value })
                  }
                />
                <InputField
                  type="number"
                  isLabel={true}
                  lable="Max Quantity"
                  placeholder="Enter max quantity"
                  value={String(this.state.maxQuantity)}
                  onChange={(e: any) =>
                    this.setState({ maxQuantity: e.target.value })
                  }
                />
                <div style={{ gridColumn: "1/2" }}>
                  <InputField
                    type="text"
                    isLabel={true}
                    lable={
                      <>
                        Tags{" "}
                        <span style={{ color: "gray" }}>
                          (Press enter to add)
                        </span>
                      </>
                    }
                    placeholder="Enter relevant keywords"
                    value={this.state.tagTxt}
                    onChange={(e: any) =>
                      this.setState({ tagTxt: e.target.value })
                    }
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        this.setState({
                          tags: [...this.state.tags, this.state.tagTxt],
                          tagTxt: "",
                        });
                      }
                    }}
                  />
                  <div className={styles.badge_container}>
                    {this.state.tags.map((value: string, index: number) => {
                      return (
                        <p>
                          {value}{" "}
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              let temp = this.state.tags;
                              temp.splice(index, 1);
                              this.setState({ tags: [...temp] });
                            }}
                          />
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div style={{ gridColumn: "2/4" }}>
                  <InputField
                    type="text"
                    isLabel={true}
                    lable={
                      <>
                        Inclusinon{" "}
                        <span style={{ color: "gray" }}>
                          (Press enter to add)
                        </span>
                      </>
                    }
                    placeholder="Enter inclusions"
                    value={this.state.inclusionTxt}
                    onChange={(e: any) =>
                      this.setState({ inclusionTxt: e.target.value })
                    }
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        this.setState({
                          inclusions: [
                            ...this.state.inclusions,
                            this.state.inclusionTxt,
                          ],
                          inclusionTxt: "",
                        });
                      }
                    }}
                  />
                  <div className={styles.badge_container}>
                    {this.state.inclusions.map(
                      (value: string, index: number) => {
                        return (
                          <p>
                            {value}{" "}
                            <FontAwesomeIcon
                              icon={faClose}
                              onClick={() => {
                                let temp = this.state.inclusions;
                                temp.splice(index, 1);
                                this.setState({ inclusions: [...temp] });
                              }}
                            />
                          </p>
                        );
                      }
                    )}
                  </div>
                </div>
                <div style={{ gridColumn: "1/5" }}>
                  <InputField
                    type="textarea"
                    isLabel={true}
                    lable="Terms"
                    placeholder="Enter the service specific terms and conditions"
                    value={this.state.terms}
                    onChange={(e: any) =>
                      this.setState({ terms: e.target.value })
                    }
                  />
                </div>
                <div style={{ gridColumn: "1/5" }}>
                  <InputField
                    type="textarea"
                    isLabel={true}
                    lable="Cancellation"
                    placeholder="Enter the service specific cancellation policy"
                    value={this.state.cancellation}
                    onChange={(e: any) => {
                      this.setState({ cancellation: e.target.value });
                    }}
                  />
                </div>
                <div className={styles.text_editor_container}>
                  <p>Description</p>
                  <TextEditor
                    content={this.state.description}
                    handleChange={(val: any) =>
                      this.setState({ description: val })
                    }
                    placeholder="Enter the Description"
                  />
                </div>
              </div>

              {/* for media */}
              <div
                className={`${styles.card} ${styles.media_container}`}
                style={{ gridColumn: "1/3" }}
              >
                <h3>Media</h3>
                {!(this.state.mediaUrl.length > 0) ? (
                  <div className={styles.select_media}>
                    <p>+</p>
                    <p>Add Images/Videos</p>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={this.handleMediaAdd}
                    />
                  </div>
                ) : (
                  <div className={styles.selected_media}>
                    {this.state.mediaUrl.map((img_data: any, index: any) => {
                      return (
                        <div className={styles.media_image}>
                          <img
                            src={
                              this.props.router.params?.id
                                ? img_data.obj
                                  ? img_data.url
                                  : img_data.url
                                : img_data.url
                            }
                            alt=""
                          />
                          <FontAwesomeIcon
                            color="white"
                            icon={faClose}
                            onClick={() => {
                              let temp_mediaUrl = this.state.mediaUrl;
                              temp_mediaUrl.splice(index, 1);
                              this.setState({ mediaUrl: [...temp_mediaUrl] });
                            }}
                          />
                        </div>
                      );
                    })}
                    <div className={styles.select_media}>
                      <p>+</p>
                      <p>Add Media</p>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={this.handleMediaAdd}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* pricing */}
              <div className={styles.card}>
                <h3>Pricing</h3>
                <InputField
                  type="number"
                  isLabel={true}
                  lable="Base Price"
                  placeholder="Service base price"
                  value={this.state.basePrice}
                  onChange={(e: any) =>
                    this.setState({ basePrice: e.target.value })
                  }
                  style={{ gridColumn: "1/5" }}
                />
                <div
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    marginTop: "15px",
                    alignItems: "center",
                    gap: "10px",
                    gridColumn: "1/5",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={this.state.chargeTax}
                    style={{ fontSize: "2rem" }}
                    onChange={(event: any) => {
                      this.setState({ chargeTax: event.target.checked });
                      if (!event.target.checked) {
                        this.setState({ gst: "" });
                        this.setState({ hsnsac: "" });
                      }
                    }}
                  />
                  <p style={{ fontSize: "16px" }}>Charge tax on this product</p>
                </div>

                {this.state.chargeTax && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "20px",
                      marginTop: "10px",
                      gridColumn: "1/5",
                    }}
                    className={styles.hidden_input_arrows}
                  >
                    <InputField
                      type={"number"}
                      isLabel={true}
                      lable="GST"
                      value={this.state.gst}
                      onChange={(e: any) =>
                        this.setState({ gst: e.target.value })
                      }
                      sideIcon={true}
                    />
                    <InputField
                      type={"number"}
                      isLabel={true}
                      lable="HSN/SAC"
                      value={this.state.hsnsac}
                      onChange={(e: any) =>
                        this.setState({ hsnsac: e.target.value })
                      }
                    />
                  </div>
                )}
                <div style={{ gridColumn: "1/5" }}>
                  <div className={styles.markup_heading}>
                    <h3>Markup Price</h3>{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={this.addMarkupPriceObj}
                    >
                      +
                    </span>
                  </div>
                  <div className={styles.markup_container}>
                    {this.state.markupPrice.map((value: any, index: number) => {
                      return (
                        <div className={styles.markup_card} key={index}>
                          <InputField
                            type="number"
                            isLabel={true}
                            lable="Price"
                            placeholder="price"
                            value={value.price}
                            onChange={(e: any) => {
                              let temp: any = this.state.markupPrice;
                              temp[index].price = e.target.value;
                              this.setState({ markupPrice: [...temp] });
                            }}
                          />

                          <InputField
                            type="text"
                            isLabel={true}
                            lable="Duration"
                            placeholder="duration"
                            value={value.duration}
                            onChange={(e: any) => {
                              let temp: any = this.state.markupPrice;
                              temp[index].duration = e.target.value;
                              this.setState({ markupPrice: [...temp] });
                            }}
                          />

                          <InputField
                            type="time"
                            isLabel={true}
                            lable="Start Time"
                            placeholder="time"
                            value={value.startTime}
                            onChange={(e: any) => {
                              let temp: any = this.state.markupPrice;
                              temp[index].startTime = e.target.value;
                              this.setState({ markupPrice: [...temp] });
                            }}
                          />
                          <InputField
                            type="time"
                            isLabel={true}
                            lable="End Time"
                            placeholder="time"
                            value={value.endTime}
                            onChange={(e: any) => {
                              let temp: any = this.state.markupPrice;
                              temp[index].endTime = e.target.value;
                              this.setState({ markupPrice: [...temp] });
                            }}
                          />
                          <div>
                            <InputField
                              type="select"
                              isLabel={true}
                              lable="Day"
                              placeholder="day"
                              value={""}
                              options={[
                                { title: "Monday", value: "monday" },
                                { title: "Tuesday", value: "tuesday" },
                                { title: "Wednesday", value: "wednesday" },
                                { title: "Thursday", value: "thursday" },
                                { title: "Friday", value: "friday" },
                                { title: "Saturday", value: "saturday" },
                                { title: "Sunday", value: "sunday" },
                              ].filter((val) => {
                                if (value.day.length === 0) return true;
                                if (value.day.includes(val.value)) {
                                  return false;
                                }
                                return true;
                              })}
                              accessText="title"
                              accessValue="value"
                              onChange={(e: any) => {
                                let temp: any = this.state.markupPrice;
                                temp[index].day.push(e.target.value);
                                this.setState({ markupPrice: [...temp] });
                              }}
                            />
                            <ul
                              style={{
                                listStyle: "none",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              {value.day.length !== 0 &&
                                value?.day?.map((d: any, indexxx: any) => (
                                  <li
                                    key={indexxx}
                                    style={{
                                      background: "#eff5fc",
                                      color: "#36619b",
                                      width: "fit-content",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {d}{" "}
                                    <FontAwesomeIcon
                                      icon={faClose}
                                      color="#36619b"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let temp: any = this.state.markupPrice;
                                        let tempDay: any = temp[index].day;
                                        tempDay.splice(indexxx, 1);
                                        temp[index].day = tempDay;
                                        this.setState({
                                          markupPrice: [...temp],
                                        });
                                      }}
                                    />
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div>
                            <InputField
                              type="date"
                              isLabel={true}
                              lable="date"
                              placeholder="date"
                              value={""}
                              onChange={(e: any) => {
                                let temp: any = this.state.markupPrice;
                                temp[index].date.push(e.target.value);
                                temp[index].dateVal = "";
                                this.setState({ markupPrice: [...temp] });
                              }}
                            />
                            <ul
                              style={{
                                listStyle: "none",
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                                marginTop: "5px",
                              }}
                            >
                              {value.date.length !== 0 &&
                                value.date.map((d: any, indexx: any) => (
                                  <li
                                    key={indexx}
                                    style={{
                                      background: "#eff5fc",
                                      color: "#36619b",
                                      width: "fit-content",
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {d}{" "}
                                    <FontAwesomeIcon
                                      icon={faClose}
                                      color="#36619b"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        let temp: any = this.state.markupPrice;
                                        let tempDate: any = temp[index].date;
                                        tempDate.splice(indexx, 1);
                                        temp[index].date = tempDate;
                                        this.setState({
                                          markupPrice: [...temp],
                                        });
                                      }}
                                    />
                                  </li>
                                ))}
                            </ul>
                          </div>

                          <div
                            className={styles.remove_variant}
                            onClick={() => {
                              let temp = this.state.markupPrice;
                              temp.splice(index, 1);
                              this.setState({ markupPrice: [...temp] });
                            }}
                          >
                            <FontAwesomeIcon icon={faClose} color="white" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* operation Hours */}
              <div className={styles.card}>
                <h3 style={{ whiteSpace: "nowrap", gridColumn: "1/5" }}>
                  Operation Hours{" "}
                  <span onClick={this.addOperationHoursObj}>+</span>
                </h3>

                <div className={styles.operation_hours_container}>
                  {this.state.operationHours.map(
                    (value: any, index: number) => {
                      return (
                        <div className={styles.operation_hour_card} key={index}>
                          <InputField
                            type="select"
                            isLabel={true}
                            lable="Day"
                            value={value.day}
                            options={[
                              { title: "Monday", value: "monday" },
                              { title: "Tuesday", value: "tuesday" },
                              { title: "Wednesday", value: "wednesday" },
                              { title: "Thursday", value: "thursday" },
                              { title: "Friday", value: "friday" },
                              { title: "Saturday", value: "saturday" },
                              { title: "Sunday", value: "sunday" },
                            ]}
                            accessText={"title"}
                            accessValue={"value"}
                            onChange={(e: any) => {
                              let temp: any = this.state.operationHours;
                              temp[index].day = e.target.value;
                              this.setState({ operationHours: [...temp] });
                            }}
                          />
                          <InputField
                            type="time"
                            isLabel={true}
                            lable="Start Time"
                            value={value.startTime}
                            onChange={(e: any) => {
                              let temp: any = this.state.operationHours;
                              temp[index].startTime = e.target.value;
                              this.setState({ operationHours: [...temp] });
                            }}
                          />
                          <InputField
                            type="time"
                            isLabel={true}
                            lable="End Time"
                            value={value.endTime}
                            onChange={(e: any) => {
                              let temp: any = this.state.operationHours;
                              temp[index].endTime = e.target.value;
                              this.setState({ operationHours: [...temp] });
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            color="white"
                            onClick={() => {
                              let temp = this.state.operationHours;
                              temp.splice(index, 1);
                              this.setState({ operationHours: [...temp] });
                            }}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              {/* inventory */}
              <div className={styles.card}>
                <h3 style={{ whiteSpace: "nowrap", gridColumn: "1/5" }}>
                  Inventory <span onClick={this.addInventoryObj}>+</span>
                </h3>
                <div className={styles.inventory_container}>
                  {this.state.inventory.map((value: any, index: number) => {
                    return (
                      <div className={styles.inventory_card} key={index}>
                        <InputField
                          type="text"
                          isLabel={true}
                          lable="Title"
                          placeholder="Service inventory title"
                          value={value.title}
                          onChange={(e: any) => {
                            let temp: any = this.state.inventory;
                            temp[index].title = e.target.value;
                            this.setState({ inventory: [...temp] });
                          }}
                        />
                        <InputField
                          type="date"
                          isLabel={true}
                          lable="Date"
                          placeholder="Service inventory date"
                          value={value.date}
                          onChange={(e: any) => {
                            let temp: any = this.state.inventory;
                            temp[index].date = e.target.value;
                            this.setState({ inventory: [...temp] });
                          }}
                        />
                        <InputField
                          type="text"
                          isLabel={true}
                          lable="Location"
                          placeholder="Service location"
                          value={value.location}
                          onChange={(e: any) => {
                            let temp: any = this.state.inventory;
                            temp[index].location = e.target.value;
                            this.setState({ inventory: [...temp] });
                          }}
                        />
                        <InputField
                          type="text"
                          isLabel={true}
                          lable="Sub Location"
                          placeholder="Service sub-location"
                          value={value.subLocation}
                          onChange={(e: any) => {
                            let temp: any = this.state.inventory;
                            temp[index].subLocation = e.target.value;
                            this.setState({ inventory: [...temp] });
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faClose}
                          color="white"
                          onClick={() => {
                            let temp = this.state.inventory;
                            temp.splice(index, 1);
                            this.setState({ inventory: [...temp] });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* SE Listing */}
              <div className={styles.card}>
                <h3 style={{ whiteSpace: "nowrap", gridColumn: "1/5" }}>
                  Search Engine Listing
                </h3>
                <div
                  style={{
                    gridColumn: "1/5",
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: "20px",
                  }}
                >
                  <InputField
                    type="text"
                    isLabel={true}
                    lable="Title"
                    placeholder="Enter page title"
                    value={""}
                    onChange={() => {}}
                  />
                  <InputField
                    type="text"
                    isLabel={true}
                    lable="Route Handle"
                    placeholder="Enter route handle"
                    value={""}
                    onChange={() => {}}
                  />
                  <InputField
                    type="textarea"
                    isLabel={true}
                    lable="Meta Description"
                    placeholder="Enter meta description"
                    value={""}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>
          </ContentBody>
        </Content>
      </>
    );
  }
}

export default withRouter(AddUpdateServices);

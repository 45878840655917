import { useState } from "react";
import Content from "../../../Components/Content/Content";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import styles from "./importLeads.module.css";
import { ReactComponent as RightIcon } from "../../../Assets/icons/right.svg";
import { ReactComponent as ImportIcon } from "../../../Assets/icons/import.svg";
import { ReactComponent as LeftIcon } from "../../../Assets/icons/left.svg";
import Info from "../../../Components/info";
import InputField from "../../../Components/InputField";
import ContactConfiguration from "../../../Components/Leads/ImportLeads/contactConfiguration";
import { parseCSVOrTSV, parseExcel } from "../../../Utils/excelFunctions";
import ContactMapFields from "../../../Components/Leads/ImportLeads/contactMapFields";
import ContactPreview from "../../../Components/Leads/ImportLeads/contactPreview";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ImportLeads = () => {
  const [stage, setStage] = useState("configuration");
  const [files, setFiles] = useState([]);
  const [encoding, setEncoding] = useState("utf-8");
  const [delimiter, setDelimiter] = useState("comma");
  const [tsvDelimiter, setTsvDelimiter] = useState("tab");
  const [processing, setProcessing] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [assignedFields, setAssignedFields] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    personalEmail: "",
    cooperateEmail: "",
    mobileNumber: "",
    altMobileNumber: "",
    contactOwner: "",
    companyName: "",
    companyWebsite: "",
    companyHQ: "",
    status: "",
    reason1: "",
    reason2: "",
    message: "",
    country: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    remarks: "",
  });
  const [data, setData]: any = useState([]);
  const [ready, setReady]: any = useState([]);
  const [skipped, setSkipped]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicate, setDuplicate] = useState("skip");
  const navigate = useNavigate();

  const handleUpload = () => {
    setLoading(true);

    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}leads/bulkUpload?identifier=` +
        localStorage.getItem("identifier") +
        "&duplicate=" +
        duplicate,
      method: "post",
      data: ready,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        toast.success("Uploaded Successfully.");
        navigate("/contacts");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong.");
      });
  };

  const handleNext = () => {
    if (stage === "configuration") {
      setProcessing(true);
      processFiles();
      setProcessing(false);
      setStage("map_fields");
    } else if (stage === "map_fields") {
      setProcessing(true);
      const temp_ready: any = [];
      const temp_skipped: any = [];
      const new_data = data.map((v: any) => {
        return {
          firstName: assignedFields?.firstName
            ? v[assignedFields.firstName] || ""
            : "",
          middleName: assignedFields?.middleName
            ? v[assignedFields.middleName] || ""
            : "",
          lastName: assignedFields?.lastName
            ? v[assignedFields.lastName] || ""
            : "",
          personalEmail: assignedFields?.personalEmail
            ? v[assignedFields.personalEmail] || ""
            : "",
          cooperateEmail: assignedFields?.cooperateEmail
            ? v[assignedFields.cooperateEmail] || ""
            : "",
          mobileNumber: assignedFields?.mobileNumber
            ? v[assignedFields.mobileNumber] || ""
            : "",
          altMobileNumber: assignedFields?.altMobileNumber
            ? v[assignedFields.altMobileNumber] || ""
            : "",
          contactOwner: assignedFields?.contactOwner
            ? v[assignedFields.contactOwner] || ""
            : "",
          companyName: assignedFields?.companyName
            ? v[assignedFields.companyName] || ""
            : "",
          companyWebsite: assignedFields?.companyWebsite
            ? v[assignedFields.companyWebsite] || ""
            : "",
          companyHQ: assignedFields?.companyHQ
            ? v[assignedFields.companyHQ] || ""
            : "",
          status: assignedFields?.status
            ? v[assignedFields.status]?.toLowerCase() || ""
            : "",
          reason1: assignedFields?.reason1
            ? v[assignedFields.reason1] || ""
            : "",
          reason2: assignedFields?.reason2
            ? v[assignedFields.reason2] || ""
            : "",
          message: assignedFields?.message
            ? v[assignedFields.message] || ""
            : "",
          country: assignedFields?.country
            ? v[assignedFields.country] || ""
            : "",
          state: assignedFields?.state ? v[assignedFields.state] || "" : "",
          city: assignedFields?.city ? v[assignedFields.city] || "" : "",
          address: assignedFields?.address
            ? v[assignedFields.address] || ""
            : "",
          pincode: assignedFields?.pincode
            ? v[assignedFields.pincode] || ""
            : "",
          remarks: assignedFields?.remarks
            ? v[assignedFields.remarks] || ""
            : "",
        };
      });

      new_data.forEach((v: any) => {
        if (!v?.personalEmail) {
          temp_skipped.push(v);
        } else if (!isValidEmail(v?.personalEmail)) {
          temp_skipped.push(v);
        } else if (v?.status && !leads.includes(v?.status)) {
          temp_skipped.push(v);
        } else {
          temp_ready.push(v);
        }
      });

      setReady(temp_ready);
      setSkipped(temp_skipped);

      setStage("preview");
      setProcessing(false);
    } else if (stage === "preview") {
      handleUpload();
    }
  };

  const handlePrevious = () => {
    if (stage === "map_fields") {
      setStage("configuration");
    } else if (stage === "preview") {
      setStage("map_fields");
    }
  };

  const processFiles = async () => {
    try {
      const allData = await Promise.all(
        files.map(async (file: File) => {
          if (
            file.type.includes("csv") ||
            file.type.includes("tsv") ||
            file.type.includes("text")
          ) {
            return await parseCSVOrTSV(file, encoding, delimiter, tsvDelimiter);
          } else if (
            file.type.includes("xls") ||
            file.type.includes("spreadsheet")
          ) {
            return await parseExcel(file, encoding);
          }
          return []; // Return an empty array for unsupported file types
        })
      );

      let combinedData = allData.flat(); // Combine all arrays into a single array

      // Remove duplicates where all fields are identical
      combinedData = combinedData.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
      );

      setData(combinedData);

      let keys: any = [];
      combinedData.forEach((d: any) => {
        keys.push(...Object.keys(d));
      });

      keys = Array.from(new Set(keys));

      const temp: any = { ...assignedFields };

      keys.forEach((k: any) => {
        if (temp.hasOwnProperty(k)) {
          temp[k] = k;
        }
      });
      setHeaders(keys);
      setAssignedFields(temp);
    } catch (error) {
      console.error("Error processing files:", error);
    }
  };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,.3)",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="loader"></span>
        </div>
      )}
      <Content>
        <ContentBody
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            padding: 0,
          }}
        >
          <div style={{ flex: 1 }}>
            <div className={styles.heading}>Import Contacts</div>
            <div className={styles.progress_container}>
              <div
                className={`${styles.progress_state} ${styles.active_progress_state}`}
              >
                <div>1</div>
                <p>Configuration</p>
              </div>
              <div className={styles.progress_between}></div>
              <div
                className={`${styles.progress_state} ${
                  (stage === "map_fields" || stage === "preview") &&
                  styles.active_progress_state
                }`}
              >
                <div>2</div>
                <p>Map Fields</p>
              </div>
              <div className={styles.progress_between}></div>
              <div
                className={`${styles.progress_state} ${
                  stage === "preview" && styles.active_progress_state
                }`}
              >
                <div>3</div>
                <p>Preview</p>
              </div>
            </div>
            {stage === "configuration" && (
              <ContactConfiguration
                files={files}
                setFiles={setFiles}
                encoding={encoding}
                setEncoding={setEncoding}
                setDelimiter={setDelimiter}
                delimiter={delimiter}
                tsvDelimiter={tsvDelimiter}
                setTsvDelimiter={setTsvDelimiter}
                duplicate={duplicate}
                setDuplicate={setDuplicate}
              />
            )}
            {stage === "map_fields" && (
              <ContactMapFields
                files={files}
                assignedFields={assignedFields}
                setAssignedFields={setAssignedFields}
                headers={headers}
              />
            )}
            {stage === "preview" && (
              <ContactPreview
                headers={headers}
                assignedFields={assignedFields}
                ready={ready}
                skipped={skipped}
                duplicate={duplicate}
              />
            )}
          </div>
          <div className={styles.footer_actions}>
            <div style={{ display: "flex", gap: "10px" }}>
              {stage !== "configuration" && (
                <button onClick={handlePrevious}>
                  <LeftIcon />
                  Previous
                </button>
              )}
              <button
                className={styles.blue_btn}
                style={
                  files.length === 0 && stage === "configuration"
                    ? { opacity: ".5", cursor: "not-allowed" }
                    : {}
                }
                onClick={() => {
                  if (files.length === 0 && stage === "configuration") {
                  } else handleNext();
                }}
              >
                {stage === "preview" ? "Import" : "Next"} <RightIcon />
              </button>
            </div>
            <button onClick={() => navigate("/contacts")}>Cancel</button>
          </div>
        </ContentBody>
      </Content>
    </>
  );
};

function isValidEmail(email: any) {
  // Regular expression for validating an email address
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regular expression
  return emailRegex.test(email);
}

const leads = [
  "new",
  "mql",
  "sql",
  "open",
  "working",
  "nurture",
  "unqualified",
  "opportunity",
  "won",
  "lost",
  "in_progress",
  "open_deal",
  "attempted_to_contact",
  "connected",
  "bad_timing",
  "unassigned",
  "complete",
];

export default ImportLeads;

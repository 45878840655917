import { useNavigate } from "react-router-dom";
import Content from "../../Components/Content/Content";
import ContentBody from "../../Components/Content/ContentBody";
import ContentHead from "../../Components/Content/ContentHead";
import WithTableView from "../../GenericPages/WithTableView";
import { category_body_keys, category_head } from "../../Utils/Data";
import { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import toast from "react-hot-toast";
import Modal from "../../Components/Modal";
import TableLoading from "../../Components/LoadingUi/TableLoading";
import DraggableTable from "../../Components/DraggableTable";
import { useAdminContext } from "../../Store/adminContext";
import ContentOuterHead from "../../Components/Content/contentOuterHead";

let inFocus = "";

const Categories = () => {
  const navigate = useNavigate();
  const [data, setData]: any = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);

  const { admin }: any = useAdminContext();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      method: "GET",
      url: `${
        process.env.REACT_APP_BASE_URL
      }productcategory/?identifier=${localStorage.getItem(
        "identifier"
      )}&limit=10000`,
    })
      .then(async (res: any) => {
        const data = res.data.productCategorys;

        let found = false;

        // check if data have priority value
        for (let i = 0; i < data.length; i++) {
          if (data[i].priority === undefined) {
            found = true;
            // add priority
            const _ = await axios({
              method: "PATCH",
              url:
                `${process.env.REACT_APP_BASE_URL}productcategory/` +
                data[i]._id,
              data: { ...data[i], priority: i },
            });
          }
        }

        if (found) getData();

        setData(
          data
            .map((val: any) => ({ ...val, id: val._id }))
            .sort((a: any, b: any) => a.priority - b.priority)
        );

        setLoading(false);
      })
      .catch((error: any) => {
        toast.error("Something went wrong.");
        setLoading(false);
        setData([]);
      });
  };

  const handleAction = () => {
    setModal(false);

    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}productcategory/` + inFocus,
          method: "delete",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then((res: any) => {
            inFocus = "";
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        loading: "Deleting...",
        success: (res: any) => {
          getData();
          return "Deleted Successfully!";
        },
        error: (err: any) => {
          return "Error in deleting the product.";
        },
      }
    );
  };

  const handleSwap = (id1: any, id2: any, priority1: any, priority2: any) => {
    let temp: any = [...data];
    let newIndex1 = temp.findIndex((val: any) => val.id === id1);
    let newIndex2 = temp.findIndex((val: any) => val.id === id2);

    setIndex1(newIndex1);
    setIndex2(newIndex2);

    if (priority1 > priority2) {
      temp[newIndex1].priority = priority2;
      for (let i = newIndex2; i < newIndex1; i++) {
        temp[i].priority = temp[i].priority + 1;
      }
    }

    if (priority2 > priority1) {
      temp[newIndex1].priority = priority2;
      for (let i = newIndex1 + 1; i < newIndex2 + 1; i++) {
        temp[i].priority = temp[i].priority - 1;
      }
    }

    setData(temp.sort((a: any, b: any) => a.priority - b.priority));
    setLoading(true);
    updateForSwap();
  };

  const updateForSwap = async () => {
    if (index1 > index2) {
      for (let i = index2; i < index1 + 1; i++) {
        await axios({
          method: "patch",
          data: { priority: data[i]?.priority },
          url:
            `${process.env.REACT_APP_BASE_URL}productcategory/` + data[i]?.id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        });
      }
    }

    if (index2 > index1) {
      for (let i = index1; i < index2 + 1; i++) {
        await axios({
          url:
            `${process.env.REACT_APP_BASE_URL}productcategory/` + data[i]?.id,
          data: { priority: data[i].priority },
          method: "patch",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        });
      }
    }

    getData();
  };

  return (
    <>
      <Modal
        title={"Delete"}
        description={
          "Do you really want to delete this record? This process cannot be undone."
        }
        state={modal}
        onBtn1Click={() => {
          inFocus = "";
          setModal(false);
        }}
        onBtn2Click={handleAction}
      />
      <Content>
        <ContentHead
          title="Categories"
          showBtn1={isUserAllowedToEdit(admin)}
          btn1Name="btn1"
          btn1Text={"Add Category"}
          btn1type="filled"
          onClickHandler={() => navigate("/categories/add")}
        />
        <ContentBody style={{ padding: 0 }}>
          {loading ? (
            <TableLoading head={category_head} />
          ) : (
            <DraggableTable
              head={["S.No.", "Name", ""]}
              body={data}
              body_keys={categoryKeys(admin)}
              onSwap={handleSwap}
              onDelete={(val: any, id: any) => {
                inFocus = id;
                setModal(true);
              }}
              onEdit={(id: any) => {
                navigate("/categories/update/" + id);
              }}
              pageNo={0}
            />
          )}
        </ContentBody>
      </Content>
    </>
  );
};

const isUserAllowedToEdit = (admin: any) => {
  if (admin.role !== "subadmin") return true;

  if (admin.role === "subadmin" && admin.catalog_access.write) return true;

  return false;
};

const categoryKeys = (admin: any) => {
  const base_keys = ["sno", "title"];

  if (admin.role !== "subadmin") return [...base_keys, "action"];

  if (admin.role === "subadmin") {
    if (admin.catalog_access.delete) {
      return [...base_keys, "action"];
    }
    if (admin.catalog_access.write) {
      return [...base_keys, "write"];
    }
  }

  return [...base_keys, "show"];
};

export default Categories;

import React, { useEffect, useState } from "react";
import styles from "../../../Styles/browseMedia.module.css";
import ContentHead from "../../../Components/Content/ContentHead";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Content from "../../../Components/Content/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentBody from "../../../Components/Content/ContentBody";
import { IoGrid } from "react-icons/io5";
import { PiListBold } from "react-icons/pi";
import { MdKeyboardArrowDown, MdOutlineCheck } from "react-icons/md";
import { GoCopy } from "react-icons/go";
import { getAllFiles } from "../../../Apis/callApi";
import { toast } from "react-hot-toast";
import { GoPlusCircle } from "react-icons/go";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";
import { Tooltip } from "../../../Components/ui/tooltip/tooltip";

const BrowseMedia = () => {
  const [viewType, setViewType] = useState("grid");
  const [dropdownOpen, setDropdownOpen] = useState<any>({
    type: false,
    modified: false,
    customDate: false,
  });
  const [products, setProducts] = useState<any>([]);
  const [media, setMedia] = useState<any>([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedPeople, setSelectedPeople] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [customDate, setCustomDate] = useState({ from: "", to: "" });
  const [isProductModal, setIsProductModal] = useState(false);

  const toggleDropdown = (dropdown: any) => {
    setDropdownOpen((prev: any) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const closeDropdowns = () => {
    setDropdownOpen({
      type: false,
      people: false,
      modified: false,
      customDate: false,
    });
  };

  const handleTypeChange = (type: any) => {
    setSelectedType(type);
    closeDropdowns();
  };

  const handlePeopleChange = (people: any) => {
    setSelectedPeople(people);
    closeDropdowns();
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    if (date === "Custom date range") {
      toggleDropdown("customDate");
    } else {
      closeDropdowns();
    }
  };

  const isSelected = (value: any) => !!value;

  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  const toggleImageSelection = (url: string) => {
    setSelectedImages(
      (prevSelected) =>
        prevSelected.includes(url)
          ? prevSelected.filter((image) => image !== url) // Deselect if already selected
          : [...prevSelected, url] // Select if not already selected
    );
  };

  const exportToCSV = () => {
    const csvData = selectedImages?.map((img) => ({
      Name: "Image",
      URL: img,
    }));
    const csvContent =
      "data:text/csv;charset=utf-8," +
      ["Name,URL"]
        .concat(csvData.map((row) => `${row.Name},${row.URL}`))
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "image_urls.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    getAllMedia();
  }, []);

  const getAllMedia = async () => {
    try {
      const res = await getAllFiles();

      const mediaWithCopiedField = res.data.images.map((image: any) => ({
        ...image,
        copied: false,
      }));
      setMedia(mediaWithCopiedField);
    } catch (error) {
      toast.error("Error Fetching Files");
    }
  };

  const handleCopy = (id: number, url: string) => {
    navigator.clipboard.writeText(url).then(() => {
      setMedia((prev: any) =>
        prev.map((img: any, key: any) =>
          key === id ? { ...img, copied: true } : img
        )
      );
    });
  };

  const handleOpenProductModal = () => {
    setIsProductModal(!isProductModal);
  };
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}products/search/` +
        localStorage.getItem("identifier") +
        `?limit=${1000}&text=${searchData}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        //  setLoading(false);
        setProducts(res.data.data);
      })
      .catch(() => {
        //  setLoading(false);
      });
  };

  const handleUpdateProduct = (id: any, media: any) => {
    axios({
      method: "patch",
      url: `${process.env.REACT_APP_BASE_URL}products/` + id,
      data: {
        mediaUrl: [...media, ...selectedImages],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        toast.success("Product updated successfully.");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <Content>
        <ContentHead
          title={"Browse Media"}
          isMoreSettings={true}
          moreSettingsList={[
            { icon: "import", title: "Export ", name: "import" },
          ]}
          handleMoreClick={(name: any) => {
            exportToCSV();
          }}
          customElement={
            <div className={styles.topBtn}>
              {selectedImages?.length > 0 && (
                <Tooltip
                  content="This will add image to any Product"
                  side="bottom"
                >
                  <button
                    className={`${styles.button} ${styles.primary}`}
                    onClick={() => setIsProductModal(true)}
                  >
                    Link to Product
                  </button>
                </Tooltip>
              )}

              <div className={styles.gridButton}>
                <Tooltip content="List" side="bottom">
                  <button
                    className={styles.list}
                    onClick={() => setViewType("list")}
                    style={{
                      backgroundColor:
                        viewType === "list" ? "rgba(57,126,230,0.5)" : "",
                    }}
                  >
                    {" "}
                    <PiListBold />{" "}
                  </button>
                </Tooltip>

                <Tooltip content="Grid" side="bottom">
                  <button
                    className={styles.grid}
                    onClick={() => setViewType("grid")}
                    style={{
                      backgroundColor:
                        viewType === "grid" ? "rgba(57,126,230,0.5)" : "",
                    }}
                  >
                    <IoGrid />{" "}
                  </button>
                </Tooltip>
              </div>
            </div>
          }
        />
        <ContentBody>
          <div>
            {/* <div
              className={styles.filterContainer}
              onClick={(e) => e.stopPropagation()}
            >
              <div className={styles.filterGroup}>
               
                <div className={styles.dropdown}>
                  <button
                    className={styles.filterButton}
                    onClick={() => toggleDropdown("type")}
                  >
                    Type
                    {isSelected(selectedType) && (
                      <span className={styles.redDot} />
                    )}
                    <span className={styles.arrowDown}>
                      <MdKeyboardArrowDown size={18} />{" "}
                    </span>
                  </button>
                  {dropdownOpen.type && (
                    <div className={styles.dropdownContent}>
                      <div onClick={() => handleTypeChange("Image")}>Image</div>
                      <div onClick={() => handleTypeChange("Video")}>Video</div>
                      <div onClick={() => handleTypeChange("Audio")}>Audio</div>
                    </div>
                  )}
                </div>

               
                <div className={styles.dropdown}>
                  <button
                    className={styles.filterButton}
                    onClick={() => toggleDropdown("modified")}
                  >
                    Modified
                    {isSelected(selectedDate) && (
                      <span className={styles.redDot} />
                    )}
                    <span className={styles.arrowDown}>
                      <MdKeyboardArrowDown size={18} />{" "}
                    </span>
                  </button>
                  {dropdownOpen.modified && (
                    <div className={styles.dropdownContent}>
                      <div onClick={() => handleDateChange("Today")}>Today</div>
                      <div onClick={() => handleDateChange("Last 7 days")}>
                        Last 7 days
                      </div>
                      <div onClick={() => handleDateChange("Last 30 days")}>
                        Last 30 days
                      </div>
                      <div onClick={() => handleDateChange("This year")}>
                        This year (2024)
                      </div>
                      <div onClick={() => handleDateChange("Last year")}>
                        Last year (2023)
                      </div>
                      <div
                        onClick={() => handleDateChange("Custom date range")}
                      >
                        Custom date range
                        {dropdownOpen.customDate && (
                          <div
                            className={styles.customDateDropdown}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <input
                              type="date"
                              value={customDate.from}
                              onChange={(e) =>
                                setCustomDate({
                                  ...customDate,
                                  from: e.target.value,
                                })
                              }
                              placeholder="From"
                            />
                            <input
                              type="date"
                              value={customDate.to}
                              onChange={(e) =>
                                setCustomDate({
                                  ...customDate,
                                  to: e.target.value,
                                })
                              }
                              placeholder="To"
                            />
                            <div className={styles.btnGroup}>
                              <button>Cancel</button>
                              <button>Apply</button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
            {viewType === "grid" ? (
              <div className={styles.imageGrid}>
                {media?.map((image: any, id: any) => (
                  <div
                    key={image.url}
                    className={`${styles.imageItem} ${
                      selectedImages.includes(image.url) ? styles.selected : ""
                    }`}
                    onClick={() => toggleImageSelection(image.url)}
                    style={{
                      width: "100%",
                      height: "180px",
                      padding: "5px",
                      backgroundColor: selectedImages.includes(image.url)
                        ? "rgb(57, 126, 230,0.6)"
                        : "#e0dede",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "17%",
                        cursor: "pointer",
                        paddingInline: "5px",
                      }}
                    >
                      <p style={{ width: "70%", fontSize: "0.75rem" }}>
                        {image.key.split("/")[1]}
                      </p>
                      {/* <span
                        onClick={() => {
                          setIsProductModal(true);
                          setSelectedUrl(image.url);
                        }}
                      >
                        <GoPlusCircle />
                      </span> */}
                      <span
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleCopy(id, image.url);
                        }}
                      >
                        {image.copied ? (
                          <Tooltip content="Copied" side="left">
                            {" "}
                            <MdOutlineCheck />{" "}
                          </Tooltip>
                        ) : (
                          <Tooltip content="Copy" side="left">
                            <GoCopy />
                          </Tooltip>
                        )}
                      </span>
                    </div>
                    <img
                      src={image.url}
                      loading="lazy"
                      alt="..."
                      style={{
                        objectFit: "cover",
                        height: "83%",
                        width: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.imageList}>
                {media?.map((image: any, id: any) => (
                  <div
                    key={image.url}
                    className={`${styles.imageCard} ${
                      selectedImages.includes(image.url) ? styles.selected : ""
                    }`}
                    onClick={() => toggleImageSelection(image.url)}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 2fr",
                      alignItems: "center",
                      padding: "6px 12px",

                      backgroundColor: selectedImages.includes(image.url)
                        ? "rgb(57, 126, 230,0.6)"
                        : "#e0dede", // Background color for selected image

                      borderRadius: "8px", // Rounded corners
                    }}
                  >
                    <img
                      src={image.url}
                      alt="..."
                      loading="lazy"
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "10px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                    <span className={styles.imageName}>
                      {image.key.split("/")[1]}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        position: "absolute",
                        right: "20px",
                        cursor: "pointer",
                      }}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleCopy(id, image.url);
                      }}
                    >
                      {image.copied ? (
                        <Tooltip content="Copied" side="top">
                          {" "}
                          <MdOutlineCheck />{" "}
                        </Tooltip>
                      ) : (
                        <Tooltip content="Copy" side="top">
                          <GoCopy />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ContentBody>
      </Content>

      {isProductModal && (
        <>
          <div className={styles.modalOverlay} onClick={handleOpenProductModal}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Link Your Image</h2>
              <div style={{ display: "flex", gap: "10px" }}>
                <input
                  type="search"
                  placeholder="Search products..."
                  value={searchData}
                  onChange={(e) => setSearchData(e.target.value)}
                  className={styles.searchBar}
                />
                <button className={styles.searchBtn} onClick={getData}>
                  Search
                </button>
                <span
                  onClick={handleOpenProductModal}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    // backgroundColor: "white",
                    borderRadius: "50%",
                    zIndex: "99999900",
                  }}
                >
                  <RxCross2 size={25} />
                </span>
              </div>
              <div className={styles.productList}>
                {products?.map((product: any, index: any) => (
                  <div key={index} className={styles.productItem}>
                    <span>
                      {product.title} (SKU: {product.inventory.sku})
                    </span>
                    <Tooltip content="Add image to this Product" side="top">
                      <button
                        onClick={() => {
                          handleUpdateProduct(product._id, product.mediaUrl);
                        }}
                      >
                        Add
                      </button>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BrowseMedia;

import { useEffect, useState } from "react";
import WithTableView from "../../../GenericPages/WithTableView";
import { order_body_keys, orders_table_head } from "../../../Utils/Data";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../Components/Table/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const ServiceOrders = () => {
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [sort, setSort] = useState("increasing");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [pageNo, sort]);

  const getData = () => {
    axios({
      url:
        `${process.env.REACT_APP_BASE_URL}orders/getallorders/?identifier=` +
        localStorage.getItem("identifier") +
        "&page=" +
        pageNo,
      method: "get",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
    })
      .then((res) => {
        setData(
          res.data.orders.map((val: any) => {
            return {
              ...val,
              // capatilize
              location:
                val.location.charAt(0).toUpperCase() + val.location.slice(1),
              subLocation:
                val.subLocation.charAt(0).toUpperCase() +
                val.subLocation.slice(1),
              category:
                val.category.charAt(0).toUpperCase() + val.category.slice(1),
              subCategory: val.subCategory
                ? val.subCategory.charAt(0).toUpperCase() +
                  val.subCategory.slice(1)
                : "",
              fulfilmentStatus:
                val.fulfilmentStatus.charAt(0).toUpperCase() +
                val.fulfilmentStatus.slice(1),
              date: `${val.date
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-")}  ${
                val.startTime && val.endTime
                  ? `(${val.startTime
                      .split("T")[1]
                      .split(":")
                      .splice(0, 2)
                      .join(":")} - ${val.endTime
                      .split("T")[1]
                      .split(":")
                      .splice(0, 2)
                      .join(":")})`
                  : ""
              }`,
              paymentStatus:
                val.paymentStatus === "unpaid"
                  ? "Un-Paid"
                  : val.paymentStatus === "pending"
                  ? "Pending"
                  : val.paymentStatus === "partial"
                  ? "Partial"
                  : val.paymentStatus === "failed"
                  ? "Failed"
                  : val.paymentStatus,

              modeOfPayment:
                val.modeOfPayment === "pg" ? "Online" : val.modeOfPayment,

              customer_name: val?.billingAddress?.name,
            };
          })
        );
        setTotal(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        setData([]);

        setLoading(false);
      });
  };

  const onHeadClick = (val: any) => {
    setSort((val) => (val === "increasing" ? "decreasing" : "increasing"));
  };

  return (
    <>
      <WithTableView
        loading={loading}
        show_btn_1={true}
        btn1Text={
          <div>
            <FontAwesomeIcon icon={faPlus} color="white" /> Create Order
          </div>
        }
        btn1Type="filled"
        heading="Orders"
        head={[
          { title: "S.No." },
          { title: "Order ID" },
          { title: "Customer Name" },
          { title: "Booking Date" },
          { title: "Location" },
          { title: "Sub Location" },
          { title: "Category" },
          { title: "Sub Category" },
          { title: "Mode Of Payment", filter: true },
          { title: "Payment Status", filter: true },
          { title: "Order Status" },
          // { title: "Payment Method" },
          // { title: "Payment ID" },
        ]}
        body={data.map((val: any) => ({
          ...val,
          fulfilmentStatus:
            val.fulfilmentStatus === "cancelled"
              ? `cancelled ${
                  val.modeOfPayment === "cod" ? "" : "(refund requested)"
                }`
              : val.fulfilmentStatus,
          modeOfPayment:
            val.modeOfPayment === "cod" || val.modeOfPayment === ""
              ? "Pay at Venue"
              : val.modeOfPayment,
        }))}
        body_keys={order_body_keys}
        onRowClick={(val: any) => {
          navigate("/orders/details/" + val._id);
        }}
        onActionClick={() => {}}
        onClickHandler={() => {
          navigate("/orders/add");
        }}
        customElement={
          <Pagination
            pageNo={pageNo}
            changePage={(val: any) => {
              setPageNo(val);
            }}
            total={total}
            showDrop={false}
          />
        }
        page={pageNo}
        showIconAt={"Booked for"}
        icon={sort === "increasing" ? "↓" : "↑"}
        onHeadClick={onHeadClick}
      />
    </>
  );
};

export default ServiceOrders;

import { useNavigate } from "react-router-dom";
import Content from "../../../Components/Content/Content";
import ContentBody from "../../../Components/Content/ContentBody";
import ContentHead from "../../../Components/Content/ContentHead";
import styles from "./comingFromPage.module.css";
import { MdArrowBack } from "react-icons/md";
import { useEffect, useState } from "react";
import axios from "axios";
import SingleComingFrom from "../../../Components/Analytics/comingFrom/SingleComingFrom";

const url = process.env.REACT_APP_BASE_URL;

const ComingFromPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [comingFrom, setComingFrom]: any = useState(null);

  useEffect(() => {
    getComingFromData();
  }, []);

  const getComingFromData = () => {
    axios({
      url: `${url}analytics/comingFromData?identifier=${localStorage.getItem(
        "identifier"
      )}`,
      method: "get",
    })
      .then((res) => {
        const totalValue = Object.values(res.data.comingFrom).reduce(
          (sum, value) => Number(sum) + Number(value),
          0
        ) as number;

        const array = Object.keys(res.data.comingFrom).map((key) => ({
          label: key,
          value: Number(
            ((res.data.comingFrom[key] / totalValue) * 100).toFixed(2)
          ),
          session: res.data.comingFrom[key],
        }));
        const filteredArray = array.filter(
          (data) => !data.label.includes("www.pulpofficial.com")
        );
        setComingFrom([...filteredArray]);
        setLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <Content>
      <ContentHead
        title={
          <>
            <MdArrowBack
              size={22}
              onClick={() => navigate("/analytics")}
              style={{ cursor: "pointer" }}
            />
            Coming From
          </>
        }
      />
      <ContentBody>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        ) : (
          <>
            <div className={styles.container}>
              <div className={styles.heading}>
                <h5>Session Source</h5>
                <h5>Sessions</h5>
              </div>
              {comingFrom && (
                <>
                  <div className={styles.mainData}>
                    {comingFrom &&
                      comingFrom.map((data: any, key: any) => (
                        <SingleComingFrom
                          label={data.label}
                          count={data?.session}
                          key={key}
                        />
                      ))}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </ContentBody>
    </Content>
  );
};

export default ComingFromPage;

import React, { forwardRef, useEffect, useLayoutEffect, useState } from "react";
import styles from "./thermalInvoice.module.css";
import { numberToWords } from "../../../Utils/numberToWordsConverter";
import { useReactToPrint } from "react-to-print";

type taxesProps = {
  taxPercent?: Number | String;
  totalAmount?: Number | String;
  totalGSTAmount?: Number | String;
};

const ThermalInvoice = forwardRef((props: any, ref: any) => {
  const [height, setheight] = useState<number>(0);
  const { user, order, admin }: any = props;

  useLayoutEffect(() => {
    if (ref.current) {
      const invoice = ref.current;
      // Accessing the clientHeight after the component has mounted and layout has been calculated
      const heightInPixels = invoice.clientHeight;
      setheight(heightInPixels);
    }
  }, [order]);

  const totalQty = () => {
    const qty = order?.products?.reduce((acc: any, product: any) => {
      return acc + product.quantity;
    }, 0);
    return qty;
  };

  if (order.length === 0) {
    return <>Loading...</>;
  }

  const calculateTaxAmount = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ): string => {
    if (isInclusive) {
      return (subTotal - (subTotal / 1 + gst / 100)).toFixed(2);
    }
    // this will return the price after adding tax .
    return ((gst / 100) * subTotal).toFixed(2);
  };
  // will retrun only tax amount in all products.
  const totalIGSTAmount = () => {
    const totaligst = order?.products?.reduce((acc: any, product: any) => {
      const taxAmount = calculateTaxAmount(
        product?.productId?.pricing?.price,
        product?.productId?.pricing?.inclusiveOfGST,
        product?.productId.pricing?.igst
      );
      const taxAmountWithQuantity = Number(taxAmount) * product.quantity;
      return acc + taxAmountWithQuantity;
    }, 0);
    return totaligst;
  };

  // this function return the actual amount
  // * if tax is exclusive it will add the tax amount
  // * if inclusive it will remove the tax amount.
  const calculateAmt = (
    subTotal: number,
    isInclusive: boolean,
    gst: number
  ) => {
    // this will return the actual price of item after removing the tax.
    if (isInclusive) {
      return (subTotal * (100 / (100 + gst))).toFixed(2);
    }
    // this will return the price after adding tax .
    return (subTotal + (gst / 100) * subTotal).toFixed(2);
  };

  // It retrun an array of objects with each type of taxes . eg. 0% ,18%... with their amount and tax amount.
  const calculateTaxes = (): taxesProps[] | [] => {
    let temp: any[] = [];
    const taxData = order.products?.forEach((element: any) => {
      const isPresent = temp.findIndex(
        (val: any) => val?.taxPercent == element.productId?.pricing?.igst
      );
      if (isPresent == -1) {
        let obj: taxesProps = {};
        obj.taxPercent = element.productId?.pricing.igst;
        obj.totalAmount = element?.productId?.pricing.price * element.quantity;
        obj.totalGSTAmount =
          element.productId?.pricing?.igst == 0
            ? 0.0
            : Number(
                calculateTaxAmount(
                  element?.productId?.pricing.price,
                  element?.productId?.pricing?.inclusiveOfGST,
                  element?.productId?.pricing?.igst
                )
              ) * element.quantity;
        temp.push({ ...obj });
      } else {
        let obj = temp[isPresent];
        obj.totalAmount += element?.productId?.pricing.price * element.quantity;
        obj.totalGSTAmount +=
          element.productId.pricing.igst == 0
            ? 0.0
            : Number(
                calculateTaxAmount(
                  element?.productId?.pricing.price,
                  element?.productId?.pricing?.inclusiveOfGST,
                  element?.productId.pricing?.igst
                )
              ) * element.quantity;
        temp[isPresent] = obj;
      }
    });
    return temp;
  };

  const heightInMM = (height / 95) * 22;
  const taxesArray: taxesProps[] | any = calculateTaxes();

  return (
    <div className={styles.invoiceContainer} ref={ref} id="invoice">
      <style>{`@page { size:57mm ${heightInMM}mm;}`}</style>
      <h4>RETAIL INVOICE</h4>
      <h1 className={styles.title}>{admin.mainDomain}</h1>
      <div className={styles.header}>
        {admin?.address?.addLineOne !== "" && (
          <div>
            {admin?.address?.addLineOne}, {admin?.address?.addLineTwo},{" "}
            {admin?.address?.city}
            {admin.address?.companyStateZip}
          </div>
        )}
        <h5>Ph No : {admin?.mobileNumber?.value}</h5>
        {admin?.gst && <h5>GSTIN : {admin.gst}</h5>}
        {admin?.mainDomain && <p>Order on : {"www." + admin?.mainDomain}</p>}
      </div>
      <div className={styles.details_grid}>
        <div>
          Date: <span>{new Date().toLocaleDateString()}</span>
        </div>
        <div style={{ justifySelf: "end" }}>
          Time: <span>{new Date().toLocaleTimeString()}</span>
        </div>
        <div>
          Bill No:{" "}
          <span>
            <strong>{order.orderRefId}</strong>
          </span>
        </div>
        <div style={{ justifySelf: "end" }}>
          User: <span>ADMIN</span>
        </div>
      </div>

      <div className={styles.item_heading}>
        <h5>Item Name</h5>
        <h5>GST %</h5>
        <h5>Qty</h5>
        <h5>Rate</h5>
        <h5>Amt</h5>
      </div>

      <div className={styles.items}>
        {order?.products?.map((item: any, index: any) => (
          <div key={index} className={styles.item}>
            <div className={styles.itemTitle}>{item.productId?.title}</div>
            <div className={styles.itemDetails}>
              <span></span>
              <span> {item?.productId?.pricing?.igst}%</span>
              <span>{item?.quantity || "NA"}</span>
              <span>
                {" "}
                ₹{" "}
                {item?.productId?.pricing?.inclusiveOfGST
                  ? calculateAmt(
                      item?.productId?.pricing?.price,
                      item?.productId?.pricing?.inclusiveOfGST,
                      item?.productId?.pricing?.igst
                    )
                  : item?.productId?.pricing?.price}
              </span>

              <span>
                {" "}
                ₹{" "}
                {item?.quantity *
                  Number(
                    calculateAmt(
                      item?.productId?.pricing?.price,
                      item?.productId?.pricing?.inclusiveOfGST,
                      item?.productId?.pricing?.igst
                    )
                  )}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.total}>
        <div></div>
        <h5>Total</h5>
        <h5>{totalQty()}</h5>
        <div></div>
        <h5>₹{order.total}</h5>
      </div>
      <div className={styles.taxes}>
        {taxesArray?.map((val: any, indx: any) => {
          return (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                marginBlock: "3px",
              }}
              key={indx}
            >
              <p>Taxable @ {val.taxPercent}</p>
              <p>₹{val?.totalAmount}</p>
              <p>₹{val?.totalGSTAmount}</p>
            </div>
          );
        })}
      </div>

      <div className={styles.footer}>
        <p className={styles.totalWords}>
          In Words: {numberToWords(Number(order.total))}
        </p>
        <p>Thank you, Please Visit Again</p>
        <div className={styles.user}>
          <p>{order.billingAddress?.name}</p>
          <p>{order.billingAddress?.landmark}</p>
          <p>
            {order.billingAddress?.city + "," + order.billingAddress?.pincode}
          </p>
        </div>
        {/* <p>Better Quality Better Health</p> */}
      </div>
    </div>
  );
});

export default ThermalInvoice;

import { useEffect, useRef, useState } from "react";
import styles from "../../Pages/Leads/leads.module.css";
import { ReactComponent as DownIcon } from "../../Assets/icons/down.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../Components/Modal";
import InputField from "../../../Components/InputField";
import axios from "axios";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../Store/adminContext";

const SingleRow = ({
  data,
  changeSelect,
  disableForAction,
  updatedContacts,
  setUpdatedContacts,
  subAdmins,
  onRowClick,
  handleGenetrateLabel,
  handleOptionClick,
  getData,
}: any) => {
  const [modal, setModal] = useState(false);
  const { admin }: any = useAdminContext();
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [weight, setWeight] = useState("");
  const [loading, setLoading] = useState(false);
  const [noOfPackages, setNoOfPackages] = useState("");

  const SchedulePickup = () => {
    if (!length) {
      toast.error("Length is required.");
      return;
    }
    if (!width) {
      toast.error("Width is required.");
      return;
    }
    if (!height) {
      toast.error("Height is required.");
      return;
    }
    if (!weight) {
      toast.error("Weight is required.");
      return;
    }
    setLoading(true);

    axios({
      url:
        "https://backend.retaino.in/api/users/getuserbyid/" + data?.customerId,
      method: "get",
    })
      .then((r: any) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}orders/create-order-for-intargo`,
          method: "post",
          data: {
            orderId: data._id,
            customerName: data?.shippingAddress?.name,
            customerMobile: r.data.user?.mobile?.number,
            shipment_height: Number(height),
            shipment_length: Number(length),
            shipment_weight: Number(weight),
            shipment_width: Number(width),
            netWeight: Number(noOfPackages) * Number(weight),
            noOfPackages,
            identifier: localStorage.getItem("identifier"),
          },
        })
          .then(() => {
            toast.success("Successfully scheduled order for pickup.");
            setModal(false);
            setLoading(false);
            getData();
          })
          .catch((err: any) => {
            toast.error(err.response.data.message || "Something went wrong.");
            setLoading(false);
          });
      })
      .catch((err) => {
        toast.error("Not able to fetch user details.");
        setLoading(false);
      });
  };

  const cancelOrder = () => {
    toast.promise(
      new Promise((resolve, reject) => {
        axios({
          url: `${process.env.REACT_APP_BASE_URL}orders/product/` + data._id,
          method: "patch",
          data: {
            fulfilmentStatus: "cancelled",
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
          },
        })
          .then(() => {
            getData();
            resolve(true);
          })
          .catch(() => {
            reject(false);
          });
      }),
      {
        success: "Order Cancelled Successfully.",
        loading: "Canceling Order.",
        error: "Error while cancelling order.",
      }
    );
  };

  return (
    <>
      {modal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,.05)",
            zIndex: "10000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60vw",
              height: "fit-content",
              background: "white",
              borderRadius: "15px",
            }}
          >
            {/* Schedule Heading */}
            <div
              style={{
                padding: "10px",
                borderBottom: "1px solid lightgrey",
                fontSize: "1.1rem",
              }}
            >
              Schedule Pickup
            </div>
            <div
              style={{
                padding: "20px",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "20px",
              }}
            >
              <InputField
                isLabel={true}
                lable={"Package Length"}
                value={length}
                type="number"
                onChange={(e: any) => {
                  setLength(e.target.value);
                }}
              />
              <InputField
                isLabel={true}
                lable={"Package Width"}
                value={width}
                type="number"
                onChange={(e: any) => {
                  setWidth(e.target.value);
                }}
              />
              <InputField
                isLabel={true}
                lable={"Package Height"}
                value={height}
                type="number"
                onChange={(e: any) => {
                  setHeight(e.target.value);
                }}
              />
              <InputField
                isLabel={true}
                lable={"Package Weight"}
                value={weight}
                type="number"
                onChange={(e: any) => {
                  setWeight(e.target.value);
                }}
              />
              <InputField
                isLabel={true}
                lable={"Number of packages"}
                value={noOfPackages}
                type="number"
                onChange={(e: any) => {
                  setNoOfPackages(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                borderTop: "1px solid grey",
              }}
            >
              <button
                style={{
                  border: "none",
                  outline: "none",
                  background: "lightgrey",
                  padding: "10px 15px",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  border: "none",
                  outline: "none",
                  background: "var(--brand-color)",
                  padding: "10px 15px",
                  borderRadius: "10px",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={SchedulePickup}
              >
                Schedule
              </button>
            </div>
          </div>
        </div>
      )}
      <tr onClick={onRowClick}>
        <td>{data.orderRefId}</td>
        <td>{data.shippingAddress?.name || "--"}</td>
        <td>{data?.total || "--"}</td>
        <td>{data?.date}</td>
        <td>{data?.modeOfPayment == "cod" ? "COD" : data?.modeOfPayment}</td>

        <td>{data.paymentStatus || "--"}</td>
        <td>{data.fulfilmentStatus || "--"}</td>
        <td style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {Object.keys(data?.logisticsDetails || {}).length === 0 &&
            admin.integration.filter((v: any) => v.category === "logistics")
              .length !== 0 && (
              <button
                style={{
                  background: "var(--brand-color)",
                  color: "white",
                  fontSize: ".8rem",
                  padding: "3px",
                  outline: "none",
                  border: "none",
                  textAlign: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={(e: any) => {
                  e.stopPropagation();
                  setModal(true);
                }}
              >
                Schedule Pickup
              </button>
            )}
          <button
            style={{
              background: "lightgrey",
              color: "black",
              fontSize: ".8rem",
              padding: "3px",
              outline: "none",
              border: "none",
              textAlign: "center",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              handleGenetrateLabel();
            }}
          >
            Generate Label
          </button>
          <button
            style={{
              background: "lightgrey",
              color: "black",
              fontSize: ".8rem",
              padding: "3px",
              outline: "none",
              border: "none",
              textAlign: "center",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick();
            }}
          >
            Print Invoice{" "}
          </button>
          {data?.fulfilmentStatus !== "cancelled" && (
            <button
              style={{
                background: "lightgrey",
                color: "black",
                fontSize: ".8rem",
                padding: "3px",
                outline: "none",
                border: "none",
                textAlign: "center",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                cancelOrder();
              }}
            >
              Cancel Order
            </button>
          )}
        </td>
        {/* <td>{"--"}</td>
      <td>--</td> */}
      </tr>
    </>
  );
};

export default SingleRow;
